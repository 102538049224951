import React, { useContext, createContext , useState} from 'react';

export const ErrorContext = createContext(null);

export function useHasErrors(scope) {
    // Check for current context
    var errorContext = useContext(ErrorContext);
    if (typeof scope !== "undefined") { // Use specific scope if supplied
        scope = "";
    }
    else {
        let errors, setErrors, showErrors, setShowErrors;
        scope = "";
        if (errorContext) {
            [errors, setErrors, showErrors, setShowErrors, scope] = errorContext;
        }
    }
    return (errors) => {
        for (let prop in errors) {
            // Skip errors not in current scope
            if (scope !== "" && !prop.startsWith(scope)) {
                continue;
            }
            if (errors[prop]) return true;
        }
        return false;
    }
}

export const ErrorContextProvider = ({ scope, errors, setErrors, showErrors, setShowErrors, children }) => {

    // Try getting an existing error context
    let ctxErrors, setCtxErrors, ctxShowErrors, setCtxShowErrors, ctxScope;
    try {
        // Try getting the error context
        [ctxErrors, setCtxErrors, ctxShowErrors, setCtxShowErrors, ctxScope] = useContext(ErrorContext);
        // If present we replace the not specified parameters with the parameters from the context 
        if (typeof errors === "undefined" && typeof setErrors === "undefined") {
            // Note: only inherit scope if no error or setError is supplied (which implies a new scope)
            if (typeof scope === "undefined") {
                scope = ctxScope;
            }
            else {
                scope = ctxScope + scope;
            }
        }
        if (typeof errors === "undefined") {
            errors = ctxErrors;
        }
        if (typeof setErrors === "undefined") {
            setErrors = setCtxErrors;
        }
        if (typeof showErrors === "undefined") {
            showErrors = ctxShowErrors;
        }
        if (typeof setShowErrors === "undefined") {
            setShowErrors = setCtxShowErrors;
        }
    } catch (e) {
        // Then the context is not available
    }
    // Default to an empty scope
    if (typeof scope === "undefined") {
        scope = "";
    }
    return <ErrorContext.Provider value={[errors, setErrors, showErrors, setShowErrors, scope]} >
        {children}
    </ErrorContext.Provider>
}
