import * as React from 'react';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import StandardCheckbox from './StandardCheckbox';
import { CreateGridLocale } from './EntityGrid';
import useTheme from '@mui/material/styles/useTheme';
import { DialerSip } from '../../node_modules/@mui/icons-material/index';

export default function SimpleGrid({ rows, pinnedRows,columns, selection, setSelection, height, hideFooter, autoPageSize, pagination, pageSize, rowReordering, onRowOrderChange, disableSelection, textColor, headerTextColor, headerBackgroundColor,borderColor,backgroundColor,disableBorders, ...props }) {
    const theme = useTheme();

    function handleSelectionChange(selectedRows) {
        setSelection(selectedRows);
    }
    const gridLocale = CreateGridLocale();

    // Fallback to background color if set when no header color
    if (backgroundColor && !headerBackgroundColor) {
        headerBackgroundColor = backgroundColor;
    }
    if (textColor && !headerTextColor) {
        headerTextColor = textColor;
    }
    let customStyles = {
        height: height,
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
        },
        [`& .${gridClasses.pinnedRows}`]: {
            background: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
        {
            outline: 'none',
        },
    };

    customStyles["& .MuiDataGrid-row.mt-even-row"] = {
        backgroundColor: theme.palette.backgrounds.gridEvenRow
    };
    customStyles["& .MuiDataGrid-row.mt-odd-row"] = {
        backgroundColor: theme.palette.backgrounds.gridOddRow
    };
    customStyles["& .MuiDataGrid-row:hover"] = {
        backgroundColor: theme.palette.backgrounds.gridRowHover
    };
    customStyles[`& .${gridClasses.columnHeaders}`] = {
        backgroundColor: theme.palette.backgrounds.gridHeader
    };

    if (borderColor || disableBorders || textColor || backgroundColor) {
        customStyles[`& .${gridClasses.cell}`] = {
            borderColor: borderColor,
            borderWidth: disableBorders ? 0 : null,
            color: textColor ? textColor : null,
            backgroundColor: backgroundColor ? backgroundColor : null
        };
        customStyles[`& .${gridClasses.virtualScrollerContent}`] = {
            backgroundColor: backgroundColor ? backgroundColor : null
        };
        customStyles[`& .${gridClasses["pinnedRows--bottom"]}`] = {
            borderColor: borderColor,
            borderTop: disableBorders ? null : "1px " + borderColor + " solid",
            borderWidth: disableBorders ? 0 : null,
            borderTop: borderColor ? "1px " + borderColor + " solid" : "1px " + theme.palette.primary.main + " solid" ,
            boxShadow:"none"
        };
        customStyles.boxShadow = disableBorders ? null : "inset 0px 0px 0px 1px " + borderColor;
        if (disableBorders) {
            customStyles.borderWidth = 0;
        }
    }
    else {
        customStyles[`& .${gridClasses["pinnedRows--bottom"]}`] = {
            borderTop: "1px " + theme.palette.primary.main + " solid",
            boxShadow:"none"
        };
    }

    if (headerTextColor || headerBackgroundColor || borderColor || disableBorders) {
        customStyles[`& .${gridClasses.columnHeader}`] = {
            backgroundColor: headerBackgroundColor ? headerBackgroundColor : null,
            borderColor:borderColor ? borderColor : null,
            color: headerTextColor ? headerTextColor : "",
            borderWidth: disableBorders ? 0 : null
        };
        customStyles[`& .${gridClasses.columnHeaders}`] = {
            backgroundColor: backgroundColor ? backgroundColor : null,
            borderColor: borderColor,
            borderWidth: disableBorders ? 0 : null
        };
    }
    if (typeof headerTextColor !== "undefined") {
        customStyles[`& .${gridClasses.sortIcon}`] = {
            color: headerTextColor,
        };
    }
    return (
        <DataGridPro
            disableColumnMenu
            sx={customStyles}
            localeText={gridLocale}
            //rowHeight={Constants.Grids.RowHeight}
            //columnHeaderHeight={Constants.Grids.RowHeight}
            density="compact"
            rows={rows}
            pinnedRows={pinnedRows}
            pageSize={pageSize}
            hideFooter={hideFooter}
            pagination={pagination}
            showColumnVerticalBorder={true}
            showCellVerticalBorder={true}
            columns={columns}
            autoPageSize={autoPageSize}
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'mt-even-row' : 'mt-odd-row'
            }
            disableRowSelectionOnClick={disableSelection}
            checkboxSelection={!Boolean(disableSelection)}
            rowReordering={rowReordering}
            // Remove this when upgrading
            //components={{ BaseCheckbox: StandardCheckbox }}
            //selectionModel={selection}
            //onSelectionModelChange={disableSelection ? null : handleSelectionChange}
            // Change to this when upgrading
            slots={{ baseCheckbox: StandardCheckbox }}
            rowSelectionModel={selection}
            onRowSelectionModelChange={disableSelection ? null : handleSelectionChange}
            onRowOrderChange={onRowOrderChange}
            {...props }
        />
    );
}
