import React, { Component, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LanguageIcon from '@mui/icons-material/Language';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import Typography from '@mui/material/Typography';
import { Drawer } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Constants } from './AppConstants';
import SessionContext from './SessionContext';
import { ActivityTracker } from './ActivityTracker';
import SessionProvider from './SessionProvider';
import { createTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import UserProfileButton from './UserProfileButton'
import ProgressBackdrop from './ProgressBackdrop';
import LogoutIcon from '@mui/icons-material/Logout';
import ErrorDialog from './ErrorDialog';
import StandardMenu from './StandardMenu';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PersonIcon from '@mui/icons-material/Person';
import ConfirmDialog from './ConfirmDialog';

const textColor = "#FFFFFF";
const iconWidth = 32;
const iconFontSize = "24px";

const theme = createTheme(
    {
        typography: {
            "fontFamily": `"Gotham SSm A", "Gotham SSm B", "Roboto", "Helvetica", "Arial", sans-serif`,
            "fontSize": 13,
            "fontWeightLight": 400,
            "fontWeightRegular": 400,
            "fontWeightMedium": 400
        },

        //typography: {
        //    "fontFamily": `"Gotham SSm A", "Gotham SSm B", "Roboto", "Helvetica", "Arial", sans-serif`,
        //    "fontSize": 13,
        //    "fontWeightLight": 300,
        //    "fontWeightRegular": 300,
        //    "fontWeightMedium": 300
        //},
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 28,
                        paddingLeft: 18,
                        paddingRight: 18,
                    },
                },
            },
            MuiTab: {
                defaultProps: {
                    disableRipple: true,
                },
            },
            MuiRadio: {
                defaultProps: {
                    disableRipple: true,
                },
            },
            MuiCheckbox: {
                defaultProps: {
                    disableRipple: true,
                },
            },
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true,
                },
            },
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#FFFFFF"
                    }
                }
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        //"&$selected": {
                        //    backgroundColor: "purple", // Customize the selected background color
                        //    color: "white", // Customize the selected text color
                        //    "& .MuiListItemIcon-root": {
                        //        color: "red", // Customize the selected icon color
                        //    },
                        //},
                        "&:hover": {
                            backgroundColor: "#3f3f3f", // Customize the hover background color
                            color: "white", // Customize the hover text color
                            "& .MuiListItemIcon-root": {
                                color: "white", // Customize the hover icon color
                            },
                        },
                    },
                    selected: {}, // Required for the selected state
                },
            },
        },
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,040608 101725
                main: '#06090e',
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            secondary: {
                // light: will be calculated from palette.primary.main,
                main: '#e0e0e0',
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            success: {
                main: '#10b981' // '#03a9f5' // '#8aa43e' //'#10b981'
            },
            progress: {
                wait: "#ffba00"
            },
            search: {
                main: "red",
                //main: "rgba(80,80,80,0)",
            },
            text: {
                primary: "#606060",
                secondary: "#505050",
                myBorder: "#ff0000",
                primaryToolbarButton: "#ffffff",
                primaryBottombarButton: "#ffffff",
                toolbarButton: "#404040",
                bottombarButton: "#202020"
            },
            avatar: {
                loggedIn: "#315667",
                default: "#e9e6de"
            },
            borders: {
                default: "#c4c4c4"
            },
            backgrounds: {
                navigation: "#393939",
                navigationSelected: "#454545",
                navigationHover: "#3f3f3f",
                topBar: "#393939",
                application: "#fcfcfc",
                standard: "#ffffff",
                hover: "#ececec",
                backdrop: "rgba(0,0,0,0.1)",
                gridOddRow: "#f5f5f5",
                gridEvenRow: "#ffffff",
                gridRowHover: "#ebebeb",
                gridHeader: "#ffffff"
            }
        },
        inputs: {
            variant: "standard"
        }
    }
);

function NavListItem({ text, url, icon, selected, badgeCount, onClick, subItem }) {

    let subItemProps = subItem ? { pl: 3, mr: 1 } : {};

    // Check if this item should be selected or not
    let isSelected = false;
    if (url === "/") {
        isSelected = url === window.location.pathname;
    }
    else {
        let urlParts = url.split('/');
        let pathParts = window.location.pathname.split('/');
        // Set selected if first part of the path matches
        if (urlParts.length > 1 && pathParts.length > 1) {
            if (url && url.toLowerCase().startsWith("/instance/")) {
                // These have a different structure, require part 1 and 3 to be equal
                if (urlParts.length >= 3 && pathParts.length >= 3) {
                    isSelected = urlParts[1].toLowerCase() == pathParts[1].toLocaleLowerCase() && urlParts[3].toLowerCase() == pathParts[3].toLocaleLowerCase();
                }
            }
            else {
                isSelected = urlParts[1].toLowerCase() == pathParts[1].toLocaleLowerCase();
            }
        }
    }
    selected = isSelected || selected;
    return (
        <Link key={url} to={url} onClick={onClick} className="nav-link">
            <ListItemButton selected={selected} sx={{ backgroundColor: selected ? theme.palette.backgrounds.navigationSelected + "!important" : null }}>
                <ListItemIcon sx={{ color: textColor, minWidth: iconWidth, fontSize: iconFontSize, ...subItemProps }}>
                    {icon}
                </ListItemIcon>
                {badgeCount ?
                    <Badge badgeContent={badgeCount} color="success">
                        <ListItemText primary={text} primaryTypographyProps={{ style: { textTransform: "uppercase", fontSize: "11px" } }} />
                    </Badge>
                    :
                    <ListItemText primary={text} primaryTypographyProps={{ style: { textTransform: "uppercase", fontSize: "11px" } }} />
                }
            </ListItemButton>
        </Link>
    );
    //return (
    //    <Link key={url} to={url} onClick={closeMobileDrawer} className="nav-link">
    //        <ListItemButton selected={selected}>
    //            <ListItemIcon sx={{ color: textColor, pl: 4, mr: 1 }}>
    //                {icon}
    //            </ListItemIcon>
    //            <ListItemText primary={text} primaryTypographyProps={{ style: { textTransform: "uppercase", fontSize: "11px" } }} />
    //        </ListItemButton>
    //    </Link>
    //);

}
function NavIcon({ icon }) {
    return React.createElement(icon, { sx: { fontSize: "24px" } })
}

export function Layout(props) {
    const { session, setSession } = useContext(SessionContext);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { window } = props;
    const { t } = useTranslation();

    // ------------------------------------------------------------------------------------
    // Create a theme based on the current locale
    // ------------------------------------------------------------------------------------
    const locale = session.locale;
    const themeWithLocale = React.useMemo(
        () => createTheme(theme, locale),
        [locale, theme],
    );
    // ------------------------------------------------------------------------------------

    const container = window !== undefined ? () => window().document.body : undefined;

    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen);
    }
    function closeMobileDrawer() {
        if (mobileOpen) setMobileOpen(false);
    }

    function renderListItem(text, url, icon, selected, badgeCount) {
        return (
            <Link key={url} to={url} onClick={closeMobileDrawer} className="nav-link">
                <ListItemButton selected={selected}>
                    <ListItemIcon sx={{ color: textColor, minWidth: iconWidth, fontSize: iconFontSize }}>
                        {icon}
                    </ListItemIcon>
                    {badgeCount ?
                        <Badge badgeContent={badgeCount} color="success">
                            <ListItemText primary={text} />
                        </Badge>
                        :
                        <ListItemText primary={text} />
                    }
                </ListItemButton>
            </Link>
        );
    }

    const drawer = (
        <>
            <Toolbar variant="dense" />
            <List sx={{ color: textColor }}>

                <NavListItem text={t("Accounts")} url="/" icon={<NavIcon icon={AccountBoxIcon} />} onClick={closeMobileDrawer} />
                {session.administrator && <NavListItem text={t("Users")} url="/user/list" icon={<NavIcon icon={PersonIcon} />} onClick={closeMobileDrawer} />}
                {session.administrator && <NavListItem text={t("Partners")} url="/partner/list" icon={<NavIcon icon={HandshakeIcon} />} onClick={closeMobileDrawer} />}
                {session.administrator && <NavListItem text={t("Status")} url="/status" icon={<NavIcon icon={TroubleshootIcon} />} onClick={closeMobileDrawer} />}
            </List>
        </>
    );

    return <ThemeProvider theme={themeWithLocale}>
        <Box sx={{ display: 'flex', height: '100%' }}>
            <CssBaseline />
            <ProgressBackdrop />
            <ErrorDialog></ErrorDialog>
            <ConfirmDialog></ConfirmDialog>
            <ActivityTracker maxInactivity={15*60}/>
            <AppBar
                position="fixed"
                sx={{
                    zIndex: theme.zIndex.drawer + 1, boxShadow: "none"
                }}
            >
                <Toolbar variant="dense" style={{ backgroundColor: theme.palette.backgrounds.topBar }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, marginLeft: "-8px", display: { xs: "none", sm: "block" } }}>
                        <img alt="Logo" src="/images/modylar3.png" className="logo" style={{ marginLeft: "0px" }} />
                    </Typography>
                    <div style={{ flexGrow: 1 }}>
                    </div>
                    <UserProfileButton />
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: Constants.DrawerWidth }, flexShrink: { sm: 0 } }}
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: Constants.DrawerWidth, backgroundColor: "none" },
                    }}
                >
                    {drawer}
                </Drawer>

                <Drawer color="primary"
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: Constants.DrawerWidth, backgroundColor: "#393939" },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box className="main-container"
                component="main"
                sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column', width: { sm: `calc(100% - ${Constants.DrawerWidth}px)`, background: theme.palette.backgrounds.application } }}
            >
                <Toolbar variant="dense" />
                <>
                    {props.children}
                </>
            </Box>
        </Box>
    </ThemeProvider>;
}

