import React, { useContext, useRef } from 'react';
import { Link , useBlocker} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Toolbar } from '@mui/material';
import SessionContext from './SessionContext';
import Tooltip from '@mui/material/Tooltip';
import { Util } from './util/Util';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import StandardDialog from './StandardDialog';

export function ViewPrompt({ when, message, title }) {
    const { t } = useTranslation();

    let blocker = useBlocker(({ currentLocation, nextLocation }) => when && currentLocation.pathname !== nextLocation.pathname);

    // Do not block navigation when automatic logout is in progress
    if (blocker.state === "blocked") {
        return <StandardDialog title={title ? title : t("OnLeaveTitle")}
            open={true}
            maxWidth="sm"
            saveButtonText={t("OnLeaveContinue")}
            onClose={() => blocker.reset()}
            onSave={() => blocker.proceed()}
        >
            {message ? message : t("OnLeaveMessage")}
        </StandardDialog>;
    }
    else {
        return null;
    }
}

export function ViewIcon({ icon, size,...props }) {
    let fontSize = 48;
    let marginTop = -6;
    let marginRight = 6;
    if (size === "large") {
        //fontSize = 48;
        //marginTop = -6;
        //marginRight = 6;

        fontSize = 38;
        marginTop = -5;
        marginRight = 6;

    }
    else if (size === "medium") {
    }
    else if (size === "small") {
    }
    else if (size === "xsmall") {
        fontSize = 16;
        marginTop = -2;
        marginRight = 0;
    }

    if (props.sx) {
        props.sx.fontSize = fontSize + "px";
        props.sx.verticalAlign = "middle";
        props.sx.marginTop = marginTop + "px";
        props.sx.marginRight = marginRight + "px";
    }
    else {
        props.sx = { fontSize: fontSize + "px", verticalAlign: "middle", marginTop:marginTop + "px", marginRight: marginRight + "px" };
    }
    return React.createElement(icon, props);
}

export function TabbedViewContent({ children, header, breadcrumbItems, edited, addBottomBarSpacing, noHeader, noPadding, icon }) {

    let headerElement = <h1 className="header no-margin" style={{ marginBottom: Boolean(breadcrumbItems) ? 0 : null }} >{icon}{header}&nbsp;</h1>;
    let breadcrumbContainer = Boolean(breadcrumbItems) ? <div style={{ marginBottom: "20px" }}><ViewBreadcrumb items={breadcrumbItems}/></div> : null;
    return (
        <>
            <ViewPrompt when={edited} />
            <form noValidate={true} className={noPadding ? "mt-view-container tabbed no-padding" : "mt-view-container tabbed"} onSubmit={(event) => event.preventDefault()}>
                {!noHeader && headerElement}
                {breadcrumbContainer}
                {children}
                {addBottomBarSpacing ? <Toolbar /> : <></>}
            </form>
        </>
    );
}

export function ViewBreadcrumb({ items, maxLength }) {
    if (typeof maxLength === "undefined") {
        maxLength = 50;
    }

    function getItemText(text) {
        if (text) {
            if (typeof text === "string" && text.length > maxLength) {
                return <Tooltip title={text}><span>{Util.truncate(text, maxLength)}</span></Tooltip>;
            }
            else {
                return text;
            }
        }
        return null;
    }
    return <Breadcrumbs sx={{ fontSize: "10px", textTransform: "uppercase", letterSpacing: "0.05rem" }} separator={">"}>
        {items.map((item, index) => (
            <MuiLink
                key={index}
                title={item.tooltip}
                onClick={(e) => {
                    if (item.onClick) {
                        e.preventDefault();
                        item.onClick(e);
                    }
                    else if (!Boolean(item.url)) {
                        e.preventDefault();
                    }
                }}
                component={Link}
                to={item.url ? item.url : ""}
                className={Boolean(item.url) || Boolean(item.onClick) ? "mt-breadcrumb-link" : "mt-breadcrumb-text"}
            >
                {item.icon}<span style={{ marginLeft: Boolean(item.icon) ? "4px" : 0 }}>{getItemText(String(item.text))}</span>
            </MuiLink>
        ))}        
    </Breadcrumbs>;
}

export function ViewContent({ children, header, edited, breadcrumbItems, fullWidth, noHeader, sx, addBottomBarSpacing, fixedHeader, headerLayoutRenderer, centered }) {
    const headerContainer = useRef(null);

    let headerElement = null;

    const breadcrumb = Boolean(breadcrumbItems) ? <ViewBreadcrumb items={breadcrumbItems} /> : null;

    if (!noHeader) {
        let headerText = header ? header : "";
        let hTag = <h1 className={"header"} style={{ marginBottom: fixedHeader || Boolean(breadcrumbItems) ? 0 : null }}>{Util.truncate(headerText, 80)}&nbsp;</h1>;
        if (headerText.length > 80) {
            headerElement = <Tooltip title={headerText}>{hTag}</Tooltip>;
        }
        else {
            headerElement = hTag ;
        }
        // If custom renderer supplied
        if (headerLayoutRenderer) {
            headerElement = headerLayoutRenderer(headerElement);
        }
    }

    function handleScroll(e) {
        headerContainer.current.className = e.currentTarget.scrollTop > 0 ? "header-container scrolled" : "header-container";
        if (e.currentTarget.scrollTop > 0) {
            // Only touch the dom object if we need to change
            if (headerContainer.current.className !== "header-container scrolled") {
                headerContainer.current.className = "header-container scrolled";
            }
        }
        else {
            // Only touch the dom object if we need to change
            if (headerContainer.current.className !== "header-container") {
                headerContainer.current.className = "header-container";
            }
        }
    }

    return (
        <>
            <ViewPrompt when={edited}/>
            <form noValidate={true} className={fixedHeader ? "mt-view-container fixed-header" : "mt-view-container"} onSubmit={(event) => event.preventDefault()} style={sx}>
                {fixedHeader ?
                    <>
                        <div className="header-container" ref={headerContainer}>
                            {headerElement}
                            {breadcrumb}
                        </div>
                        <div className="mt-view-scrollcontainer" onScroll={handleScroll}>
                            <div className={fullWidth ? "mt-view-content full-width" : "mt-view-content"}>
                                {children}
                                {addBottomBarSpacing ? <Toolbar /> : <></>}
                            </div>
                        </div>
                    </>
                    :
                    <div className={fullWidth ? "mt-view-content full-width" : "mt-view-content"} style={{ marginLeft:centered && "auto", marginRight:centered && "auto"} }>
                        {headerElement}
                        {breadcrumb}
                        {children}
                        {addBottomBarSpacing ? <Toolbar /> : <></>}
                    </div>
                }
            </form>
        </>
    );
}
