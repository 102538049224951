import React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PopupMessage({ open, setOpen, text, duration, severity, vertical, horizontal }) {
    const theme = useTheme();

    const handleClose = (event, reason) => {
        setOpen(false);
    };
//            anchorOrigin={{ vertical: vertical ? vertical : "bottom", horizontal: horizontal ? horizontal : "left"}}

    return <Snackbar
        anchorOrigin={{ vertical: vertical ? vertical : "bottom", horizontal: horizontal ? horizontal : "center" }}
        open={open}
        autoHideDuration={duration ? duration : 1500}
        onClose={handleClose}>
        <Alert
            onClose={handleClose}
            severity={severity ? severity : "success"}
            sx={{ width: '100%', color: theme.palette.text.primaryToolbarButton }}>
            {text}
        </Alert>
    </Snackbar>
}
//export function BottomToolbarPlaceholder(props) {
//    return (
//        <>
//            <Toolbar />
//            <AppBar color="secondary" sx={{ top: 'auto', bottom: 0, boxShadow: "none" }}>
//                <Toolbar sx={{ marginLeft: { sm: Constants.DrawerWidth + "px" } }}>
//                    <Stack spacing={2} direction="row">
//                        {props.children}
//                    </Stack>
//                </Toolbar>
//            </AppBar>
//        </>
//    )
//}


