
export const Constants = {
    ApplicationName: "MODYLAR",
    TooltipPlacement: "right",
    MaxFileSizeMB: 50,
    MaxFileCount: 100000,
    DrawerWidth: 240,
    SubDrawerShiftSM: 50,
    SubDrawerShiftMD: 100,
    SubDrawerShiftLG: 150,
    MinPrecision: 0.000001,
    PublicFormBasePath: "/form/",
    PublicLinkBasePath: "/public/",
    DefaultEntityColor: "#8d84e8",
    IdentifierMaxLength: 250,
    BottomBarVariant: "dense",
    AttachmentsFieldId: "attachments",
    DocumentsFieldId: "documents",
    GetAllCultures: (t) => {
        let items = [];
        items.push({ culture: "sv-SE", name: t("sv-SE") });
        items.push({ culture: "en-US", name: t("en-US") });
        items.push({ culture: "nb-NO", name: t("nb-NO") });
        return items;
    },
    GetDateCultures: (t) => {
        let items = [];
        items.push({ culture: "sv-SE", name: t("sv-SE") });
        items.push({ culture: "en-US", name: t("en-US") });
        items.push({ culture: "nb-NO", name: t("nb-NO") });
        return items;
    },
    Dates: {
        Today: "TODAY"
    },
    Locales: {
        Swedish: "sv-SE",
        English: "en-US",
        English: "nb-NO"
    },
    FileFormats: {
        Xlsx: 0,
        Csv: 1
    },
    BackgroundTaskType:{
        ProcessUpdatedEntityName: 0,
        ProcessUpdatedUserName: 1,
        ProcessUpdatedField: 2,
        CreatePdfPreview: 3,
        ProcessUpdatedPermissionFields: 4,
        ProcessAddedFields: 5,
        ProcessUpdatedTag: 6,
        ExecuteMacrosAndConditions: 7,
        ProcessUpdatedContactName: 8,
        UpdateIdentifiers: 9,
        CreateCases: 10,
        ProcessUpdatedWorkflowNames: 11,
        ExportEntities: 12,
        ImportEntities: 13,
    },
    JSEP: {
        Type: {
            BinaryExpression: "BinaryExpression",
            UnaryExpression: "UnaryExpression",
            Compound: "Compound",
            Identifier: "Identifier",
            Literal: "Literal",
            ArrayExpression: "ArrayExpression",
            CallExpression: "CallExpression"
        }
    },
    WorkflowCaseFilter: {
        Variant: {
            Tasks: 0,
            Requests: 1,
            Overview: 2
        }
    },
    CustomValueTypes: {
        DocumentStructure: "Model.DocumentStructure"
    },
    Expressions: {
        FieldBackgroundColor: "#ebebeb",
        Type:
        {
            Binary: 0,
            Unary: 1,
            Field: 2,
            Literal: 3
        }
    },
    CompanyLookup: {
        InputParameters: {
            OrgNr: "orgNumber"
        },
        CompanyData: {
            Name: "name",
            OrgNumber: "orgNumber",
            Revenue: "revenue",
            RevenueInterval: "revenueInterval",
            Employees: "employees",
            Street: "street",
            Zip: "zip",
            Phone: "phone",
            Email: "email",
            Web: "web",
            IndustryCode: "industryCode",
            IndustryDescription: "industryDescription"
        }
    },
    FieldMapping: {
        ErrorType: {
            NoListItemMatch: 0,
            NoEntityInstanceMatch: 1,
            MultipleEntityInstanceMatches: 2,
            MultipleEntityInstancesToSingle: 3,
            NoContactMatch: 4,
            MultipleContactMatches: 5,
            NoUserMatch: 6,
            MultipleUserMatches: 7,
            MultipleListItemsToSingle: 8,
            MultipleContactsToSingle: 9,
            MultipleUsersToSingle: 10,
            TargetListItemNotFound: 11,
            InvalidEmail: 12,
            InvalidInt: 13,
            InvalidDouble: 14,
            MultipleSelectedContactsToSingle: 15,
            MultipleSelectedUsersToSingle: 16,
            MultipleSelectedListItemsToSingle: 17,
            MultipleSelectedEntityInstancesToSingle: 18,
            MultipleSelectedCountriesToSingle: 19,
            MultipleCountriesToSingle: 20,
            NoCountryMatch: 21,
            InvalidDate: 22,
            NumberTooLarge: 23,
            NumberTooSmall: 24,
            InvalidOrgNumber: 25,
            SignatureAuthorNotFound: 26,
            SignatureAuthorNotAuthenticated: 27,
            SignatureAuthorCouldNotLoadTemplate: 28
        },
        ValueType: {
            Nothing: 0,
            Constant: 1,
            Field: 2,
            CreatedEntity: 3,
            RelatedEntity: 4,
            ESignature: 5
        },
        FieldSource: {
            Section: 0,
            RelatedEntity: 1,
            ESignature: 2
        }
    },
    Macros: {
        MaxWhileIterations: 10000,
        ExecutionModel:
        {
            ExecuteAlways: 0,
            ExecuteIf: 1,
            ExecuteWhile: 2
        },
        ScoreType: {
            Sum: 0,
            Min: 1,
            Max: 2,
            Average: 3
        },
        ValueType: {
            Constant: 0,
            Expression: 1,
            Nothing: 2,
            Score: 3
        }
    },
    DefaultPageSize: 100,
    Grids: {
        RowHeight: 36
    },
    ESign: {
        FieldNameSeparator: " - ",
        Provider: {
            DocuSign: 0,
            Scrive: 1
        },
        AuthenticationMethod: {
            None: 0,
            SmsPin: 1,
            BankIdSE: 2,
            BankIdNO: 3,
            BankIdFI: 4,
            BankIdNL: 5
        },
        ProviderState: {
            Disabled: 0,
            Developer: 1,
            Production: 2
        }
    },
    Approvals: {
        Type: {
            None: 0,
            Any: 1,
            All: 2
        }
    },
    Notifications: {
        FieldTypeStandardPrefix: "standard__",
        FieldTypeEntityPrefix: "entity__",
        FieldTypeSectionPrefix: "section__",
        FieldTypeSeparator: "__",
        AttachmentType: {
            FormField: 0,
            EntityInstanceField: 1
        },
        FieldType: {
            WorkflowCaseName: 0,
            RecipientName: 1,
            RecipientFirstName: 2,
            RecipientLastName: 3,
            RecipientEmail: 4
        },
        ButtonType: {
            WorkflowCase: 0,
            RelatedEntity: 1,
            None: 2
        },
        SendEvent: {
            PhaseEntered: 0,
            PhaseSubmitted: 1,
            PhaseApproved: 2,
            PhaseRejected: 3
        }
    },
    SortOrder: {
        Ascending: 0,
        Descending: 1
    },
    Phases: {
        TriggerActions: {
            TransitionToPhase: 0,
            TriggerWorkflow: 1,
            Webhook: 2
        },
        Event: {
            Entered: 0,
            Submitted: 1,
            Approved: 2,
            Rejected: 3,
            InstanceCreated: 4,
            InstanceMatched: 5,
            InstanceNotMatched: 6,
            Save: 7,
            Undefined: 8,
            DeadlineExpired: 9
        },
        MatchAction: {
            Proceed: 0,
            Review: 1
        },
        UpsertAction: {
            UseMatch: 0,
            Create: 1
        },
        MappingSource: {
            IndividualFields: 0,
            EntityForm: 1
        },
        ConditionType: {
            None: 0,
            RelatedEntity: 1,
            Section: 2
        },
        DataSource: {
            None: 0,
            CompanyLookup: 1
        },
        Status: {
            Unhandled: 0,
            InProgress: 1,
            AwaitingApproval: 2,
            AwaitingSignature: 3,
            Completed: 4,
            Rejected: 5,
            Approved: 6,
            Error: 7,
            Overdue: 8,
            Any: 9
        },
        Type: {
            Form: 0,
            UpdateEntity: 1,
            MatchInstance: 2,
            CreateEntity: 3,
            DataTransfer: 4,
            ESignature: 5,
            EndPhase: 6
        }
    },
    Charts: {
        yAxis: {
            Primary: 0,
            Secondary: 1
        },
        Type: {
            Column: 0,
            Bar: 1,
            Pie: 2,
            StackedBar: 3,
            StackedColumn: 4,
            StackedBarPercentage: 5,
            StackedColumnPercentage: 6,
            Line: 7,
            Gauge: 8,
            Area: 9,
            Donut: 10
        }
    },
    Sections: {
        Type: {
            WorkflowForm: 0,
            EntityForm: 1
        },
        Visibility: {
            Editable: 0,
            ReadOnly: 1,
            Hidden: 2
        }
    },
    Workflows: {
        TriggerType: {
            Manual: 0,
            DateLimit: 1,
            PublicLink: 2,
            InternalLink: 3
        },
        DateTriggerOperator: {
            OccurrsInPeriod: 0,
            OccurredPeriodAgo: 1
        },
        AssignmentType: {
            CaseCreator: 0,
            EntityInstanceField: 1,
            Team: 2,
            User: 3,
            FormField: 4,
            UserGroup: 5,
            PhaseOwner: 6,
            Contact: 7,
            PhaseSubmitter: 8
        }
    },
    TimeUnitSuffix: {
        Day: "d",
        Month: "m",
        Year: "y"
    },
    DocumentType: {
        Pdf: 0,
        Image: 1,
        Office: 2,
        Other: 3
    },
    IdentityProviderType: {
        Generic:0,
        EntraId:1,
        Okta:2,
        Google:3
    },
    DocumentState: {
        Temporary: 0,
        OK: 1,
        Uploading: 2
    },
    EntityInstanceRootFolder: "root",
    DocumentListItemType: {
        Folder: "folder",
        ParentFolder: "parentfolder",
        FolderContext: "foldercontext",
        Document: "document"
    },
    Dashboards: {
        ItemType: {
            VerticalContainer: 0,
            HorizontalContainer: 1,
            Component: 2
        },
        IndicatorValueType: {
            Absolute: 0,
            Percentage: 1
        },
        IndicatorValueVariant: {
            Scalar: 0,
            LinearGauge: 1,
            Circle: 2
        },
        Aggregate: {
            Count: 0,
            Average: 1,
            Sum: 2,
            Min: 3,
            Max: 4,
            Share: 5
        },
        ComponentType: {
            Undefined: 0,
            Placeholder: 1,
            Chart: 2,
            Indicator: 3,
            List: 4,
            Table: 5,
            Text: 6,
            RefreshTimer: 7,
            TimeSelecter: 8
        },
        ElementType: {
            ResizeVertical: "resize-vertical",
            ResizeHorizontal: "resize-horizontal",
            ComponentOutline: "component-outline",
            ContentButton: "content-button",
            HorizontalContainerButton: "horizontal-container-button",
            VerticalContainerButton: "vertical-container-button"
        }
    },
    ModelType: {
        User: 0,
        Account: 1,
        Entity: 2,
        Dimension: 3,
        Field: 4,
        FieldGroup: 5,
        Form: 6,
        GridLayout: 7,
        EntityType: 8,
        Document: 9,
        Folder: 10,
        EntityInstance: 11,
        GridState: 12,
        UserGroup: 13,
        EntityFilter: 14,
        EntityEvent: 15,
        Tag: 16,
        Comment: 17,
        Macro: 18,
        Team: 19,
        Workflow: 20,
        WorkflowCase: 21,
        WorkflowCaseTask: 22,
        Contact: 23,
        Undefined: 24,
        Dashboard: 25,
        ESignature: 26,
        Theme: 27,
        Palette: 28
    },
    ThemeMode: {
        Light: "light",
        Dark: "dark",
        System: "system"
    },
    Alignment: {
        Left: 0,
        Centered: 1,
        Right: 2,
        LeftOf: 3,
        RightOf: 4
    },
    NumberFormat: {
        Increment: 0,
        YearlyIncrement: 1
    },
    UploadConflictOptionsHeader: {
        AlternativeName: "alternative-name",
        ConflictOption: "conflict-option",
        DocumentId: "document-id"
    },
    UploadConflictOptions: {
        Replace: "replace",
        Skip: "skip",
        Rename: "rename"
    },
    GridContentType: {
        DateTime: 1,
        Rating: 2,
        Documents: 3,
        TimePeriod: 4,
        Date: 5,
        Undefined: 6,
        ListItem: 7,
        Tags: 8
    },
    DividerType: {
        Line: 0,
        Spacing: 1
    },
    FieldType: {
        Name: 0,
        Text: 1,
        Dropdown: 2,
        RadioButtonList: 3,
        CheckboxList: 4,
        Checkbox: 5,
        UserList: 6,
        EntityList: 7,
        Header: 8,
        Date: 9,
        Integer: 10,
        Decimal: 11,
        Switch: 12,
        ToggleButton: 13,
        Country: 14,
        SliderInteger: 15,
        Rating: 16,
        SliderDecimal: 17,
        SwitchList: 18,
        SystemField: 19,
        Custom: 20,
        TimePeriod: 21,
        Email: 22,
        ContactList: 23,
        DocumentStructure: 24,
        Divider: 25,
        Html: 26,
        OrgNumber: 27,
        Mobile: 28
    },
    SystemField: {
        None: 0,
        Name: 1,
        LastModifiedDate: 2,
        LastModifiedByUserId: 3,
        CreateDate: 4,
        CreatedByUserId: 5,
        Email: 6,
        Nr: 7,
        Tags: 8,
        Documents: 9
    },
    EntityEventType: {
        Read: 0,
        Edit: 1,
        Delete: 2,
        Create: 3,
        Info: 4,
        SentReminder: 5,
        Comment: 6,
        PhaseAssigned: 7,
        CaseCompleted: 8,
        PhaseSaved: 9,
        PhaseSubmitted: 10,
        PhaseApproved: 11,
        PhaseRejected: 12,
        CaseCreated: 13,
        PhaseOpened: 14,
        Remove: 15,
        Restore: 16,
        SentNotification: 17,
        InstanceCreated: 18,
        InstanceSelected: 19,
        InstanceUpdated: 20,
        WorkflowTriggered: 21
    },
    GradientType: {
        None: 0,
        LinearHorizontal: 1,
        LinearVertical: 2,
        Radial: 3
    },
    TimeDirection: {
        None: 0,
        Last: 1,
        Next: 2
    },
    TimeUnit: {
        Day: 0,
        Month: 1,
        Year: 2,
        Quarter: 3,
        HalfYear: 4
    },
    Operator: {
        Equal: 0,
        NotEqual: 1,
        GreaterThan: 2,
        LessThan: 3,
        GreaterThanOrEqual: 4,
        LessThanOrEqual: 5,
        IsEmpty: 6,
        Contains: 7,
        StartsWith: 8,
        EndsWith: 9,
        Add: 10,
        Subtract: 11,
        Divide: 12,
        Multiply: 13,
        Not: 14,
        And: 15,
        Or: 16,
        OccursInLessThan: 17,
        OccursInLessThanOrEqual: 18,
        OccursInMoreThan: 19,
        OccursInMoreThanOrEqual: 20,
        OccurredForLessThan: 21,
        OccurredForLessThanOrEqual: 22,
        OccurredForMoreThan: 23,
        OccurredForMoreThanOrEqual: 24
    },
    FieldDataType: {
        None: 0,
        String: 11,
        ListItemId: 2,
        ListItemIdList: 3,
        Integer: 4,
        Decimal: 5,
        Boolean: 6,
        Date: 7,
        UserId: 8,
        UserIdList: 9,
        EntityId: 10,
        EntityIdList: 11,
        Country: 12,
        CountryList: 13,
        Name: 14,
        TimePeriod: 15,
        Email: 16,
        ContactIdList: 17,
        ContactId: 18,
        DocumentStructure: 19
    },
    User: {
        Role: {
            User: 0,
            Administrator: 1,
            WorkflowAdministrator: 2
        },
        Status: {
            Active: 0,
            Inactive: 1
        }
    }
    //    FieldType: {
    //        Name: 0,
    //        Text: 1,
    //        Dropdown: 2,
    //        RadioButtonList: 3,
    //         Multiselect: 4,
    //        CheckboxList: 5,
    //        Checkbox: 6,
    //        UserList: 8,
    //         UserListMultiple: 9,
    //        EntityList: 10,
    //         EntityListMultiple: 11,
    //        Header: 12,
    //        Date: 13,
    //        Integer: 14,
    //        Decimal: 15,
    //        Switch: 16,
    //        ToggleButton: 17,
    //         ToggleButtonMultiple: 18,
    //        Country: 19,
    //        SliderInteger: 20,
    //        Rating: 21,
    //        SliderDecimal: 22,
    //        SwitchList: 23
    //    }
};

