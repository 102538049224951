import * as React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { Link } from "react-router-dom";
import MuiLink from '@mui/material/Link';

export default function StandardLink({ children, sx,...props}) {
    const theme = useTheme();

    return <MuiLink component={Link} {...props} sx={{ color: theme.palette.text.primary, ...sx }}>{children}</MuiLink>;
}

