import React, { Component, useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import apiClient from './api-communication/ApiClient'
import { ViewIcon } from './ViewContent';
import { useTranslation } from 'react-i18next';
import TopToolbar from './TopToolbar';
import AddButton from './AddButton';
import ProgressBackdrop from './ProgressBackdrop';
import DeleteButton from './DeleteButton';
import SimpleGrid from './SimpleGrid';
import StandardLink from './StandardLink';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { Util } from './util/Util';

export function PartnerList() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [partners, setPartners] = useState([]);
    const [selected, setSelected] = useState([]);
    let navigate = useNavigate();

    function handleAdd() {
        navigate("/partner/view/0");
    }
    function handleDelete() {
        apiClient.deleteMultiple("partners/deletemultiple", selected, function (response) {
            bindList();
        })
    }

    function bindList() {
        apiClient.getData("partners", function (loadedPartners) {
            setPartners(loadedPartners);
            setLoading(false);
        });
    }
    function nameRenderer(params) {
        return <StandardLink component={Link} to={"/partner/view/" + params.id} >{params.formattedValue}</StandardLink>
    }

    function getGridRows() {
        let rows = [];
        for (let i = 0; i < partners.length; i++) {
            let account = partners[i];
            rows.push({
                id: account.id,
                name: account.name
            });
        }
        return rows;
    }
    function getGridColumns() {
        let columns = [];
        columns.push({
            field: "name",
            headerName: t("Name"),
            sortable: true,
            flex: 1,
            renderCell: nameRenderer
        });
        return columns;
    }
    useEffect(() => {
        bindList();
        Util.setPageTitle(t("Partners"));
    },[])


    let grid = <SimpleGrid
        rows={getGridRows()}
        columns={getGridColumns()}
        selection={selected}
        setSelection={setSelected}
    />;

    return (
        <>
            <div className="mt-listview-container">
                <h1 className="header" ><ViewIcon icon={HandshakeIcon} size="large" />{t("Partners")}</h1>
                <TopToolbar>
                    <AddButton onClick={handleAdd}></AddButton>
                    <DeleteButton onClick={handleDelete}></DeleteButton>
                </TopToolbar>
                <div className="mt-listview-content">
                    {grid}
                </div>
            </div>
        </>
    );
}

