import React from 'react';
import { Toolbar,AppBar } from '@mui/material';
import { Constants } from './AppConstants';
import Stack from '@mui/material/Stack';

export default function BottomToolbar({ children, leftMargin, fullWidth, adjustForFullscreenDrawer }) {

    let leftMarginSM = 0;
    let leftMarginMD = 0;
    let leftMarginLG = 0;
    if (adjustForFullscreenDrawer) {
        leftMarginSM = Constants.SubDrawerShiftSM;
        leftMarginMD = Constants.SubDrawerShiftMD;
        leftMarginLG = Constants.SubDrawerShiftLG;
    }
    else {
        if (typeof leftMargin !== "undefined") {
            leftMarginSM = leftMargin.sm ? leftMargin.sm : leftMargin;
            leftMarginMD = leftMargin.lg ? leftMargin.md : leftMargin;
            leftMarginLG = leftMargin.lg ? leftMargin.lg : leftMargin;
        }
    }

    return (
        <>
            <Toolbar />
            <AppBar position="fixed" color="secondary" sx={{
                top: 'auto',
                bottom: 0,
                boxShadow: "none",
                left: {
                    sm: fullWidth ? 0 : Constants.DrawerWidth + leftMarginSM + "px",
                    md: fullWidth ? 0 : Constants.DrawerWidth + leftMarginMD + "px",
                    lg: fullWidth ? 0 : Constants.DrawerWidth + leftMarginLG + "px"
                }
            }}>
                <Toolbar variant={Constants.BottomBarVariant}>
                    <Stack spacing={2} direction="row">
                        {children}
                    </Stack>
                </Toolbar>
            </AppBar>
        </>
    )
}
export function BottomDrawerToolbar({ children, width}) {

    if (typeof width === "undefined") {
        console.log("Missing width in BottomToolbar");
        return null;
    }

    return (
        <>
            <Toolbar />
            <AppBar position="fixed" color="secondary" sx={{
                top: 'auto',
                bottom: 0,
                boxShadow: "none",
                width: width + "px"
            }}>
                <Toolbar>
                    <Stack spacing={2} direction="row">
                        {children}
                    </Stack>
                </Toolbar>
            </AppBar>
        </>
    )
}

//export function BottomToolbarPlaceholder(props) {
//    return (
//        <>
//            <Toolbar />
//            <AppBar color="secondary" sx={{ top: 'auto', bottom: 0, boxShadow: "none" }}>
//                <Toolbar sx={{ marginLeft: { sm: Constants.DrawerWidth + "px" } }}>
//                    <Stack spacing={2} direction="row">
//                        {props.children}
//                    </Stack>
//                </Toolbar>
//            </AppBar>
//        </>
//    )
//}


