import React, { Component, useState, useEffect, useMemo, useContext } from 'react';
import { useNavigate, Link } from "react-router-dom";
import apiClient from './api-communication/ApiClient'
import { ViewIcon } from './ViewContent';
import SessionContext from './SessionContext';
import { useTranslation } from 'react-i18next';
import TopToolbar from './TopToolbar';
import { useData } from './api-communication/Data'
import AddButton from './AddButton';
import PersonIcon from '@mui/icons-material/Person';
import ProgressBackdrop from './ProgressBackdrop';
import DeleteButton from './DeleteButton';
import SimpleGrid from './SimpleGrid';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import StandardLink from './StandardLink';
import { Util } from './util/Util';


export function UserList() {
    const { session, setSession } = useContext(SessionContext);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [partners, partnersLoading] = useData("partners");
    const [users, setUsers] = useState([]);
    const [selected, setSelected] = useState([]);
    let navigate = useNavigate();

    function handleAdd() {
        navigate("/user/view/0");
    }

    function handleDelete(e) {
        apiClient.deleteMultiple("users/deletemultiple", selected, function (response) {
            bindList();
        }, ProgressBackdrop.handleProgress);
    }


    const partnerLookup = useMemo(() => {
        if (partnersLoading) {
            return {};
        }
        else {
            let lookup = {};
            for (var i = 0; i < partners.length; i++) {
                const partner = partners[i];
                lookup[partner.id] = partner;
            }
            return lookup;
        }
    },[partners,partnersLoading])

    function bindList() {
        apiClient.getData("users", function (loadedUsers) {
            setUsers(loadedUsers);
            setLoading(false);
        });
    }
    function nameRenderer(params) {
        return <StandardLink to={"/user/view/" + params.id} >{params.formattedValue ? params.formattedValue : t("NoName")}</StandardLink>;
    }

    function getPartnerName(id) {
        const partner = partnerLookup[id];
        if (partner) {
            return partner.name;
        }
        return "";
    }
    function getGridRows() {
        let rows = [];
        for (let i = 0; i < users.length; i++) {
            let user = users[i];
            rows.push({
                id: user.id,
                name: user.name,
                email: user.email,
                partner: getPartnerName(user.partnerId),
                administrator: user.administrator ? t("Yes") : t("No"),
                twoFactorAuthenticationEnabled: user.twoFactorAuthenticationEnabled ? t("Yes") : t("No")
            });
        }
        return rows;
    }
    function getGridColumns() {
        let columns = [];
        columns.push({
            field: "name",
            headerName: t("Name"),
            sortable: true,
            flex: 1,
            renderCell: nameRenderer
        });
        columns.push({
            field: "email",
            headerName: t("Email"),
            sortable: true,
            width:250
        });
        if (session.administrator) {
            columns.push({
                field: "partner",
                headerName: t("Partner"),
                width: 200,
                sortable: true
            });
        }
        if (session.administrator) {
            columns.push({
                field: "administrator",
                headerName: t("Administrator"),
                width: 120,
                sortable: true
            });
            columns.push({
                field: "twoFactorAuthenticationEnabled",
                headerName: t("MFA"),
                width: 120,
                sortable: true
            });
        }
        return columns;
    }
    useEffect(() => {
        bindList();
        Util.setPageTitle(t("Users"));
    },[])


    let grid = <SimpleGrid
        rows={getGridRows()}
        columns={getGridColumns()}
        selection={selected}
        setSelection={setSelected}
    />;

    return !loading && !partnersLoading && session.ready && (
        <>
            <div className="mt-listview-container">
                <h1 className="header" ><ViewIcon icon={PersonIcon} size="large" />{t("Users")}</h1>
                <TopToolbar>
                    <AddButton onClick={handleAdd}></AddButton>
                    {session.administrator && <DeleteButton onClick={handleDelete}></DeleteButton>}
                </TopToolbar>
                <div className="mt-listview-content">
                    {grid}
                </div>
            </div>
        </>
    );
}

