import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function getAlignment(align) {
    if (align) {
        return align;
    }
    return "left";
}

export default function TextTable({ table }) {
    const theme = useTheme();

    return table && <Table stickyHeader sx={{ border: "1px " + theme.palette.borders.default + " solid", } }>
        <TableHead>
            <TableRow>
                {
                    table.columns.map((column, index) => (
                        <TableCell
                            key={index}
                            align={getAlignment(column.align)}
                        >
                            {column.name}
                        </TableCell>
                    ))
                }
            </TableRow>
        </TableHead>
        <TableBody>
        {
            table.rows.map((row, rowIndex) => (
                <TableRow key={"r" + rowIndex}>
                    {
                        row.cells.map((cell, cellIndex) => (
                            <TableCell key={"c" + cellIndex}
                                align={getAlignment(cell.align)}
                            >
                                {cell.value}
                            </TableCell>
                        ))
                    }
                </TableRow>
            ))
        }
        </TableBody>
    </Table>
}
