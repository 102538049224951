import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import ToolbarButton from './ToolbarButton';

export default function ErrorDialog() {
    const { t } = useTranslation();

    // Config for standard error
    let defaultConfig = {
        title: t("Message"),
        message: t("UnexpectedError"),
        buttonText:t("Ok")
    };

    const [dialogConfig, setDialogConfig] = React.useState(defaultConfig);
    const [open, setOpen] = React.useState(false);

    ErrorDialog.showMessage = function (message,closeHandler, title) {
        let conf = { ...defaultConfig };
        conf.message = message;
        if (typeof title !== "undefined") {
            conf.title = title;
        }
        conf.closeHandler = closeHandler;
        setDialogConfig(conf);
        setOpen(true);
    }
    ErrorDialog.showMessageKey = function (key) {
        let conf = { ...defaultConfig };
        conf.message = t(key);
        setDialogConfig(conf);
        setOpen(true);
    }
    ErrorDialog.show = function (config) {
        if (config) {
            setDialogConfig(config);
        }
        else {
            setDialogConfig(defaultConfig); // Just show a default error
        }
        // The set open
        setOpen(true);
    }
    function handleClose() {
        setOpen(false);
        if (dialogConfig.closeHandler) {
            dialogConfig.closeHandler();
        }
    }
    return (open ?
        <>
            <Dialog
            open={open}
            onClose={handleClose}
            slotProps={{
                backdrop:
                {
                    invisible: false,
                    style: {
                        opacity: 0.2
                    }
                }
            }}
            aria-labelledby="error-dialog-title"
            aria-describedby="error-dialog-description"
            >
            <DialogTitle id="error-dialog-title">
                    {dialogConfig.title}
            </DialogTitle>
                <DialogContent
                    sx={{ minWidth: "500px" }}
                >
                    <div id="error-dialog-description" style={{ whiteSpace: "pre-line" }}>
                        {dialogConfig.message}
                        {dialogConfig.details && <div style={{marginTop:"5px"} }>{dialogConfig.details}</div>}
                </div>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <ToolbarButton primary="true" onClick={handleClose} text={dialogConfig.buttonText}></ToolbarButton>
            </DialogActions>
            </Dialog>
        </>
        :<></>
    );
}
