import React, { useEffect, useState } from 'react'
import apiClient from './api-communication/ApiClient'
import SessionContext from './SessionContext'
import { useTranslation } from 'react-i18next';
import * as dateLocales from 'date-fns/locale';
// MUI locales
import * as locales  from '@mui/material/locale';

import { LoginActions } from './api-authorization/ApiAuthorizationConstants'
import ApiAuthorzationRoutes from './api-authorization/ApiAuthorizationRoutes';
import { Constants } from './AppConstants';
import { Navigate, useLoaderData } from 'react-router-dom'
import { Route, Routes } from 'react-router';
import AuthorizeRoute from './api-authorization/AuthorizeRoute';
import { Login } from './api-authorization/Login';

export async function sessionLoader() {
    return new Promise((resolve, reject) => {
        apiClient.getData("session", function (loadedSession) {
            loadedSession.locale = getLocale(loadedSession.user.culture, locales);
            loadedSession.dateLocale = getLocale(loadedSession.user.dateCulture, dateLocales);
            resolve({ session: loadedSession, authorized: true })
        }, function () {
            reject({authorizationFailed:true});
        });
    })
}
function getLocale(culture, repository) {
    var cultureParts = culture.split('-');
    var language = cultureParts[0];
    var region = cultureParts[1];
    var locale = repository["enUS"];
    // First try language and region
    if (repository[language + region]) {
        locale = repository[language + region];
    }
    else if (repository[language]) {
        locale = repository[language];
    }
    return locale;
}

export default function SessionProvider({ children }) {
    const sessionData = useLoaderData();
    const [session, setSession] = useState(sessionData.session);
    const { i18n } = useTranslation();
    const [authorized, setAuthorized] = useState(true);


    //useEffect(() => {
    //    if (sessionData.authorized) {
    //        setSession(sessionData.session);
    //        setAuthorized(sessionData.authorized);
    //        // Also update the application language
    //        i18n.changeLanguage(sessionData.session.user.culture);
    //    }
    //    else {
    //        setAuthorized(sessionData.authorized);
    //    }
    //},[sessionData])

    SessionProvider.SetAuthorized = function (status) {
        if (typeof status === "undefined") {
            status = true;
        }
        setAuthorized(status);
    }

    async function getSession(forceRefresh) {
        if (!session.ready || forceRefresh) {
            apiClient.getData("session", function (loadedSession) {
                loadedSession.locale = getLocale(loadedSession.user.culture, locales);
                loadedSession.dateLocale = getLocale(loadedSession.user.dateCulture, dateLocales);
                setAuthorized(true);
                // Also update the application language
                i18n.changeLanguage(loadedSession.user.culture);
                setSession(loadedSession);
            }, function () {
            })
        }
    }

    SessionProvider.refresh = function () {
        getSession(true);
    }
    SessionProvider.setContact = function (contact) {
        setSession((prev) => {
            return { ...prev, contact: contact }
        })
    }
    SessionProvider.setContact = function (contact) {
        setSession((prev) => {
            return { ...prev, contact: contact }
        })
    }
    SessionProvider.setAcceptedExtensions = function (acceptedExtensions) {
        setSession((prev) => {
            return { ...prev, acceptedExtensions: acceptedExtensions }
        })
    }
    useEffect(() => {
        // Also update the application language
        i18n.changeLanguage(session.user.culture);
    }, [session])


    if (!authorized) {
        // DETTA GENERERAR INTE FRAME WINDOW TIMEOUT!
        //    //<Login action={name}></Login>
        //    //<Navigate replace to={redirectUrl} />
        var link = document.createElement("a");
        link.href = window.location.pathname; // this.props.path;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        return <>
            <Routes>
                {ApiAuthorzationRoutes.map((route, index) => {
                    const { element, requireAuth, ...rest } = route;
                    return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
                })}
            </Routes>
            <Login action={LoginActions.Login} returnUrl={returnUrl}></Login>
        </>;
    }
    else {
        return <SessionContext.Provider value={{ session, setSession }}>
            {children}
        </SessionContext.Provider>
    }
}


//export default function SessionProvider({ children }) {
//    const sessionData = useLoaderData();
//    const [session, setSession] = useState({ administrator: false, userId: "", userName: "", ready: false });
//    const { i18n } = useTranslation();
//    const [authenticationFailed, setAuthenticationFailed] = useState(false);
//    console.log("loader data in provider", sessionData);
//    function getLocale(culture, repository) {
//        var cultureParts = culture.split('-');
//        var language = cultureParts[0];
//        var region = cultureParts[1];
//        var locale = repository["enUS"];
//        // First try language and region
//        if (repository[language + region]) {
//            locale = repository[language + region];
//        }
//        else if (repository[language]) {
//            locale = repository[language];
//        }
//        return locale;
//    }

//    SessionProvider.SetAuthenticationFailed = function (status) {
//        if (typeof status === "undefined") {
//            status = true;
//        }
//        setAuthenticationFailed(status);
//    }

//    async function getSession(forceRefresh) {
//        if (!session.ready || forceRefresh) {
//            apiClient.getData("session", function (loadedSession) {
//                loadedSession.locale = getLocale(loadedSession.user.culture, locales);
//                loadedSession.dateLocale = getLocale(loadedSession.user.dateCulture, dateLocales);
//                setAuthenticationFailed(false);
//                // Also update the application language
//                i18n.changeLanguage(loadedSession.user.culture);
//                setSession(loadedSession);
//            }, function () {
//            })
//        }
//    }

//    useEffect(() => {
//        getSession();
//    }, [])

//    SessionProvider.refresh = function () {
//        getSession(true);
//    }

//    if (authenticationFailed) {
//        if (true) {
//            // DETTA GENERERAR FRAME WINDOW TIMED OUT
//            // OM MAN INTE INKLUDERAR CHIILDREN NEDAN S� F�R MAN FRAME WINDOW TIMEOUT
//            var link = document.createElement("a");
//            link.href = window.location.pathname; // this.props.path;
//            const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
//            console.log("auth failed", returnUrl);
//            return <>
//                {children}
//                <Login action={LoginActions.Login} returnUrl={returnUrl}></Login>
//            </>;
//        }
//        else {
//            // DETTA GENERERAR INTE FRAME WINDOW TIMEOUT!
//            //    //<Login action={name}></Login>
//            //    //<Navigate replace to={redirectUrl} />
//            var link = document.createElement("a");
//            link.href = window.location.pathname; // this.props.path;
//            const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
//            return <>
//                <Routes>
//                    {ApiAuthorzationRoutes.map((route, index) => {
//                        const { element, requireAuth, ...rest } = route;
//                        return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
//                    })}
//                </Routes>
//                <Login action={LoginActions.Login} returnUrl={returnUrl}></Login>
//            </>;
//        }
//    }
//    else {
//        return session.ready ? <SessionContext.Provider value={{ session, setSession }}>
//            {children}
//        </SessionContext.Provider>
//            :
//            <LoadingProgress />
//    }
//}


//export default function SessionProvider({children}){
//    const [session, setSession] = useState({ administrator: false, userId: "", userName: "", ready:false });
//    const { i18n } = useTranslation();

//    function getLocale(culture, repository) {
//        var cultureParts = culture.split('-');
//        var language = cultureParts[0];
//        var region = cultureParts[1];
//        var locale = repository["enUS"];
//        // First try language and region
//        if (repository[language + region]) {
//            locale = repository[language + region];
//        }
//        else if (repository[language]) {
//            locale = repository[language];
//        }
//        return locale;
//    }

//    async function getSession(forceRefresh) {
//        if (!session.ready || forceRefresh) {
//            apiClient.getData("session", function (loadedSession) {
//                loadedSession.locale = getLocale(loadedSession.user.culture, locales);
//                loadedSession.dateLocale = getLocale(loadedSession.user.dateCulture, dateLocales);
//                // Also update the application language
//                i18n.changeLanguage(loadedSession.user.culture);
//                setSession(loadedSession);
//            })
//        }
//    }

//    useEffect(() => {
//        getSession();
//    }, [])

//    SessionProvider.refresh = function () {
//        getSession(true);
//    }

//    return <SessionContext.Provider value={{ session, setSession}}>
//        {children}
//    </SessionContext.Provider>
//}