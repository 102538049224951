
import { format } from 'date-fns'
import { Constants } from '../AppConstants'
export const Util = function () {
    return {
        isNullOrWhiteSpace: function (text) {
            if (text === null || typeof text === "undefined") {
                return true;
            }
            text = text.trim();
            return text === "";
        },
        roundTo: function (num, places) {
            const factor = 10 ** places;
            return Math.round(num * factor) / factor;
        },
        arrayEqual: function (a, b) {
            // Returns true if all elements in a is also in b and they are the same length
            if (a.length === b.length) {
                return a.every(v => b.includes(v));
            }
            else {
                return false; // No need to check
            }
        },
        toBase64: function(str) {
            const bytes = new TextEncoder().encode(str);
            const base64Encoded = btoa(String.fromCharCode(...bytes));
            return base64Encoded;
        },
        normalize: function (text) {
            if (text.normalize) {
                return text.normalize("NFC");
            }
            return text;
        },
        getUniqueName: function(name, nameLookup) {
            let versionNumber = 2;
            let candidateName = name;
            let nameKey = candidateName.toLowerCase().trim();
            while (Boolean(nameLookup[nameKey])) {
                // Then name exists and we need to get a new name
                if (candidateName.endsWith("...")) {
                    // Remove dots first
                    candidateName = name.substring(0,name.length-4) + "(" + versionNumber + ")...";
                }
                else {
                    candidateName = name + "(" + versionNumber + ")";
                }
                versionNumber++;
                nameKey = candidateName.toLowerCase().trim();
                if (versionNumber > 1000) {
                    console.log("Cannot find unique name");
                    break;
                }
            }
            nameLookup[nameKey] = true;
            return candidateName;
        },
        truncate: function (input, maxLength) {
            if (typeof maxLength === "undefined") {
                maxLength = 50;
            }
            if (input) {
                if (input.length > maxLength) {
                    return input.substring(0, maxLength - 1) + "...";
                }
            }
            return input;
        },
        getDecimalCharacter: function (alternate) {
            let n = 1.1;
            n = n.toLocaleString().substring(1, 2);
            if (alternate) {
                return n === "." ? "," : ".";
            }
            return n;
        },
        isGuid: function (text) {
            if (text) {
                //let pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i')
                const match = text.match(/^[0-9a-f]{8}[-]?(?:[0-9a-f]{4}[-]?){3}[0-9a-f]{12}$/i);
                return match === text;
            }
            return false;
        },
        setPageTitle: function (text) {
            document.title = text + " - " + Constants.ApplicationName;
        },
        formatDate: function (input, locale, dateFormat) {
            if (input) {
                // Use P or Pp
                if (typeof dateFormat === "undefined" || dateFormat === null) {
                    dateFormat = "Pp";
                }
                if (typeof input === "string") {
                    input = new Date(input);
                }
                if (input instanceof Date && !isNaN(input)) {
                    return format(input, dateFormat, { locale: locale });
                }
            }
            return "";
        },
        parseFloat: function (stringValue, decimals) {
            if (typeof decimals === "undefined") {
                decimals = 1;
            }
            let formattedValue = stringValue;
            if (stringValue) {
                // Determine current decimal character
                const decimalCharacter = String(1.1).replace(/1/g,"");
                const alternateDecimalCharacter = decimalCharacter === "." ? "," : ".";
                stringValue = stringValue.replace(alternateDecimalCharacter, decimalCharacter);
                formattedValue = parseFloat(stringValue).toFixed(decimals);
                if (isNaN(formattedValue)) {
                    formattedValue = stringValue; // Revert to original value
                }
            }
            return formattedValue;
        },
        isInteger: function (value) {
            if (typeof value === "string") {
                if (value === "") {
                    return false;
                }
                value = value.trim();
                if (value === "") {
                    return false;
                }
                // Try parsing to int value value
                const intValue = parseInt(value);
                if (!isNaN(intValue)) {
                    return String(intValue) === value; // Only return true if the string conversion matches the input
                }
                return false;
            }
            else {
                return Number.isInteger(value);
            }
        },
        datesEqual: function (a, b) {
            return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
        },
        toCamelCase: function (inputString) {
            if (inputString) {
                const firstChar = inputString.substring(0, 1);
                const remaining = inputString.substring(1);
                return firstChar.toLowerCase() + remaining;
            }
            return inputString;
        },
        sortByAttribute: function (list, attribute) {
            return list.sort((a, b) => a[attribute].localeCompare(b[attribute]));
        },
        formatAttribute: function (value, replacement) {
            if (typeof value === "undefined" || value === "" || value === null) {
                if (replacement) {
                    return replacement.toLowerCase();
                }
                else {
                    return "...";
                }
            }
            else {
                return value.toLowerCase();
            }
        }
    }
}();
