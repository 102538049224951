import * as React from 'react';
import Button from '@mui/material/Button';
import useTheme from '@mui/material/styles/useTheme';
import Tooltip from '@mui/material/Tooltip';

export default function ToolbarButton({onClick,text,icon,endIcon,primary,type,disabled,size,tooltip,sx, filled}) {
    const theme = useTheme();
    let variant = primary || filled ? "contained" : "text";
    let additionalProps = {};
    if (primary) {
        additionalProps.color = "success"
    }
    else if (filled) {
        additionalProps.color = "secondary";
    } 
    let textColor = primary ? theme.palette.text.primaryToolbarButton : theme.palette.text.toolbarButton;
    if (!size) {
        size = "small"
    }
    let style = sx ? sx : {};
    style.color = textColor;
    const btn = <Button variant={variant} disableRipple={true} disabled={disabled} type={type} sx={style} disableElevation {...additionalProps} size={size} onClick={onClick} startIcon={icon} endIcon={endIcon}>{text}</Button>;
    return (tooltip && !disabled ?
        <Tooltip title={tooltip}>{btn}</Tooltip>
        : 
        <>{ btn }</>
    );
}
