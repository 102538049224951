import React, { Component } from 'react';
import { Layout } from './components/Layout';
import AppRoutes from './AppRoutes';
import SessionProvider from './components/SessionProvider';
//import { Route, Routes} from 'react-router';



import includes from './components/includes'
import './gotham.css'
import './custom.css';
import { Outlet } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey(
    'c29aaeea5f918e7fd8a7f70357dcb332Tz04MTQ1OCxFPTE3MzU5MDAwNjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);


export default function App() {
    return <SessionProvider>
        <Layout>
            <Outlet />
        </Layout>
    </SessionProvider>;

    //return <SessionProvider>
    //    <Layout>
    //        <Routes>
    //            {AppRoutes.map((route, index) => {
    //                const { element, requireAuth, ...rest } = route;
    //                return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
    //            })}
    //        </Routes>
    //    </Layout>
    //</SessionProvider>;


//    return <Layout>
//        <Routes>
//            <Route exact path='/' element={Home} />
//        </Routes>
//    </Layout>
}

