import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';

export default function ButtonHeader({ headerText, tooltip, buttonText, onButtonClick, icon, additionalButtons, buttonTooltip, disabled, sxHeader }) {
    let buttonIcon = icon ? icon : <AddIcon />;
    // Then use normal button
    let button = <Button disableRipple={true} variant="text" size="small" sx={{ color: "inherit" }} disabled={disabled} onClick={onButtonClick} endIcon={buttonIcon}>
        {buttonText}
    </Button>;
    let wrappedButton = buttonTooltip ? <Tooltip title={buttonTooltip} placement="top">{button}</Tooltip> : button;
    let header = <h2 className="subheader" style={sxHeader}>{headerText}</h2>;
    return (
        <div className="subheader-container">
            <div className='header-text'>
                {tooltip ? <Tooltip title={tooltip} placement="top">{header}</Tooltip> : header}
            </div>
            <div className="header-buttons">
                <Stack spacing={2} direction="row">
                    {additionalButtons}
                    {wrappedButton}
                </Stack>
            </div>
        </div>
    );
}
export function MenuButtonHeader({ headerText, buttonText, icon, additionalButtons, menuItems, disabled, sx }) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    let buttonIcon = icon ? icon : <AddIcon />;

    function onButtonClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function onMenuClick(event,onClick) {
        setAnchorEl(null);
        onClick(event);
    }

    return (
        <>
            <div className="subheader-container" style={{ ...sx }}>
                <div className='header-text'>
                    {
                        typeof headerText == "string" ?
                            <h2 className="subheader">{headerText}</h2>
                            :
                            <>{ headerText }</>
                    }
                </div>
                <div className="header-buttons">
                    <Stack spacing={2} direction="row">
                        {additionalButtons}
                        <Button disableRipple={true} sx={{color:theme.palette.text.primary}} variant="text" disabled={disabled} size="small" onClick={onButtonClick} endIcon={buttonIcon}>
                            {buttonText}
                        </Button>
                    </Stack>
                </div>
            </div>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {
                    menuItems.map((item, index) => (
                        item.isDivider ? 
                        <Divider key={index} />
                        :
                        <MenuItem
                            key={index}
                            disabled={item.disabled}
                            className="mt-menu-item"
                            ref={item.ref}
                            onClick={(event) => onMenuClick(event, item.onClick)} >
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText>
                                {item.text}
                            </ListItemText>
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    );
}
