import * as React from 'react';
import Stack from '@mui/material/Stack';

export default function TopToolbar({ children,noMargin,rightAlign,sx }) {
    return (
        <>
            <div className={`mt-toolbar ${noMargin ? "no-margin" : ""}`} style={sx}>
                <Stack spacing={2} direction={rightAlign?"row-reverse":"row"}>
                    {children}
                </Stack>
            </div>
        </>
    );
}
