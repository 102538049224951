import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ToolbarButton from './ToolbarButton';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';

export default function DeleteButton({ onClick, size, disabled, text, tooltip, sx }) {
    const { t } = useTranslation();
    return (
        <ToolbarButton size={size} onClick={onClick} disabled={disabled} text={text ? text : t("Delete")} tooltip={tooltip} icon={<DeleteIcon />} sx={sx}></ToolbarButton>
    );
}
export function DeleteIconButton({ onClick, size, disabled, text, tooltip, sx }) {
    const { t } = useTranslation();
    return (
        <IconButton onClick={onClick} ><DeleteIcon/></IconButton>
    );
}
