import { throwError } from '../library/Error';
import { Constants } from '../AppConstants';

export const CurrencyCodes = ["SEK"];

export class Amount {
    nominalValue = null;
    currencyCode = "USD";
    _typeName = "Amount";

    constructor(inputValue) {
        this.nominalValue = null;
        this.currencyCode = "USD";
        if (typeof inputValue === "object" || inputValue instanceof Amount) {
            if (typeof inputValue.nominalValue !== "undefined") {
                this.nominalValue = inputValue.nominalValue;
            }
            if (typeof inputValue.currencyCode !== "undefined") {
                this.currencyCode = inputValue.currencyCode;
            }
        }
        else {
            if (inputValue !== null && typeof inputValue !== "undefined" && inputValue !== "") {
                let parts = inputValue.split(/\s/);
                const currencyCode = parts[0];
                if (parts.length > 1) {
                    const valuePart = parts.splice(0, parts.length - 1).join("");
                    const value = parseInt(valuePart);
                    if (!isNaN(value)) {
                        this.nominalValue = value;
                    }
                }
                if (currencyCode) {
                    currencyCode = currencyCode.toUpperCase();
                    // Check that currency code is included is supported
                    if (CurrencyCodes.includes(currencyCode)) {
                        this.currencyCode = currencyCode;
                    }
                    else {
                        this.nominalValue = null; // Then reset the value since we do not know the currency
                    }
                }
            }
        }
    }

    static Parse(value) {
        if (value !== "undefined" && value !== null) {
            return new Amount(value);
        }
        return new Amount();
    }
    equalTo(timeSpan) {
        //return TimePeriod.toMonths(this) === TimePeriod.toMonths(timeSpan);
    }
    greaterThan(timeSpan) {
        /// TODO: i dessa metoder m�ste vi kunna kolla dagens kurser osv via session etc.
        //return TimePeriod.toMonths(this) > TimePeriod.toMonths(timeSpan);
    }
    greaterThanOrEqual(timeSpan) {
        //return TimePeriod.toMonths(this) >= TimePeriod.toMonths(timeSpan);
    }
    lessThan(timeSpan) {
        //return TimePeriod.toMonths(this) < TimePeriod.toMonths(timeSpan);
    }
    lessThanOrEqual(timeSpan) {
        //return TimePeriod.toMonths(this) <= TimePeriod.toMonths(timeSpan);
    }
    getIntCount() {
        let intCount = 0;
        if (this.count) {
            intCount = parseInt(this.count);
            if (isNaN(intCount)) {
                intCount = 0;
            }
        }
        return intCount;
    }
    addTo(date) {
        if (this.unit === Constants.TimeUnit.Day) {
            return //TimePeriod.addDays(date, this.getIntCount());
        }
        else if (this.unit === Constants.TimeUnit.Month) {
            return //TimePeriod.addMonths(date, this.getIntCount());
        }
        else if (this.unit === Constants.TimeUnit.Year) {
            return //TimePeriod.addYears(date, this.getIntCount());
        }
        else {
            throwError("Unsupported time unit");
        }
    }
    subtractFrom(date) {
        if (this.unit === Constants.TimeUnit.Day) {
            return //TimePeriod.addDays(date, -this.getIntCount());
        }
        else if (this.unit === Constants.TimeUnit.Month) {
            return //TimePeriod.addMonths(date, -this.getIntCount());
        }
        else if (this.unit === Constants.TimeUnit.Year) {
            return //TimePeriod.addMonths(date, -12 * this.getIntCount());
        }
        else {
            throwError("Unsupported time unit");
        }
    }

}
