import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import DialogContent from '@mui/material/DialogContent';
import authService from './api-authorization/AuthorizeService'
import { LogoutActions } from './api-authorization/ApiAuthorizationConstants'
import { Logout } from './api-authorization/Logout';

export function ActivityTracker({ maxInactivity, onExpiry, preExpiryMessage}) {
    const { t } = useTranslation();
    const preExpiryInterval = 30;
    const lastActivity = useRef(new Date());
    const interval = useRef(null);
    const authInterval = useRef(null);
    const messageOpen = useRef(false);
    const [refresh, setRefresh] = useState(false);
    const [remaining, setRemaining] = useState(preExpiryInterval);
    const [doLogout, setDoLogout] = useState(false);

    if (typeof preExpiryMessage === "undefined") {
        preExpiryMessage = t("PreExpiryMessage");
    }
    if (typeof maxInactivity === "undefined") {
        maxInactivity = 30 * 60; // Set to 30 min if not specified
    }

    function TrackActivity() {
        lastActivity.current = new Date();
        if (messageOpen.current) { // Avoid using state for this so that we do not refresh the component for each event
            messageOpen.current = false;
            setRefresh((prev) => { return !prev}); // Just refreshes the component
        }
    }

    useEffect(() => {
        var events = ['scroll', 'touchstart', 'mousedown', 'mousemove', 'keydown'];
        events.forEach(function (eventName) {
            // Assert removed before adding
            document.removeEventListener(eventName, TrackActivity, true);
            document.addEventListener(eventName, TrackActivity, true);
        });
        // Clear interval if already present
        if (interval.current) {
            clearInterval(interval.current);
        }
        authInterval.current = setInterval(async function () {
            let authenticated = await authService.isAuthenticated()
            if (!authenticated) {
                if (onExpiry) {
                    // Reset activity
                    TrackActivity();
                    // Then call handler
                    onExpiry();
                }
            }
        }, 10*1000);

        interval.current = setInterval(function () {
            let secondsSinceLastActivity = (new Date() - lastActivity.current) / 1000;

            if (secondsSinceLastActivity > maxInactivity) {
                // Reset activity
                TrackActivity();
                // Then call handler, if available
                if (onExpiry) onExpiry();
                setDoLogout(true);
            }
            else {
                // Only check preexiry if message set
                if (preExpiryMessage) {
                    if (secondsSinceLastActivity > (maxInactivity - preExpiryInterval)) {
                        if (!messageOpen.current) {
                            messageOpen.current = true;
                            setRemaining(preExpiryInterval);
                        }
                        else {
                            setRemaining((prev) => { return prev - 1; });
                        }
                    }
                }
            }
        }, 1000);
        return () => {
            // Remove event listener in unmount
            events.forEach(function (eventName) {
                document.removeEventListener(eventName, TrackActivity, true);
            });
            // Clear intervals
            if (interval.current) {
                clearInterval(interval.current);
                interval.current = null;
            }
            if (authInterval.current) {
                clearInterval(authInterval.current);
                authInterval.current = null;
            }
        }
    }, []);

    if (doLogout) {
        return <Logout action={LogoutActions.Logout} />;
    }
    else {
        return (messageOpen.current ?
            <Dialog
                open={messageOpen.current}
            >
                <DialogContent >
                    {preExpiryMessage.format(remaining)}
                </DialogContent>
            </Dialog>
            : <></>
        );
    }
}



