import React, { useState, useEffect, useRef} from 'react';
import { TextField} from '@mui/material';
import { FormUtil } from './util/FormUtil'
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Constants } from './AppConstants'
import { useTranslation } from 'react-i18next';
import useTheme from '@mui/material/styles/useTheme';
import FormFieldText from './FormFieldText';
import FormFieldDescription from './FormFieldDescription';
import { Place } from '../../node_modules/@mui/icons-material/index';

export default function FormTextField({ entity, setEntity, label, attribute, autoFocus, multiline, minRows, maxRows, tooltip, defaultValue, required, errors, setErrors, disableErrors, numeric, decimal, step,
    decimals, minValue, maxValue, readOnly, placeholder, variant, onKeyDown, disabled, onFocus,onBlur,endAdornment,email,orgNumber, updateOnChange, setEdited, onChange, maxLength, text, description, emptyValue }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [errorText, setErrorText] = useState(null);
    const [fieldValue, setFieldValue] = useState("");
    const isMounted = useRef(false);
    const emailRegex = useRef(null);
    const orgNumberRegex = useRef(null);
    if (orgNumber) {
        placeholder = "______-____";
    }

    // The empty value is used when the control is empty. For nullable numeric values we might want to return null instead on 0 or empty string
    if (typeof emptyValue === "undefined") {
        emptyValue = defaultValue;
    }

    updateOnChange = typeof updateOnChange === "undefined" ? true : updateOnChange;
    maxRows = typeof maxRows === "undefined" ? 10 : maxRows;

    let entityValue = FormUtil.getEntityAttribute(entity, attribute, defaultValue);
    let hasValue = !valueIsEmpty(fieldValue);

    // Default step length in numeric is 1 for integer, 0.1 for decimal
    if (!step) {
        step = decimal ? 0.1 : 1;
    }
    if (!decimals) {
        decimals = 1;
    }

    let enableAutoFocus = (entity.id === "0" && attribute === "name") || autoFocus;
    useEffect(() => {
        isMounted.current = true;
        // Assert the entity gets the default value if no value is set
        if (typeof defaultValue !== "undefined") {
            let value = FormUtil.getEntityAttribute(entity, attribute);
            if (typeof value === "undefined" && !numeric) { // Numeric values do not need default attributes
                //FormUtil.setEntityAttribute(entity, attribute, defaultValue);
                setEntity((prev) => {
                    let newEntity = { ...prev };
                    //FormUtil.setEntityAttribute(newEntity, attribute, defaultValue);
                    FormUtil.setEntityAttribute(newEntity, attribute, defaultValue);
                    return newEntity;
                });
                return;
            }
        }
        return () => {
            // Remove any errors created by this component
            isMounted.current = false;
            // Also clear errors before unmount
            if (setErrors) {
                setErrors((prev) => {
                    let newErrors = { ...prev };
                    newErrors[attribute] = false;
                    return newErrors;
                });
            }
        }
    }, []);

    useEffect(() => {
        setFieldValue(entityValue);
    }, [entityValue,entity]);


    useEffect(() => {
        checkRequired();
    }, [entityValue, fieldValue])

    function valueIsEmpty(value) {
        return typeof value === "undefined" || value === null || value === "";
    }

    function isEmail(text) {
        if (emailRegex.current === null) {
            emailRegex.current = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        }
        return emailRegex.current.test(text);
    }
    function isOrgNumber(text) {
        if (orgNumberRegex.current === null) {
            orgNumberRegex.current = /^\d{6}-\d{4}$/i;
        }
        return orgNumberRegex.current.test(text);
    }

    function checkRequired() {
        if (required && errors || (email || orgNumber || numeric) && errors) {
            let currentValue = fieldValue;
            let formattedValue = "";
            let errorState = false;
            let errorMessage = "";
            [formattedValue, errorState, errorMessage] = checkValue(currentValue);
            setError(errorState);
            setErrorText(errorMessage);
        }
    }

    function setError(isError) {
        if (errors && setErrors) {
            setErrors((prev) => {
                if (prev[attribute] === isError) {
                    return prev;
                }
                else {
                    let newErrors = { ...prev };
                    newErrors[attribute] = isError;
                    return newErrors;
                }
            });
        }
    }

    function aboveMax(value) {
        if (typeof maxValue !== "undefined" && maxValue !== null && String(maxValue) !== "") {
            if (value > maxValue) {
                return true;
            }
        }
        return false;
    }
    function belowMin(value) {
        if (typeof minValue !== "undefined" && minValue !== null && String(minValue) !== "") {
            if (value < minValue) {
                return true;
            }
        }
        return false;
    }

    function checkValue(value) {
        let originalValue = value;
        let currentValue = value;
        let formattedValue = value;
        let errorMessage = "";
        let errorState = false;
        if (value !== "" && value !== null && typeof value !== "undefined") {
            if (numeric || email || orgNumber) {
                currentValue = String(currentValue).trim();
                if (email) {
                    if (!isEmail(currentValue)) {
                        errorMessage = t("InvalidEmail");
                        errorState = true
                    }
                }
                else if (orgNumber) {
                    if (!isOrgNumber(currentValue)) {
                        errorMessage = t("InvalidOrgNumber");
                        errorState = true
                    }
                }
                else if (decimal) {
                    let isFloat = /^-?\d*[.,]?\d*$/.test(currentValue);
                    let incompleteEntry = currentValue.endsWith(".") || currentValue.endsWith(",") || currentValue.startsWith("-");
                    // Determine the current decimal point character
                    let pointChar = String(1.1).replace(/1/g, "");
                    let alternatePointChar = pointChar === "." ? "," : ".";
                    let pointRegex = alternatePointChar === "." ? new RegExp("\\" + alternatePointChar, "g") : new RegExp(alternatePointChar, "g");
                    // Replace incorrect point character
                    currentValue = currentValue.replace(pointRegex, pointChar);

                    if (isFloat) {
                        if (!incompleteEntry) {
                            formattedValue = parseFloat(currentValue);
                            if (isNaN(formattedValue)) {
                                formattedValue = originalValue; // Restore
                                errorMessage = t("InvalidDecimal");
                                errorState = true;
                            }
                        }
                    }
                    else {
                        errorMessage = t("InvalidDecimal");
                        errorState = true
                    }
                }
                else {
                    let isInteger = /^-?\d*$/.test(currentValue);
                    let incompleteEntry = currentValue.endsWith(".") || currentValue.endsWith(",") || currentValue.startsWith("-");
                    if (isInteger) {
                        if (!incompleteEntry) {
                            formattedValue = parseInt(currentValue);
                            if (isNaN(formattedValue)) {
                                formattedValue = originalValue;
                                errorMessage = t("InvalidInteger");
                                errorState = true;
                            }
                            else {
                                errorState = false;
                            }
                        }
                    }
                    else {
                        errorMessage = t("InvalidInteger");
                        errorState = true
                    }
                }
                // If no other errors check bounds
                if (!errorState) {
                    if (aboveMax(formattedValue)) {
                        errorState = true;
                        errorMessage = t("NumberTooLarge").format(maxValue);
                    }
                    if (belowMin(formattedValue)) {
                        errorState = true;
                        errorMessage = t("NumberTooSmall").format(minValue);
                    }
                }
            }
        }
        else { // Then no value
            if (required) {
                errorState = true; // Then this should be marked as error because value is missing
            }
            if (typeof defaultValue !== "undefined") {
                formattedValue = defaultValue;
            }
            else if (numeric) {
                formattedValue = null;
            }
        }
        return [formattedValue, errorState, errorMessage];
    }

    function handleChange(e) {
        const { name, value } = e.target;
        let formattedValue = value;
        let errorState, errorText;
        [formattedValue, errorState, errorText] = checkValue(value);

        if (fieldValue !== formattedValue) {
            if (setEdited) {
                setEdited(true);
            }
            if (onChange) {
                onChange(e);
            }
            setFieldValue(formattedValue);
        }
        if (setEdited) {
            setEdited(true);
        }
        // Writeback to entity if no error and updateOnChange
        // Note: must allow for writeback of empty values
        if (updateOnChange && (!errorState || errorState && formattedValue === defaultValue)) {
            setEntity((prev) => {
                let newEntity = { ...prev };
                // Set the empty value when the formatted value is the same as the default
                if (formattedValue === defaultValue) {
                    formattedValue = emptyValue;
                }
                FormUtil.setEntityAttribute(newEntity, name, formattedValue);
                if (onChange) {
                    onChange(e);
                }
                return newEntity;
            });
        }
    }

    function handleFocus(e) {
        
        //if (enableAutoFocus) {
        //    //e.target.select();
        //    e.target.focus();
        //}
        if (onFocus) {
            onFocus(e);
        }
    }

    function handleBlur(e) {
        if (!updateOnChange) {
            if (entityValue !== fieldValue) {
                // Then update entity
                setEntity((prev) => {
                    let newEntity = { ...prev };
                    let newFieldValue = fieldValue;
                    // Set the empty value when the field value is the same as the default
                    if (newFieldValue === defaultValue) {
                        newFieldValue = emptyValue;
                    }
                    FormUtil.setEntityAttribute(newEntity, attribute, newFieldValue);
                    return newEntity;
                });
            }
        }
       if (onBlur) {
            onBlur(e);
        }
    }

    function checkMinMax(currentValue) {
        let errorState = false;
        let errorMessage = "";
        if (aboveMax(currentValue)) {
            errorState = true;
            errorMessage = t("NumberTooLarge").format(maxValue);
        }
        if (belowMin(currentValue)) {
            errorState = true;
            errorMessage = t("NumberTooSmall").format(minValue);
        }
        setErrorText(errorMessage);
        setError(errorState);
    }
    function handleIncrease() {
        setEntity((prev) => {
            let newEntity = { ...prev };
            let previousValue = FormUtil.getEntityAttribute(newEntity, attribute);
            if (String(previousValue) !== "") {
                let currentValue = decimal ? parseFloat(String(previousValue)) : parseInt(String(previousValue));
                if (!isNaN(currentValue)) {
                    currentValue += step;
                    if (decimal) {
                        currentValue = parseFloat(currentValue.toFixed(decimals));
                    }
                    if (aboveMax(currentValue)) {
                        currentValue = Math.min(currentValue, maxValue);
                    }
                    checkMinMax(currentValue);
                    FormUtil.setEntityAttribute(newEntity, attribute, currentValue);
                }
            }
            return newEntity;
        });
    }
    function handleDecrease() {
        setEntity((prev) => {
            let newEntity = { ...prev };
            let previousValue = FormUtil.getEntityAttribute(newEntity, attribute);
            if (String(previousValue) !== "") {
                let currentValue = decimal ? parseFloat(previousValue) : parseInt(previousValue);
                if (!isNaN(currentValue)) {
                    currentValue -= step;
                    if (decimal) {
                        currentValue = parseFloat(currentValue.toFixed(decimals));
                    }
                    if (belowMin(currentValue)) {
                        currentValue = Math.max(currentValue, minValue);
                    }
                    checkMinMax(currentValue);
                    FormUtil.setEntityAttribute(newEntity, attribute, currentValue);
                }
            }
            return newEntity;
        });
    }

    //endAdornment: <InputAdornment position="end">
    //    <IconButton edge="end" onClick={handleIncrease}>{<KeyboardArrowUpIcon />}</IconButton>
    //    <IconButton edge="end" onClick={handleDecrease}>{<KeyboardArrowDownIcon />}</IconButton>
    //</InputAdornment>,

    // Additional props for integer inputs. Note: layout issues if we use IconButtons as adornments
    let additionalProps = numeric ? {
        step: step,
        endAdornment: <InputAdornment position="end">
            <KeyboardArrowUpIcon style={{cursor:"pointer"}} onClick={handleIncrease} />
            <KeyboardArrowDownIcon style={{ cursor: "pointer" }} onClick={handleDecrease} />
        </InputAdornment>,
    } : {}

    if (endAdornment && !numeric) {
        additionalProps.endAdornment = <InputAdornment position="end">{endAdornment}</InputAdornment>
    }
    if (minRows > 1) {
        additionalProps.sx = {
            '& textarea': {
                whiteSpace: 'pre',
                overflowX: 'auto',
            }
        };
    }

    if (readOnly) {
        additionalProps.readOnly = true;
    }
    if (email) {
        additionalProps.type = "email";
    }
    else if (numeric) {
        // Remove this due to issues with format checks
        //additionalProps.type = "number";
    }

    // Always show error if explicit error text set
    let displayError = false;
    if (errors) {
        displayError = errors[attribute] && !disableErrors;
    }
    if (errorText) {
        displayError = true;
    }

    let fieldTexts = null;
    let tooltipAdded = false;
    if (text || description) {
        fieldTexts = <div style={{ marginBottom: "16px" }}>
            <FormFieldText text={text} disabled={disabled} />
            <FormFieldDescription description={description} disabled={disabled} />
        </div>;

        if (tooltip) {
            fieldTexts = <Tooltip title={tooltip} placement="top-start">
                {fieldTexts}
            </Tooltip>;
            tooltipAdded = true;
        }
    }


    let field = <TextField
        label={label}
        placeholder={placeholder}
        fullWidth
        InputLabelProps={{ shrink: placeholder ? true : hasValue }}
        required={required}
        error={displayError}
        variant={variant ? variant : theme.inputs.variant}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        multiline={multiline}
        disabled={disabled}
        minRows={minRows}
        autoComplete="off"
        maxRows={maxRows}
        autoFocus={enableAutoFocus}
        onFocus={handleFocus}
        onBlur={handleBlur}
        size="small"
        name={attribute}
        helperText={errorText}
        value={fieldValue}
        InputProps={additionalProps}
        inputProps={{ maxLength: maxLength }}
        />;

    if (tooltip && !tooltipAdded) {
        return (
            <Tooltip title={tooltip} placement={Constants.TooltipPlacement} >
                {field}
            </Tooltip>
        );
    }
    else {
        return <>{fieldTexts}{field}</>;
    }
}

