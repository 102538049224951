import { throwError } from '../library/Error';
import { Constants } from '../AppConstants';

export class TimePeriod {
    count = null;
    unit = Constants.TimeUnit.Month;
    _typeName = "TimePeriod";

    constructor(inputValue) {
        this.count = null;
        this.unit = Constants.TimeUnit.Month;
        if (typeof inputValue === "object" || inputValue instanceof TimePeriod) {
            if (typeof inputValue.count !== "undefined") {
                this.count = inputValue.count;
            }
            if (typeof inputValue.unit !== "undefined") {
                this.unit = inputValue.unit;
            }
        }
        else {
            if (typeof inputValue === "string" && inputValue !== null && typeof inputValue !== "undefined" && inputValue !== "") {
                inputValue = inputValue.replace(/\s/g, "");
                let stringUnit;
                if (inputValue.endsWith("d")) {
                    this.unit = Constants.TimeUnit.Day;
                    stringUnit = "d";
                }
                else if (inputValue.endsWith("m")) {
                    this.unit = Constants.TimeUnit.Month;
                    stringUnit = "m";
                }
                else if (inputValue.endsWith("y")) {
                    this.unit = Constants.TimeUnit.Year;
                    stringUnit = "y";
                }
                // Remove the unit
                if (inputValue) {
                    inputValue = inputValue.replace(stringUnit, "");
                }

                if (inputValue !== "") {
                    const count = parseInt(inputValue);
                    if (!isNaN(count)) {
                        this.count = count;
                    }
                }
            }
        }
    }

    static Parse(value) {
        if (value !== "undefined" && value !== null) {
            return new TimePeriod(value);
        }
        return new TimePeriod();
    }

    static addMonths(date, months) {
        //return date.AddDays(1).AddMonths(Count).AddDays(-1);
        date.setDate(date.getDate() + 1);
        date.setMonth(date.getMonth() + months);
        date.setDate(date.getDate() - 1);
        return date;
    }
    static addYears(date, years) {
        //return date.AddDays(1).AddMonths(Count).AddDays(-1);
        date.setDate(date.getDate() + 1);
        date.setYear(date.getYear() + years);
        date.setDate(date.getDate() - 1);
        return date;
    }

    static getToday() {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
    }

    static addDays(date, days) {
        date.setDate(date.getDate() + days);
        return date;
    }

    static toMonths(timeSpan) {
        if (timeSpan.unit === Constants.TimeUnit.Day) {
            return timeSpan.getIntCount() / 30;
        }
        else if (timeSpan.unit === Constants.TimeUnit.Month) {
            return timeSpan.getIntCount();
        }
        else if (timeSpan.unit === Constants.TimeUnit.Year) {
            return timeSpan.getIntCount()*12;
        }
    }
    equalTo(timeSpan) {
        return TimePeriod.toMonths(this) === TimePeriod.toMonths(timeSpan);
    }
    greaterThan(timeSpan) {
        return TimePeriod.toMonths(this) > TimePeriod.toMonths(timeSpan);
    }
    greaterThanOrEqual(timeSpan) {
        return TimePeriod.toMonths(this) >= TimePeriod.toMonths(timeSpan);
    }
    lessThan(timeSpan) {
        return TimePeriod.toMonths(this) < TimePeriod.toMonths(timeSpan);
    }
    lessThanOrEqual(timeSpan) {
        return TimePeriod.toMonths(this) <= TimePeriod.toMonths(timeSpan);
    }
    toString(t) {
        let unitDescription = "";
        if (this.unit === Constants.TimeUnit.Day) {
            unitDescription = t("TimeUnitDay");
        }
        else if (this.unit === Constants.TimeUnit.Month) {
            unitDescription = t("TimeUnitMonth");
        }
        else if(this.unit === Constants.TimeUnit.Year) {
            unitDescription = t("TimeUnitYear");
        }
        return this.count + " " + unitDescription.toLowerCase();
    }
    getIntCount() {
        let intCount = 0;
        if (this.count) {
            intCount = parseInt(this.count);
            if (isNaN(intCount)) {
                intCount = 0;
            }
        }
        return intCount;
    }
    addTo(date) {
        if (this.unit === Constants.TimeUnit.Day) {
            return TimePeriod.addDays(date, this.getIntCount());
        }
        else if (this.unit === Constants.TimeUnit.Month) {
            return TimePeriod.addMonths(date, this.getIntCount());
        }
        else if (this.unit === Constants.TimeUnit.Year) {
            return TimePeriod.addYears(date, this.getIntCount());
        }
        else {
            throwError("Unsupported time unit");
        }
    }
    subtractFrom(date) {
        if (this.unit === Constants.TimeUnit.Day) {
            return TimePeriod.addDays(date, -this.getIntCount());
        }
        else if (this.unit === Constants.TimeUnit.Month) {
            return TimePeriod.addMonths(date, -this.getIntCount());
        }
        else if (this.unit === Constants.TimeUnit.Year) {
            return TimePeriod.addMonths(date, -12 * this.getIntCount());
        }
        else {
            throwError("Unsupported time unit");
        }
    }

}
