import React, { useEffect, useRef, useState, useContext } from 'react';
import { FormUtil } from './util/FormUtil'
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import ColorSwatch from './ColorSwatch';
import IconButton from '@mui/material/IconButton';
import { Constants } from './AppConstants'
import FormFieldText from './FormFieldText'
import FormFieldDescription from './FormFieldDescription'
import useTheme from '@mui/material/styles/useTheme';
import { ErrorContext } from './ErrorContextProvider';
import './FormAutoComplete.css';

const filter = createFilterOptions();

export default function FormAutoComplete({ entity, setEntity, label, attribute, items, multiple, errorIdentifier, placeholder, defaultValue, valueAttribute, textAttribute, tooltip, required, errors, setErrors,
    disableErrors, onChange, readOnly, blurOnSelect, allowAdd, enableAddButton, onAddHandler, addItemPrefix, onClick, disabled, hasHeaders, variant, sx, text, description }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMounted = useRef(false);
    const [isFocused, setIsFocused] = useState(false);
    const entityValue = FormUtil.getEntityAttribute(entity, attribute, defaultValue);

    if (typeof addItemPrefix === "undefined") {
        addItemPrefix = t("Add");
    }
    // Default error identifier to attribute
    if (typeof errorIdentifier === "undefined") {
        errorIdentifier = attribute;
    }
    // Try getting an error context
    var errorContext = useContext(ErrorContext);
    let ctxErrors, setCtxErrors, ctxShowErrors, setCtxShowErrors, ctxScope;
    if (errorContext) {
        [ctxErrors, setCtxErrors, ctxShowErrors, setCtxShowErrors, ctxScope] = errorContext;
        // If present we replace the errors etc with the variables from the context
        errors = ctxErrors;
        setErrors = setCtxErrors;
        disableErrors = !ctxShowErrors;
        errorIdentifier = ctxScope + errorIdentifier;
    }

    // Set default value and text attributes
    if (typeof valueAttribute === "undefined") {
        valueAttribute = "value";
    }
    if (typeof textAttribute === "undefined") {
        textAttribute = "text";
    }


    // Handle initial load
    useEffect(() => {
        // Assert the entity gets the default value if no value is set
        if (typeof defaultValue !== "undefined") {
            let value = FormUtil.getEntityAttribute(entity, attribute);
            if (typeof value === "undefined") {
                //FormUtil.setEntityAttribute(entity, attribute, defaultValue);
                setEntity((prev) => {
                    let newEntity = { ...prev };
                    FormUtil.setEntityAttribute(newEntity, attribute, defaultValue);
                    return newEntity;
                });
                return;
            }
        }
        //checkRequired(entity);
    }, [])

    useEffect(() => {
        checkRequired();
    }, [entityValue])

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
            // Also clear errors before unmount
            if (setErrors) {
                setErrors((prev) => {
                    let newErrors = { ...prev };
                    newErrors[errorIdentifier] = false;
                    return newErrors;
                });
            }
        }
    }, []);

    function valueExists(val) {
        if (multiple) {
            if (val.length > 0) {
                return true;
            }
        }
        else {
            if (val !== null && val !== "" && typeof val !== "undefined") {
                return true;
            }
        }
        return false;
    }

    let hasValue = valueExists(entityValue);

    if (hasHeaders) {
        itemStyle = { paddingLeft: "35px" };
    }
    var itemStyle = null;

    // Required
    function checkRequired() {
        if (required && errors) {
            setErrors((prev) => {
                //let entityValue = FormUtil.getEntityAttribute(entity, attribute, defaultValue);
                let missing = !valueExists(entityValue);
                if (prev[attribute] === missing) {
                    return prev; // Skip the update
                }
                else {
                    let newErrors = { ...prev };
                    newErrors[errorIdentifier] = missing;
                    return newErrors;
                }
            });
        }
    }


    function getSelectedOptions() {
        if (multiple) {
            let selectedOptions = [];
            for (let i = 0; i < entityValue.length; i++) {
                let selectedValue = entityValue[i];
                for (let j = 0; j < items.length; j++) {
                    if (items[j][valueAttribute] === selectedValue) {
                        selectedOptions.push(items[j]);
                        break;
                    }
                }
            }
            return selectedOptions;
        }
        else {
            let selectedOption = null;
            for (let j = 0; j < items.length; j++) {
                if (items[j][valueAttribute] === entityValue) {
                    selectedOption = items[j];
                    break;
                }
            }
            return selectedOption;
        }
    }

    function handleChange(e, newValue) {
        // Handle if new item was added
        if (newValue && allowAdd) {
            if (Array.isArray(newValue)) {
                if (newValue.length > 0) {
                    if (newValue[0].id === "0") {
                        onAddHandler(newValue[0]);
                        return;
                    }
                }
            }
            else {
                if (newValue.id === "0") {
                    onAddHandler(newValue);
                    return;
                }
            }
        }
        //checkRequired(newEntity);
        if (onChange) {
            onChange(e, newValue !== null ? newValue[valueAttribute] : null);
        }
        setEntity((prev) => {
            let newEntity = { ...prev };
            if (multiple) {
                let selectedValues = [];
                for (let i = 0; i < newValue.length; i++) {
                    selectedValues.push(newValue[i][valueAttribute]);
                }
                FormUtil.setEntityAttribute(newEntity, attribute, selectedValues);
            }
            else {
                let selectedValue = "";
                if (newValue) {
                    selectedValue = newValue[valueAttribute];
                }
                FormUtil.setEntityAttribute(newEntity, attribute, selectedValue);
            }
            return newEntity;
        });
    }

    function getOptionLabel(option) {
        if (option) {
            if (option[textAttribute]) {
                return option[textAttribute];
            }
        }
        return "";
    }

    if (typeof blurOnSelect === "undefined") {
        blurOnSelect = true;
    }

    let additionalAttributes = {};

    function filterOptionsForAdd(options, params) {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option[textAttribute]);
        if (inputValue !== '' && !isExisting) {
            let addOption = {};
            addOption[valueAttribute] = "0";
            addOption[textAttribute] = inputValue;
            filtered.push(addOption);
        }
        return filtered;
    }

    function filterWithDescription(options, state) {
        const combinedOptionLabel = (option) => {
            if (option) {
                if (option[textAttribute]) {
                    return option[textAttribute] + option.description;
                }
            }
            return "";
        };
        let newState = { ...state, getOptionLabel: combinedOptionLabel };
        return filter(options, newState);
    }

    if (allowAdd) {
        additionalAttributes.filterOptions = filterOptionsForAdd;
    }
    else {
        additionalAttributes.filterOptions = filterWithDescription;
    }

    //<li {...props} key={option[valueAttribute]} disabled={true}>
    //    {option.description ?
    //        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
    //            <div style={{ flex: 1 }}>
    //                {option[valueAttribute] === "0" && allowAdd ? addItemPrefix + " '" + option[textAttribute] + "'" : option[textAttribute]}
    //            </div>
    //            <div style={{ fontSize: "0.85em" }}>
    //                {option.description}
    //            </div>
    //        </div>
    //        :
    //        <>
    //            {option[valueAttribute] === "0" && allowAdd ? addItemPrefix + " '" + option[textAttribute] + "'" : option[textAttribute]}
    //        </>
    //    }
    //</li>

    if (typeof variant === "undefined") {
        variant = theme.inputs.variant;
    }

    //{
    //    option.description ?
    //    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
    //        <div style={{ flex: 1 }}>
    //            {option[valueAttribute] === "0" && allowAdd ? addItemPrefix + " '" + text + "'" : text}
    //        </div>
    //        <div style={{ fontSize: "0.85em" }}>
    //            {option.description}
    //        </div>
    //    </div>
    //    :
    //    <>
    //        {option[valueAttribute] === "0" && allowAdd ? addItemPrefix + " '" + text + "'" : text}
    //    </>
    //}

    let options = items !== null ? items : [];
    let control = (
        <Autocomplete
            name={attribute}
            multiple={multiple}
            sx={sx}
            readOnly={readOnly}
            className="mt-autocomplete"
            blurOnSelect={blurOnSelect}
            size="small"
            value={getSelectedOptions()}
            isOptionEqualToValue={(option, value) => option[valueAttribute] === value[valueAttribute]}
            getOptionLabel={getOptionLabel}
            getOptionDisabled={option => option.disabled || option.isHeader}
            onChange={handleChange}
            options={options}
            disabled={disabled}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={index + "_" + option[valueAttribute]}
                        label={option[textAttribute]}
                        onClick={onClick ? () => onClick(option[valueAttribute]) : null}
                        size="small"
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderOption={(props, option) => {
                const text = option.isHeader ? option.header : option[textAttribute];
                const colorSwatch = Boolean(option.color) ? <ColorSwatch color={option.color} sx={{ marginTop: "-1px", marginLeft: "-6px" }} /> : null;
                return (
                    <li {...props}
                        style={{
                            fontSize: option.isHeader ? "0.875em" : null,
                            paddingLeft: option.isHeader ? null : hasHeaders ? "35px" : null,
                            fontWeight: option.bold || option.isHeader ? "bold" : null,
                            opacity: option.isHeader ? "1" : null
                        }}>
                        {option.description ?
                            option.icon ?
                                <div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        {colorSwatch}
                                        <div style={{ marginRight: "6px" }}>{option.icon}</div>
                                        <div>{option[valueAttribute] === "0" && allowAdd ? addItemPrefix + " '" + text + "'" : text}</div>
                                    </div>
                                    <div style={{ fontSize: "0.85em" }}>
                                        {option.description}
                                    </div>
                                </div>
                                : <div>
                                    <div>
                                        {colorSwatch}
                                        {option.icon && <div style={{ display: "inline-block", marginRight: "6px" }}>{option.icon}</div>}
                                        {option[valueAttribute] === "0" && allowAdd ? addItemPrefix + " '" + text + "'" : text}
                                    </div>
                                    <div style={{ fontSize: "0.85em" }}>
                                        {option.description}
                                    </div>
                                </div>
                            :
                            <>
                                {colorSwatch}
                                {option.icon && <div style={{ display: "inline-block", marginRight: "6px" }}>{option.icon}</div>}
                                {option[valueAttribute] === "0" && allowAdd ? addItemPrefix + " '" + text + "'" : text}
                            </>
                        }
                    </li>
                );
            }}
            {...additionalAttributes}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={variant}
                    label={label}
                    placeholder={hasValue ? "" : placeholder}
                    required={required}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    InputLabelProps={{ shrink: placeholder ? true : hasValue || isFocused }}
                    error={errors ? errors[errorIdentifier] && !disableErrors : false}
                />
            )}
        />
    );

    function handleOnAddButtonClick(e) {
        if (onAddHandler) {
            let addOption = {};
            addOption[valueAttribute] = "0";
            addOption[textAttribute] = "";
            onAddHandler(addOption);
        }
    }

    let fieldTexts = (text || description) ?
        <div style={{ marginBottom: "16px" }}>
            <FormFieldText text={text} disabled={disabled} />
            <FormFieldDescription description={description} disabled={disabled} />
        </div> : null;
    let wrappedControl = null;
    if (tooltip) {
        wrappedControl = <Tooltip title={tooltip} placement={Constants.TooltipPlacement} >
            {fieldTexts}
            {control}
        </Tooltip>;
    }
    else {
        wrappedControl = <>
            {fieldTexts}
            {control}
        </>;
    }

    if (enableAddButton) {
        return <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>{wrappedControl}</div>
            <IconButton size="small" onClick={handleOnAddButtonClick}><AddIcon style={{ fontSize: "inherit" }} /></IconButton>
        </div>;
    }
    else {
        return wrappedControl;
    }
}
