import * as React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ToolbarButton from './ToolbarButton';
import { useTranslation } from 'react-i18next';

export default function SaveButton({ onClick,disabled, type, sx }) {
    const { t } = useTranslation();

    if (typeof type == "undefined") {
        type = "submit";
    }

    function handleOnClick(e) {
        e.preventDefault();
        if (onClick) {
            onClick(e);
        }
    }

    return (
        <ToolbarButton primary="true" disabled={disabled} type={type} sx={sx} onClick={handleOnClick} text={t("Save")} icon={<CheckIcon />}></ToolbarButton>
    );
}
