import React, { useEffect, useRef } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormFieldDescription from './FormFieldDescription'
import Checkbox from '@mui/material/Checkbox';
import { FormUtil } from './util/FormUtil'
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import Switch from '@mui/material/Switch';
import { Constants } from './AppConstants'
import useTheme from '@mui/material/styles/useTheme';
import TopToolbar from './TopToolbar';

export default function FormCheckbox({ entity, setEntity, label, attribute, defaultValue, tooltip, multiple, required, errors, setErrors, disableErrors, errorMessage, alignment, isSwitch, readOnly, description, disabled }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMounted = useRef(false);
    const entityValue = FormUtil.getEntityAttribute(entity, attribute, defaultValue);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
            // Also clear errors before unmount
            if (setErrors) {
                setErrors((prev) => {
                    let newErrors = { ...prev };
                    newErrors[attribute] = false;
                    return newErrors;
                });
            }
        }
    }, []);

    useEffect(() => {
        checkRequired();
    }, [entityValue])

    function checkRequired() {
        if (required && errors) {
            setErrors((prev) => {
                let missing = !FormUtil.getEntityAttribute(entity, attribute, defaultValue);
                // Check if already set
                if (prev[attribute] === missing) {
                    return prev; // Skip the update
                }
                else {
                    let newErrors = { ...prev };
                    newErrors[attribute] = missing;
                    return newErrors;
                }
            });
        }
    }

    if (typeof errorMessage === "undefined") {
        errorMessage = t("PleaseCheck");
    }

    useEffect(() => {
        // Assert the entity gets the default value if no value is set
        if (typeof defaultValue !== "undefined") {
            let value = FormUtil.getEntityAttribute(entity, attribute);
            if (typeof value === "undefined") {
                //FormUtil.setEntityAttribute(entity, attribute, defaultValue);
                setEntity((prev) => {
                    let newEntity = { ...prev };
                    FormUtil.setEntityAttribute(newEntity, attribute, defaultValue);
                    return newEntity;
                });
                return;
            }
        }
        //checkRequired(entity);
    }, [])

    function handleCheckboxChange(e) {
        // No change if readonly
        if (readOnly) {
            return;
        }
        const { name, checked } = e.target;
        setEntity((prev) => {
            let newEntity = { ...prev };
            FormUtil.setEntityAttribute(newEntity, name, checked);
            //checkRequired(newEntity);
            return newEntity;
        });
    }

    let labelPlacement = "end";
    if (alignment === Constants.Alignment.Left) {
        labelPlacement = "end";
    }
    else if (alignment === Constants.Alignment.Right) {
        labelPlacement = "start";
    }

    let control = isSwitch ? 
        <Switch
            onChange={handleCheckboxChange}
            name={attribute}
            size="small"
            sx={{marginLeft:"5px"}}
            checked={entityValue}
        />
        :
        <Checkbox
            onChange={handleCheckboxChange}
            size="small"
            name={attribute}
            checked={entityValue}
            sx={{ marginTop: "-2px"}}
        />

    let innerContent = <FormControlLabel
        control={control}
        label={label}
        labelPlacement={labelPlacement}
    />;

    if (tooltip) {
        innerContent = <Tooltip title={tooltip} placement={Constants.TooltipPlacement}>{innerContent}</Tooltip>
    }

    let showError = errors ? errors[attribute] && !disableErrors: false;

    // Skip formgroup if multiple
    let field = (multiple ?
        innerContent
        :
        <>
            <FormControl
                error={showError}
                component="fieldset"
                disabled={disabled}
                variant={theme.inputs.variant}
            >
                {showError ? <FormLabel component="legend">{errorMessage}</FormLabel> : <></>}
                <FormGroup>
                    {innerContent}
                </FormGroup>
            </FormControl>
            <FormFieldDescription description={description} disabled={disabled} />
        </>
);

    let topMargin = null;
    if (theme.inputs.variant === "standard") {
        topMargin = "9px";
    }
    else if (theme.inputs.variant === "outlined") {
        topMargin = "5px";
    }



    return (
        <div style={{ textAlign: alignment === Constants.Alignment.Right ? "right" : "", marginTop: topMargin }}>
            {field}
        </div>);
}
