import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserAvatar from './UserAvatar';
import SessionContext from './SessionContext';
import { UserProfileDrawer } from './UserProfile';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import LogoutIcon from '@mui/icons-material/Logout';

export default function UserProfileButton() {
    const { t } = useTranslation();
    const { session, setSession } = useContext(SessionContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [profileOpen, setProfileOpen] = useState(false);
    const navigate = useNavigate();
    const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };

    function handleNavigate(path) {
        navigate(path);
        setAnchorEl(null);
    }

    const userId = session !== null ? session.user.id : "";
    const userName = session !== null ? (session.user.firstName + " " + session.user.lastName).trim() : "";
    return (
        <>
            {session !== null && profileOpen && <UserProfileDrawer userId={userId} open={profileOpen} setOpen={setProfileOpen} />}
            {session !== null && 
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem className="mt-menu-item" onClick={() => { setAnchorEl(null); setProfileOpen(true); }}>
                        <ListItemIcon>
                            <AccountCircle />
                        </ListItemIcon>
                        {t('MyProfile')}
                    </MenuItem>
                    <MenuItem className="mt-menu-item" onClick={() => handleNavigate(logoutPath)}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        {t('Logout')}
                    </MenuItem>
                </Menu>}
            <UserAvatar sx={{ visibility: session === null ? "hidden" : null }} userName={userName} id={userId} onClick={(e) => setAnchorEl(e.currentTarget)}></UserAvatar>
        </>
    );
}
