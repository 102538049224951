import React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import Card from '@mui/material/Card';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

export default function ItemCard({ children, width, onClick, onDelete, sx}) {
    const theme = useTheme();

    if (typeof width == "undefined") {
        width = "100%";
    }

    let content = onDelete ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
        <div style={{ flex: 1 }}>
            {children}
        </div>
        <div>
            <IconButton onClick={(e) => { e.stopPropagation(); onDelete(e); }} size="small"><ClearIcon fontSize="small" /></IconButton>
        </div>
    </div>
        : children;

    return (<Card onClick={onClick} sx={{
        ...sx,
        cursor:onClick ? "pointer" : null,
        marginTop: "6px",
        marginBottom: "6px",
        width: width,
        maxWidth: "100%",
        fontSize: "13px",
        padding: "6px",
        paddingLeft: "8px",
        paddingRight: "8px",
        border: "1px " + theme.palette.borders.default + " solid",
        boxShadow: "none"
    }}>
        {content}
    </Card>);


}

