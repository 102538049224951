import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { AccountList } from "./components/AccountList";
import { AccountView } from "./components/AccountView";
import { UserList } from "./components/UserList";
import { UserView } from "./components/UserView";
import { StatusView } from "./components/StatusView";
import { PartnerList } from "./components/PartnerList";
import { PartnerView } from "./components/PartnerView";
import App  from "./App";
import { ErrorPage } from "./components/ErrorPage";
import { sessionLoader } from "./components/SessionProvider";

                            //<AuthorizeRoute path='/user/list' component={UserList} />
                            //<AuthorizeRoute path='/user/view/:id' component={UserView} />

const AppRoutes = [
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        loader:sessionLoader,
        children: [
            {
                index: true,
                requireAuth: true,
                element: <AccountList />
            },
            {
                path: '/status',
                requireAuth: true,
                element: <StatusView />
            },
            {
                path: '/account/view/:id',
                requireAuth: true,
                element: <AccountView />
            },
            {
                path: '/user/list',
                requireAuth: true,
                element: <UserList />
            },
            {
                path: '/user/view/:id',
                requireAuth: true,
                element: <UserView />
            },
            {
                path: '/partner/list',
                requireAuth: true,
                element: <PartnerList />
            },
            {
                path: '/partner/view/:id',
                requireAuth: true,
                element: <PartnerView />
            },
            ...ApiAuthorzationRoutes
        ]
    }
];

//const AppRoutes = [
//    {
//        index: true,
//        requireAuth: true,
//        element: <AccountList />
//    },
//    {
//        path: '/account/view/:id',
//        requireAuth: true,
//        element: <AccountView />
//    },
//    {
//        path: '/user/list',
//        requireAuth: true,
//        element: <UserList />
//    },
//    {
//        path: '/user/view/:id',
//        requireAuth: true,
//        element: <UserView />
//    },
//    {
//        path: '/partner/list',
//        requireAuth: true,
//        element: <PartnerList />
//    },
//    {
//        path: '/partner/view/:id',
//        requireAuth: true,
//        element: <PartnerView />
//    },
//    ...ApiAuthorzationRoutes
//];

export default AppRoutes;
