import React, { Component, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import apiClient from './api-communication/ApiClient'
import { FormUtil } from './util/FormUtil';
import { ViewIcon } from './ViewContent';
import ErrorDialog from './ErrorDialog';
import Grid from '@mui/material/Grid';
import { ViewContent } from './ViewContent'
import { useTranslation } from 'react-i18next';
import { Util } from './util/Util';
import FormTextField from './FormTextField'
import ToolbarButton from './ToolbarButton'
import BottomToolbar from './BottomToolbar'
import SaveButton from './SaveButton'
import TopToolbar from './TopToolbar';
import AddButton from './AddButton';
import ProgressBackdrop from './ProgressBackdrop';
import DeleteButton from './DeleteButton';
import SimpleGrid from './SimpleGrid';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MuiLink from '@mui/material/Link';
import HandshakeIcon from '@mui/icons-material/Handshake';


export function PartnerView() {
    const { t } = useTranslation();
    const [edited, setEdited] = useState(false);
    const [loading, setLoading] = useState(true);
    const [partner, setPartner] = useState(null);
    const [showErrors, setShowErrors] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const { id } = useParams();
    const isMounted = useRef(false);
    const navigate = useNavigate();

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, [])

    useEffect(() => {
        if (partner) {
            Util.setPageTitle(!Util.isNullOrWhiteSpace(partner.name) ? partner.name : t("NewPartner"));
        }
    }, [partner])

    useEffect(() => {
        getPartner(id);
        setShowErrors(false);
    }, [id])

    function getPartner(partnerId) {
        apiClient.getData('partners/' + partnerId, function (data) {
            if (!isMounted.current) {
                return;
            }
            setPartner(data);
            setLoading(false);
        });
    }
    function handleUpdate(updatedItem) {
        setPartner(updatedItem);
        setEdited(true);
    }
    function handleDelete(e) {
        e.preventDefault();
        apiClient.deleteEntity('partners', partner.id, function (response) {
            // Go back to list
            navigate("/partner/list/");
            setEdited(false);
        }, ProgressBackdrop.handleProgress);
    }
    function handleSave(e) {
        e.preventDefault();
        if (!FormUtil.hasErrors(formErrors)) {
            apiClient.updateEntity('partners', partner, function (updatedPartner) {
                if (!isMounted.current) {
                    return;
                }
                setPartner(updatedPartner)
                setEdited(false);
                setShowErrors(false);
            }, ProgressBackdrop.handleProgress);
        }
        else {
            setShowErrors(true);
            ErrorDialog.showMessage(t("ErrorPleaseEnterMissingData"));
        }
    }
    let name = partner && partner.name ? partner.name : t("NewPartner");
    return !loading && <ViewContent
        header={name}
        fixedHeader={true}
        edited={edited}
        breadcrumbItems={[
            { text: t("Partners"), url: "/partner/list", icon: <ViewIcon icon={HandshakeIcon} size="xsmall" /> },
            { text: name }
        ]}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormTextField
                    label={t("Name")}
                    entity={partner}
                    setEntity={handleUpdate}
                    errors={formErrors}
                    disableErrors={!showErrors}
                    setErrors={setFormErrors}
                    attribute="name"
                    required={true}
                    updateOnChange={false}
                    setEdited={setEdited}
                />
            </Grid>
        </Grid>
        <BottomToolbar>
            <SaveButton disabled={!edited} onClick={handleSave}></SaveButton>
            <DeleteButton disabled={partner.id === "0"} onClick={handleDelete}></DeleteButton>
        </BottomToolbar>
    </ViewContent>
}

