String.prototype.format = String.prototype.f = function () {
    var s = this,
        i = arguments.length;

    while (i--) {
        var text = arguments[i];
        if (typeof text == "undefined") {
            text = "";
        }
        s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), text);
    }
    return s;
};
