import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import ToolbarButton from './ToolbarButton';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function PaperComponent(props) {
    const nodeRef = React.useRef(null)
    return (
        <Draggable
            nodeRef={nodeRef}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper ref={nodeRef} {...props} />
        </Draggable>
    );
}

//const useStyles = makeStyles({
//    topScrollPaper: {
//        alignItems: 'flex-start',
//    },
//    topPaperScrollBody: {
//        verticalAlign: 'top',
//    },
//})

export default function StandardDialog({ open,title,height,onSave,onClose,saveButtonText,cancelButtonText,disableCancel,disableSave,disableSaveButton,children, maxWidth, noPadding, noForm, sx}) {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    function handleClose(e,reason) {
        if (reason === "backdropClick") {
            return; // Ignore backdrop click
        }
        if(onClose) onClose(e);
    }

    if (typeof maxWidth === "undefined") {
        maxWidth = "md";
    }

    const cancelText = cancelButtonText ? cancelButtonText : t("Cancel");
    const saveText = saveButtonText ? saveButtonText : t("Save");

    const dialogContent = (
        <>
                {title && <DialogTitle style={{ cursor: 'move', fontWeight: 'normal' }} id="draggable-dialog-title">{title}</DialogTitle>}
                <DialogContent className={noPadding ? "dialog-content-container no-padding" : "dialog-content-container" } style={{height:height}}>
                {children}
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <>
                    {!disableSave && <ToolbarButton primary="true" onClick={onSave} disabled={disableSaveButton} type="submit" text={saveText}></ToolbarButton>}
                    {!disableCancel && <ToolbarButton onClick={handleClose} text={cancelText}></ToolbarButton>}
                </>
            </DialogActions>
        </>);

    const content = noForm ? dialogContent : (<form noValidate={true} onSubmit={(event) => event.preventDefault()}>{dialogContent}</form>);

    return open ? (<Dialog
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperComponent}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        maxWidth={maxWidth}
        scroll="paper"
        slotProps={{
            backdrop:
            {
                invisible: false,
                style: {
                    opacity: 0.2
                }
            },
            paper: {
                style: {
                    top: 0
                }
            }
        }}
        sx={sx}
        fullWidth
    >
        {content}

    </Dialog>) : null;

}
