import React, { Component, useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import apiClient from './api-communication/ApiClient'
import {useData} from './api-communication/Data'
import SessionContext from './SessionContext';
import { FormUtil } from './util/FormUtil';
import { ViewIcon } from './ViewContent';
import PopupMessage from './PopupMessage';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import TabContent from './TabContent'
import RefreshIcon from '@mui/icons-material/Refresh';
import { Constants } from './AppConstants'
import TextTable from './TextTable'
import { useDateFormatter } from './hooks/DateFormatter'
import Tabs from '@mui/material/Tabs';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import ErrorDialog from './ErrorDialog';
import StandardDialog from './StandardDialog';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import MfaSettings from './MfaSettings';
import LoginIcon from '@mui/icons-material/Login';
import Grid from '@mui/material/Grid';
import { ViewContent, TabbedViewContent } from './ViewContent'
import PersonIcon from '@mui/icons-material/Person';
import { useTranslation } from 'react-i18next';
import { Util } from './util/Util';
import FormTextField from './FormTextField'
import FormAutoComplete from './FormAutoComplete'
import ToolbarButton from './ToolbarButton'
import BottomToolbar from './BottomToolbar'
import SaveButton from './SaveButton'
import TopToolbar from './TopToolbar';
import AddButton from './AddButton';
import ProgressBackdrop from './ProgressBackdrop';
import SendIcon from '@mui/icons-material/Send';
import DeleteButton from './DeleteButton';
import SimpleGrid from './SimpleGrid';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MuiLink from '@mui/material/Link';
import FormCheckbox from './FormCheckbox';


export function StatusView() {
    const { session, setSession } = useContext(SessionContext);
    const [tabValue, setTabValue] = useState(0);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [systemStatus, setSystemStatus] = useState(null);
    const { id } = useParams();
    const isMounted = useRef(false);
    const formatDate = useDateFormatter();

    useEffect(() => {
        isMounted.current = true;
        Util.setPageTitle(t("SystemStatus"));
        getStatus();

        return () => isMounted.current = false;
    }, [])

    function getStatus() {
        apiClient.getData("systemstatus", function (response) {
            setSystemStatus(response);
        })
    }

    function getState(failed, lastExecuted, error) {
        let icon = !failed ? <CheckCircleIcon color="success" /> : <ErrorIcon color="error"/>;
        return <>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ marginRight: "5px" }}>{icon}</div>
                <div style={{ marginBottom: "3px" }}>
                    {formatDate(lastExecuted)}
                </div>
            </div>
            {error}
        </>

    }

    function getTable() {
        let table = {
            columns: [
                {
                    name: t("DailyJobLastExecuted")
                },
                {
                    name: t("DailyNotificationJobLastExecuted")
                },
                {
                    name: t("HourlyJobLastExecuted")
                },
                {
                    name: t("ContinuousJobLastExecuted")
                },
                {
                    name: t("TasksInQueue")
                }
            ],
            rows: [
                {
                    cells: [
                        { value: getState(systemStatus.dailyJobsFailed, systemStatus.dailyJobsLastExecuted) },
                        { value: getState(systemStatus.dailyNotificationJobsFailed, systemStatus.dailyNotificationJobsLastExecuted) },
                        { value: getState(systemStatus.hourlyJobsFailed, systemStatus.hourlyJobsLastExecuted) },
                        { value: getState(systemStatus.continuousJobsFailed, systemStatus.continuousJobsLastExecuted) },
                        { value: systemStatus.queueLength },
                    ]
                }                
            ]
        };
        if (systemStatus.dailyJobsError || systemStatus.dailyNotificationJobsError || systemStatus.hourlyJobsError || systemStatus.continuousJobsError) {
            table.rows.push({
                cells: [
                    { value: systemStatus.dailyJobsError },
                    { value: systemStatus.dailyNotificationJobsError },
                    { value: systemStatus.hourlyJobsError },
                    { value: systemStatus.continuousJobsError },
                    { value: "" },
                ]
            });
        }
        return table;
    }

    return systemStatus && <>
        <ViewContent
            icon={<ViewIcon icon={TroubleshootIcon} />}
            header={t("SystemStatus")}
            fixedHeader={true}
            addBottomBarSpacing={false}
        >
            <TopToolbar>
                <ToolbarButton filled={true} onClick={getStatus} text={t("Refresh")} icon={<RefreshIcon/>} />
            </TopToolbar>
            <TextTable
                table={getTable()}
            />
            
        </ViewContent>
    </>
}

