import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useTheme from '@mui/material/styles/useTheme';
import { Constants } from './AppConstants';
import { Util } from './util/Util';

export default function StandardDrawer({ open, setOpen, anchor, children, width, header, headerLayoutRenderer, persistent, hideBackdrop, elevation, disableCloseButton, noPadding, addBottomBarSpacing}) {
    const theme = useTheme();

    if (typeof anchor == "undefined") {
        anchor = "right"
    }

    let headerElement = null;
    let headerText = header ? header : "";
    let hTag = header && <h1 className={"header"}>{Util.truncate(headerText, 80)}&nbsp;</h1>;
    if (headerText.length > 80) {
        headerElement = <Tooltip title={headerText}>{hTag}</Tooltip>;
    }
    else {
        headerElement = hTag;
    }
    // If custom renderer supplied
    if (headerLayoutRenderer) {
        headerElement = headerLayoutRenderer(headerElement);
    }

    // NOTE: current version uses BackdropProps but it will be obsolete after upgrade

//    componentProps = {
//                {
//        backdrop: {
//            style: {
//                backgroundColor: theme.palette.backgrounds.backdrop
//            }
//        }
//    }
//}
    return <Drawer
            BackdropProps={{ style: { backgroundColor: theme.palette.backgrounds.backdrop } }}
            PaperProps={{ sx: { marginTop: Constants.TopBarHeight + "px" } }}
            variant={persistent ? "persistent" : undefined}
            anchor={anchor}
            elevation={elevation}
            open={open}
            hideBackdrop={hideBackdrop}
            onClose={() => setOpen(false)}
        >
            {open && 
                <div style={{ display: "flex", height: "calc(100% - " + Constants.TopBarHeight + "px", flexDirection: "column" }}>
                    <Toolbar variant="dense" />
                    {!disableCloseButton &&
                        <div style={{ position: "absolute", right: "10px", marginTop: "10px" }}>
                            <IconButton onClick={() => setOpen(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    <div className="mt-drawer-container" style={{ boxSizing: "border-box", width: width ? width : null, padding:noPadding ? 0 : null }}>
                    <div className="mt-view-content" style={{ padding: noPadding ? 0 : "20px" }}>
                            {headerElement}
                            {children}
                        </div>
                    </div>
                {addBottomBarSpacing && <Toolbar variant="dense" />}
                </div>
            }
        </Drawer>
}

export function FullScreenDrawer({ open, handleClose, anchor, children, noScroll, paddingBottom}) {
    const theme = useTheme();

    if (typeof anchor == "undefined") {
        anchor = "right"
    }

    let leftButton = <div style={{ position: "absolute", left: "2px", marginTop: "29px" }}>
        <IconButton onClick={() => handleClose()}>
            <ArrowBackIcon />
        </IconButton>
    </div>;

//    componentProps = {
//                {
//        backdrop: {
//            style: {
//                backgroundColor: theme.palette.backgrounds.backdrop
//            }
//        }
//    }
//}


    return (open ?
        <Drawer
            anchor={anchor}
            disableEnforceFocus={true}
            BackdropProps={{ style: { backgroundColor: theme.palette.backgrounds.backdrop } }}
            open={open}
            onClose={() => handleClose()}
            sx={{
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    left: {
                        xs: 0,
                        sm: Constants.DrawerWidth + Constants.SubDrawerShiftSM,
                        md: Constants.DrawerWidth + Constants.SubDrawerShiftMD,
                        lg: Constants.DrawerWidth + Constants.SubDrawerShiftLG
                    }, backgroundColor: "#ffffff"
                },
            }}
        >
            <div style={{ display: "flex", height: "100%", flexDirection: "column"}}>
                <Toolbar variant="dense" />
                <div className="mt-drawer-container" style={{overflowY:noScroll ? "hidden" : null, paddingBottom:paddingBottom}}>
                    <div style={{ position: "absolute", right: "10px", marginTop: "10px" }}>
                        <IconButton onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {leftButton}
                    {children}
                </div>
            </div>
        </Drawer>
        : <></>
    );
}
