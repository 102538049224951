import React, { useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import ToolbarButton from './ToolbarButton';
import FormCheckbox from './FormCheckbox';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export default function ConfirmDialog() {
    const { t } = useTranslation();
    let emptyModel = { confirmed: false, open:false };
    const [confirmModel, setConfirmModel] = useState(emptyModel);

    let defaultConfig = {
        title: t("Confirm"),
        message: "",
        confirmButtonText: t("Confirm"),
        cancelButtonText: t("Cancel")
    };

    const [dialogConfig, setDialogConfig] = React.useState(defaultConfig);

    ConfirmDialog.show = function (config, callback) {
        ConfirmDialog.ConfirmCallback = callback;
        setDialogConfig(config);
        setConfirmModel((prev) => {
            let newModel = { ...prev };
            newModel.open = true;
            newModel.confirmed = false;
            return newModel;
        })
    }
    ConfirmDialog.showMessage = function (message, callback, confirmButtonText, critical) {
        ConfirmDialog.ConfirmCallback = callback;
        let conf = { ...defaultConfig };
        if (critical) {
            conf.critical = critical;
        }
        conf.message = message;
        if (confirmButtonText) {
            conf.confirmButtonText = confirmButtonText;
        }
        setDialogConfig(conf);
        setConfirmModel((prev) => {
            let newModel = { ...prev };
            newModel.open = true;
            newModel.confirmed = false;
            return newModel;
        })
    }
    function handleCancel() {
        setConfirmModel((prev) => {
            let newModel = { ...prev };
            newModel.open = false;
            return newModel;
        })
    }
    function handleConfirm() {
        if (dialogConfig.critical) {
            setConfirmModel((prev) => {
                let newModel = { ...prev };
                newModel.open = false;
                return newModel;
            })
            if (ConfirmDialog.ConfirmCallback) {
                ConfirmDialog.ConfirmCallback();
            }
        }
        else {
            setConfirmModel((prev) => {
                let newModel = { ...prev };
                newModel.open = false;
                return newModel;
            })
            if (ConfirmDialog.ConfirmCallback) {
                ConfirmDialog.ConfirmCallback();
            }
        }
    }

    let checkbox = null;
    let warningIcon = null;
    if (dialogConfig.critical) {
        checkbox = (
            <div style={{marginTop:"15px",marginBottom:0}}>
                <FormCheckbox
                    label={t("ConfirmCritical")}
                    entity={confirmModel}
                    setEntity={setConfirmModel}
                    attribute={"confirmed"}
                    defaultValue={false}
                />
            </div>
        );
        warningIcon = <WarningAmberIcon sx={{ position: "relative", top: "0.18em", marginLeft:"-0.25em",marginRight:"0.2em", color:"red"}}/>;
    }

    return (confirmModel.open ?
        <>
            <Dialog
                open={confirmModel.open}
                onClose={handleCancel}
                slotProps={{
                    backdrop:
                    {
                        invisible: false,
                        style: {
                            opacity: 0.2
                        }
                    }
                }}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">{warningIcon}{dialogConfig.title}</DialogTitle>
                <DialogContent
                    sx={{ minWidth: "500px", marginTop:"10px" }}
                >
                    <DialogContentText id="confirm-dialog-description" style={{ whiteSpace: "pre-line" }}>
                        {dialogConfig.message}
                </DialogContentText>
                {checkbox}
            </DialogContent>
                <DialogActions sx={{padding:2}}>
                    <ToolbarButton primary="true" disabled={dialogConfig.critical && !confirmModel.confirmed} onClick={handleConfirm} text={dialogConfig.confirmButtonText}></ToolbarButton>
                    <ToolbarButton onClick={handleCancel} text={dialogConfig.cancelButtonText}></ToolbarButton>
                </DialogActions>
            </Dialog>
        </>
        :<></>
    );
}
