import React from 'react';
import { useRouteError,Outlet, Routes, Route } from 'react-router-dom';
import ApiAuthorzationRoutes from './api-authorization/ApiAuthorizationRoutes';
import { useTranslation } from 'react-i18next';
import { Login } from './api-authorization/Login';
import ToolbarButton from './ToolbarButton';
import { LoginActions } from './api-authorization/ApiAuthorizationConstants'

export function ErrorPage() {
    const { t } = useTranslation();
    const error = useRouteError();

    let title = t("UnexpectedErrorTitle")
    let message = t("UnexpectedErrorText");
    
    if (error.authorizationFailed || error.status === 401) {
        // Then redirect to login
        var link = document.createElement("a");
        link.href = window.location.pathname; // this.props.path;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        return <>
            <Routes>
                {ApiAuthorzationRoutes.map((route, index) => {
                    const { element, requireAuth, ...rest } = route;
                    return <Route key={index} {...rest} element={element} />;
                })}
            </Routes>
            <Login action={LoginActions.Login} returnUrl={returnUrl}></Login>
        </>;
    }
    else {
        return (
            <>
                <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center", textAlign: "center", fontFamily: "Arial,sans-serif" }}>
                    <div style={{ flex: 1 }}>
                        {message && (<div style={{ paddingTop: "10px", color: "#404040" }}><h1 className="header">{title}</h1></div>)}
                        {message && (<div style={{ paddingTop: "10px", color: "#404040" }}>{message}</div>)}
                        <ToolbarButton text={t("Reload")} primary={true} sx={{ marginTop: "20px" }} onClick={() => { window.location.href = "/"; }} />
                    </div>
                </div>
            </>
        )
    }
}
