import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';

export default function TabContent({ children,hidden,className,noScroll,sx, addBottomBarSpacing}) {
    return (
        <>
            <div
                role="tabpanel"
                style={sx}
                className={noScroll ? "mt-tab-content no-scroll" : "mt-tab-content"}
                hidden={hidden}>
                {children}
            </div>
            {addBottomBarSpacing && !hidden? <Toolbar /> : <></>}
        </>
    );
}
