import React, { useContext, useState, useEffect, useRef } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function ProgressBackdrop() {
    const theme = useTheme();
    const displayTimer = useRef(null);
    const autoHideTimer = useRef(null);
    const isMounted = useRef(false);
    const backdropRef = useRef();
    const progressRef = useRef();
    const delayDisplay = 1000;
    const autoHideAfter = 5000;
    const [message, setMessage] = useState(null);

    function clearAllTimeouts() {
        if (displayTimer.current) clearTimeout(displayTimer.current);
        if (autoHideTimer.current) clearTimeout(autoHideTimer.current);
        displayTimer.current = null;
        autoHideTimer.current = null;
    }

    useEffect(() => {
        isMounted.current = true;
        return () => {
            clearAllTimeouts();
            isMounted.current = false
        };
    }, [])

    ProgressBackdrop.handleProgress = function (inProgress) {
        if (isMounted.current) {
            if (inProgress) {
                ProgressBackdrop.show();
            }
            else {
                ProgressBackdrop.hide();
            }
        }
    }

    function showTransparentBackdrop() {
        if (isMounted.current) {
            // Show the backdrop initially with transparent background color
            backdropRef.current.style.backgroundColor = "rgba(0,0,0,0)";
            backdropRef.current.style.display = "block";
            backdropRef.current.style.zIndex = theme.zIndex.drawer + 1;
            // Hide the spinner
            progressRef.current.style.display = "none";
            progressRef.current.style.zIndex = -1;
        }
    }
    function showVisibleBackdrop() {
        if (isMounted.current) {
            // Show the backdrop with visible background 
            backdropRef.current.style.backgroundColor = null;
            // Show the spinner
            progressRef.current.style.display = "block";
            progressRef.current.style.zIndex = theme.zIndex.drawer + 2;
        }
    }
    // Starts the backdrop display
    ProgressBackdrop.show = function (customMessage) {
        clearAllTimeouts();
        showTransparentBackdrop();
        // Set timer to display a visible backdrop for long operations
        displayTimer.current = setTimeout(showVisibleBackdrop, delayDisplay);
        // Set timer to autohide in case of failure
        autoHideTimer.current = setTimeout(ProgressBackdrop.hide, autoHideAfter);
        if (customMessage) {
            setMessage(customMessage);
        }
        else {
            message !== null && setMessage(null);
        }
    }
    ProgressBackdrop.hide = function () {
        clearAllTimeouts();
        if (isMounted.current) {
            // Hide the backdrop 
            backdropRef.current.style.display = "none";
            // Move behind
            backdropRef.current.style.zIndex = -1;
            // Hide the spinner
            progressRef.current.style.display = "none";
            progressRef.current.style.zIndex = -1;
            if (message) {
                setMessage(null);
            }
        }
    }
    return <Backdrop ref={backdropRef} sx={{ display: "none", cursor: "progress", zIndex: -1 }} open={true}>
        <CircularProgress ref={progressRef} sx={{ position: "absolute", top: "50%", marginLeft: "-36px", left: "50%", display: "none" }} />
    </Backdrop>;
}


//export default function ProgressBackdrop() {
//    const theme = useTheme();
//    const emptyDisplayModel = { active: false,visible:false }
//    const [displayModel, setDisplayModel] = useState(emptyDisplayModel);
//    const displayTimer = useRef(null);
//    const autoHideTimer = useRef(null);
//    const isMounted = useRef(false);
//    const backdropRef = useRef();
//    const delayDisplay = 1000;
//    const autoHideAfter = 5*1000;

//    function clearAllTimeouts() {
//        if (displayTimer.current) clearTimeout(displayTimer.current);
//        if (autoHideTimer.current) clearTimeout(autoHideTimer.current);
//        displayTimer.current = null;
//        autoHideTimer.current = null;
//    }

//    useEffect(() => {
//        isMounted.current = true;
//        setDisplayModel(emptyDisplayModel);
//        return () => {
//            clearAllTimeouts();
//            isMounted.current = false
//        };
//    }, [])

//    ProgressBackdrop.handleProgress = function (inProgress) {
//        if (isMounted.current) {
//            if (inProgress) {
//                ProgressBackdrop.show();
//            }
//            else {
//                ProgressBackdrop.hide();
//            }
//        }
//    }

//    // Starts the backdrop disaply
//    ProgressBackdrop.show = function () {
//        clearAllTimeouts();
//        setDisplayModel({ active: true, visible: false });
//        displayTimer.current = setTimeout(() => {
//            if (isMounted.current) {
//                setDisplayModel({ active: true, visible: true });
//            }
//        }, delayDisplay);
//        autoHideTimer.current = setTimeout(() => {
//            if (isMounted.current) {
//                setDisplayModel(emptyDisplayModel);
//            }
//        }, autoHideAfter);
//    }
//    ProgressBackdrop.hide = function () {
//        clearAllTimeouts();
//        setDisplayModel(emptyDisplayModel)
//    }
//    return displayModel.active && <Backdrop
//        sx={{ backgroundColor: displayModel.visible ? null : "rgba(0,0,0,0)", cursor:"progress", zIndex: (theme) => theme.zIndex.drawer + 1 }}
//        open={displayModel.active}
//    >
//        {displayModel.visible && <CircularProgress/>}
//    </Backdrop>;
//}
