import React, { useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import useTheme from '@mui/material/styles/useTheme';
import { Constants } from './AppConstants'
import SessionContext from './SessionContext'

export default function UserAvatar({ userName, id, onClick, large, centered, modelType, isDivider, borderColor, borderWidth, sx }) {
    const theme = useTheme();
    const { session, setSession } = useContext(SessionContext);

    if (!session.ready) {
        return null;
    }

    if (typeof modelType === "undefined") {
        modelType = Constants.ModelType.User;
    }

    if (typeof borderColor === "undefined") {
        borderColor = "rgba(255,255,255,0)"; // transparent border
    }
    if (typeof borderWidth === "undefined") {
        borderWidth = 0; // transparent border
    }

    function stringAvatar(name,id) {
        const userColor = theme.palette.avatar.loggedIn;
        let bgColor = session.user.id === id && modelType === Constants.ModelType.User ? userColor : theme.palette.avatar.default;
        let initials = "";
        if (isDivider) {
            initials = "...";
            bgColor = theme.palette.backgrounds.hover;
        }
        else if (id === "") {
            initials = "M";
            bgColor = theme.palette.success.main;
        }
        else if (name) {
                let splitChar = name.indexOf('@') !== -1 ? '.' : ' ';
                let nameParts = name.split(splitChar);
                let firstName = nameParts[0];
                let firstInitial = firstName ? firstName[0] : "";
                let lastInitial = "";
                if (nameParts.length > 1) {
                    let lastName = nameParts[1];
                    lastInitial = lastName ? lastName[0] : "";
                }
                initials = firstInitial + lastInitial;
            }
        else {
            initials = "N/A";
        }

        const size = large ? (160 + 2 * borderWidth) + "px" : (31 + 2 * borderWidth) + "px";
        const fontSize = large ? "74px" : "13px";

        const marginLeft = centered ? "auto" : null;
        const marginRight = centered ? "auto" : "8px";
        const marginBottom = centered ? "10px" : null;

        const textColor = theme.palette.getContrastText(bgColor);

        return {
            sx: {
                bgcolor: bgColor,
                color: textColor,
                border: borderWidth + "px " + borderColor + " solid",
                cursor: onClick ? "pointer" : "",
                width: size,
                height: size,
                fontSize: fontSize,
                marginRight: marginRight,
                marginLeft: marginLeft,
                marginBottom: marginBottom,
                ...sx
            },
            children: initials.toUpperCase(),
        };
    }
    return <Avatar {...stringAvatar(userName, id)} onClick={onClick}></Avatar>;
}
