import React, { useRef, useContext, useEffect } from 'react';
import SessionContext from './SessionContext';



export function CreateGridLocale() {
    const { session, setSession } = useContext(SessionContext);

    if (session.user.culture === "sv-SE") {
        return {
            // Note: set this to empty to avoid showing the label above the field
            headerFilterOperatorContains:'',

            // Root
            noRowsLabel: 'Tom lista',
            noResultsOverlayLabel: 'Inga resultat funna.',
            errorOverlayDefaultLabel: 'Ett fel uppstod.',

            // Density selector toolbar button text
            toolbarDensity: 'Densitet',
            toolbarDensityLabel: 'Densitet',
            toolbarDensityCompact: 'Kompakt',
            toolbarDensityStandard: 'Standard',
            toolbarDensityComfortable: 'Bekväm',

            // Columns selector toolbar button text
            toolbarColumns: 'Kolumner',
            toolbarColumnsLabel: 'Välj kolumner',

            // Filters toolbar button text
            toolbarFilters: 'Filter',
            toolbarFiltersLabel: 'Visa filter',
            toolbarFiltersTooltipHide: 'Dölj filter',
            toolbarFiltersTooltipShow: 'Visa filter',
            toolbarFiltersTooltipActive: (count) =>
                count !== 1 ? `${count} aktiva filter` : `${count} aktivt filter`,

            // Quick filter toolbar field
            toolbarQuickFilterPlaceholder: 'Sök…',
            toolbarQuickFilterLabel: 'Sök',
            toolbarQuickFilterDeleteIconLabel: 'Rensa',

            // Export selector toolbar button text
            toolbarExport: 'Exportera',
            toolbarExportLabel: 'Exportera',
            toolbarExportCSV: 'Ladda ner som CSV',
            toolbarExportPrint: 'Skriv ut',
            toolbarExportExcel: 'Ladda ner som Excel',

            // Columns panel text
            columnsPanelTextFieldLabel: 'Hitta kolumn',
            columnsPanelTextFieldPlaceholder: 'Kolumntitel',
            columnsPanelDragIconLabel: 'Ordna om kolumnen',
            columnsPanelShowAllButton: 'Visa alla',
            columnsPanelHideAllButton: 'Dölj alla',

            // Filter panel text
            filterPanelAddFilter: 'Lägg till filter',
            filterPanelDeleteIconLabel: 'Ta bort',
            filterPanelLinkOperator: 'Logisk operatör',
            filterPanelOperators: 'Operatör',

            // TODO v6: rename to filterPanelOperator
            filterPanelOperatorAnd: 'Och',
            filterPanelOperatorOr: 'Eller',
            filterPanelColumns: 'Kolumner',
            filterPanelInputLabel: 'Värde',
            filterPanelInputPlaceholder: 'Filtervärde',

            // Filter operators text
            filterOperatorContains: 'innehåller',
            filterOperatorEquals: 'är lika med',
            filterOperatorStartsWith: 'börjar med',
            filterOperatorEndsWith: 'slutar med',
            filterOperatorIs: 'är',
            filterOperatorNot: 'är inte',
            filterOperatorAfter: 'är efter',
            filterOperatorOnOrAfter: 'är på eller efter',
            filterOperatorBefore: 'är innan',
            filterOperatorOnOrBefore: 'är på eller innan',
            filterOperatorIsEmpty: 'är tom',
            filterOperatorIsNotEmpty: 'är inte tom',
            filterOperatorIsAnyOf: 'är någon av',

            // Filter values text
            filterValueAny: 'något',
            filterValueTrue: 'sant',
            filterValueFalse: 'falskt',

            // Column menu text
            columnMenuLabel: 'Meny',
            columnMenuShowColumns: 'Visa kolumner',
            columnMenuFilter: 'Filtrera',
            columnMenuHideColumn: 'Dölj',
            columnMenuManageColumns: 'Hantera kolumner',
            columnMenuUnsort: 'Osortera',
            columnMenuSortAsc: 'Sortera stigande',
            columnMenuSortDesc: 'Sortera fallande',

            // Column header text
            columnHeaderFiltersTooltipActive: (count) =>
                count !== 1 ? `${count} aktiva filter` : `${count} aktivt filter`,
            columnHeaderFiltersLabel: 'Visa filter',
            columnHeaderSortIconLabel: 'Sortera',

            // Rows selected footer text
            footerRowSelected: (count) =>
                count !== 1
                    ? `${count.toLocaleString()} rader markerade`
                    : `${count.toLocaleString()} rad markerad`,

            // Total row amount footer text
            footerTotalRows: 'Totalt antal rader:',

            // Total visible row amount footer text
            footerTotalVisibleRows: (visibleCount, totalCount) =>
                `${visibleCount.toLocaleString()} av ${totalCount.toLocaleString()}`,

            // Checkbox selection text
            checkboxSelectionHeaderName: 'Markering med kryssruta',
            checkboxSelectionSelectAllRows: 'Markera alla rader',
            checkboxSelectionUnselectAllRows: 'Avmarkera alla rader',
            checkboxSelectionSelectRow: 'Markera rad',
            checkboxSelectionUnselectRow: 'Avmarkera rad',

            // Boolean cell text
            booleanCellTrueLabel: 'ja',
            booleanCellFalseLabel: 'nej',

            // Actions cell more text
            actionsCellMore: 'mer',

            // Column pinning text
            pinToLeft: 'Fäst till vänster',
            pinToRight: 'Fäst till höger',
            unpin: 'Ta bort fäste',

            // Tree Data
            treeDataGroupingHeaderName: 'Grupp',
            treeDataExpand: 'visa underordnade',
            treeDataCollapse: 'dölj underordnade',

            // Grouping columns
            groupingColumnHeaderName: 'Grupp',
            groupColumn: (name) => `Gruppera efter ${name}`,
            unGroupColumn: (name) => `Sluta gruppera efter ${name}`,

            // Master/detail
            // detailPanelToggle: 'Detail panel toggle',
            expandDetailPanel: 'Expandera',
            collapseDetailPanel: 'Kollapsa',

            // Row reordering text
            rowReorderingHeaderName: 'Ordna om rader',

            // Aggregation
            // aggregationMenuItemHeader: 'Aggregation',
            // aggregationFunctionLabelSum: 'sum',
            // aggregationFunctionLabelAvg: 'avg',
            // aggregationFunctionLabelMin: 'min',
            // aggregationFunctionLabelMax: 'max',
            // aggregationFunctionLabelSize: 'size',
        };
    }
    else if (session.user.culture === "nb-NO") {
        return {
            // Root
            noRowsLabel: 'Ingen rader',
            noResultsOverlayLabel: 'Fant ingen resultat.',
            errorOverlayDefaultLabel: 'Det skjedde en feil.',

            // Density selector toolbar button text
            toolbarDensity: 'Tetthet',
            toolbarDensityLabel: 'Tetthet',
            toolbarDensityCompact: 'Kompakt',
            toolbarDensityStandard: 'Standard',
            toolbarDensityComfortable: 'Komfortabelt',

            // Columns selector toolbar button text
            toolbarColumns: 'Kolonner',
            toolbarColumnsLabel: 'Velg kolonner',

            // Filters toolbar button text
            toolbarFilters: 'Filter',
            toolbarFiltersLabel: 'Vis filter',
            toolbarFiltersTooltipHide: 'Skjul fitler',
            toolbarFiltersTooltipShow: 'Vis filter',
            toolbarFiltersTooltipActive: (count) =>
                count !== 1 ? `${count} aktive filter` : `${count} aktivt filter`,

            // Quick filter toolbar field
            toolbarQuickFilterPlaceholder: 'Søk…',
            toolbarQuickFilterLabel: 'Søk',
            toolbarQuickFilterDeleteIconLabel: 'Slett',

            // Export selector toolbar button text
            toolbarExport: 'Eksporter',
            toolbarExportLabel: 'Eksporter',
            toolbarExportCSV: 'Last ned som CSV',
            toolbarExportPrint: 'Skriv ut',
            toolbarExportExcel: 'Last ned som Excel',

            // Columns panel text
            columnsPanelTextFieldLabel: 'Finn kolonne',
            columnsPanelTextFieldPlaceholder: 'Kolonne tittel',
            columnsPanelDragIconLabel: 'Reorganiser kolonne',
            columnsPanelShowAllButton: 'Vis alle',
            columnsPanelHideAllButton: 'Skjul alle',

            // Filter panel text
            filterPanelAddFilter: 'Legg til filter',
            filterPanelDeleteIconLabel: 'Slett',
            filterPanelLinkOperator: 'Logisk operator',
            filterPanelOperators: 'Operatører',

            // TODO v6: rename to filterPanelOperator
            filterPanelOperatorAnd: 'Og',
            filterPanelOperatorOr: 'Eller',
            filterPanelColumns: 'Kolonner',
            filterPanelInputLabel: 'Verdi',
            filterPanelInputPlaceholder: 'Filter verdi',

            // Filter operators text
            filterOperatorContains: 'inneholder',
            filterOperatorEquals: 'er lik',
            filterOperatorStartsWith: 'starter med',
            filterOperatorEndsWith: 'slutter med',
            filterOperatorIs: 'er',
            filterOperatorNot: 'er ikke',
            filterOperatorAfter: 'er etter',
            filterOperatorOnOrAfter: 'er på eller etter',
            filterOperatorBefore: 'er før',
            filterOperatorOnOrBefore: 'er på eller før',
            filterOperatorIsEmpty: 'er tom',
            filterOperatorIsNotEmpty: 'er ikke tom',
            filterOperatorIsAnyOf: 'er en av',

            // Filter values text
            filterValueAny: 'noen',
            filterValueTrue: 'sant',
            filterValueFalse: 'usant',

            // Column menu text
            columnMenuLabel: 'Meny',
            columnMenuShowColumns: 'Vis kolonner',
            columnMenuFilter: 'Filter',
            columnMenuHideColumn: 'Skjul',
            columnMenuUnsort: 'Usorter',
            columnMenuSortAsc: 'Sorter ØKENDE',
            columnMenuSortDesc: 'Sorter SYNKENDE',

            // Column header text
            columnHeaderFiltersTooltipActive: (count) =>
                count !== 1 ? `${count} aktive filter` : `${count} aktivt filter`,
            columnHeaderFiltersLabel: 'Vis filter',
            columnHeaderSortIconLabel: 'Sorter',

            // Rows selected footer text
            footerRowSelected: (count) =>
                count !== 1 ? `${count.toLocaleString()} rader valgt` : `${count.toLocaleString()} rad valgt`,

            // Total row amount footer text
            footerTotalRows: 'Totalt antall rader:',

            // Total visible row amount footer text
            footerTotalVisibleRows: (visibleCount, totalCount) =>
                `${visibleCount.toLocaleString()} av ${totalCount.toLocaleString()}`,

            // Checkbox selection text
            checkboxSelectionHeaderName: 'Avmerkingsboks valgt',
            checkboxSelectionSelectAllRows: 'Velg alle rader',
            checkboxSelectionUnselectAllRows: 'Velg bort alle rader',
            checkboxSelectionSelectRow: 'Velg rad',
            checkboxSelectionUnselectRow: 'Velg bort rad',

            // Boolean cell text
            booleanCellTrueLabel: 'sant',
            booleanCellFalseLabel: 'usant',

            // Actions cell more text
            actionsCellMore: 'mer',

            // Column pinning text
            pinToLeft: 'Fest til venstre',
            pinToRight: 'Fest til høyre',
            unpin: 'Løsne',

            // Tree Data
            treeDataGroupingHeaderName: 'Grupper',
            treeDataExpand: 'se barn',
            treeDataCollapse: 'skjul barn',

            // Grouping columns
            groupingColumnHeaderName: 'Grupper',
            groupColumn: (name) => `Grupper på ${name}`,
            unGroupColumn: (name) => `Stopp å grupper på ${name}`,

            // Master/detail
            // detailPanelToggle: 'Detail panel toggle',
            expandDetailPanel: 'Utvid',
            collapseDetailPanel: 'Kollaps',

            // Row reordering text
            rowReorderingHeaderName: 'Rad reorganisering',

            // Aggregation
            // aggregationMenuItemHeader: 'Aggregation',
            // aggregationFunctionLabelSum: 'sum',
            // aggregationFunctionLabelAvg: 'avg',
            // aggregationFunctionLabelMin: 'min',
            // aggregationFunctionLabelMax: 'max',
            // aggregationFunctionLabelSize: 'size',
        };
    }
    else {
        return {
            // Root
            noRowsLabel: 'No rows',
            noResultsOverlayLabel: 'No results found.',

            // Density selector toolbar button text
            toolbarDensity: 'Density',
            toolbarDensityLabel: 'Density',
            toolbarDensityCompact: 'Compact',
            toolbarDensityStandard: 'Standard',
            toolbarDensityComfortable: 'Comfortable',

            // Columns selector toolbar button text
            toolbarColumns: 'Columns',
            toolbarColumnsLabel: 'Select columns',

            // Filters toolbar button text
            toolbarFilters: 'Filters',
            toolbarFiltersLabel: 'Show filters',
            toolbarFiltersTooltipHide: 'Hide filters',
            toolbarFiltersTooltipShow: 'Show filters',
            toolbarFiltersTooltipActive: (count) =>
                count !== 1 ? `${count} active filters` : `${count} active filter`,

            // Quick filter toolbar field
            toolbarQuickFilterPlaceholder: 'Search…',
            toolbarQuickFilterLabel: 'Search',
            toolbarQuickFilterDeleteIconLabel: 'Clear',

            // Export selector toolbar button text
            toolbarExport: 'Export',
            toolbarExportLabel: 'Export',
            toolbarExportCSV: 'Download as CSV',
            toolbarExportPrint: 'Print',
            toolbarExportExcel: 'Download as Excel',

            // Columns panel text
            columnsPanelTextFieldLabel: 'Find column',
            columnsPanelTextFieldPlaceholder: 'Column title',
            columnsPanelDragIconLabel: 'Reorder column',
            columnsPanelShowAllButton: 'Show all',
            columnsPanelHideAllButton: 'Hide all',

            // Filter panel text
            filterPanelAddFilter: 'Add filter',
            filterPanelRemoveAll: 'Remove all',
            filterPanelDeleteIconLabel: 'Delete',
            filterPanelLogicOperator: 'Logic operator',
            filterPanelOperator: 'Operator',
            filterPanelOperatorAnd: 'And',
            filterPanelOperatorOr: 'Or',
            filterPanelColumns: 'Columns',
            filterPanelInputLabel: 'Value',
            filterPanelInputPlaceholder: 'Filter value',

            // Filter operators text
            filterOperatorContains: 'contains',
            filterOperatorEquals: 'equals',
            filterOperatorStartsWith: 'starts with',
            filterOperatorEndsWith: 'ends with',
            filterOperatorIs: 'is',
            filterOperatorNot: 'is not',
            filterOperatorAfter: 'is after',
            filterOperatorOnOrAfter: 'is on or after',
            filterOperatorBefore: 'is before',
            filterOperatorOnOrBefore: 'is on or before',
            filterOperatorIsEmpty: 'is empty',
            filterOperatorIsNotEmpty: 'is not empty',
            filterOperatorIsAnyOf: 'is any of',
            'filterOperator=': '=',
            'filterOperator!=': '!=',
            'filterOperator>': '>',
            'filterOperator>=': '>=',
            'filterOperator<': '<',
            'filterOperator<=': '<=',

            // Header filter operators text
            headerFilterOperatorContains: '', // Remove
            headerFilterOperatorEquals: 'Equals',
            headerFilterOperatorStartsWith: 'Starts with',
            headerFilterOperatorEndsWith: 'Ends with',
            headerFilterOperatorIs: 'Is',
            headerFilterOperatorNot: 'Is not',
            headerFilterOperatorAfter: 'Is after',
            headerFilterOperatorOnOrAfter: 'Is on or after',
            headerFilterOperatorBefore: 'Is before',
            headerFilterOperatorOnOrBefore: 'Is on or before',
            headerFilterOperatorIsEmpty: 'Is empty',
            headerFilterOperatorIsNotEmpty: 'Is not empty',
            headerFilterOperatorIsAnyOf: 'Is any of',
            'headerFilterOperator=': 'Equals',
            'headerFilterOperator!=': 'Not equals',
            'headerFilterOperator>': 'Is greater than',
            'headerFilterOperator>=': 'Is greater than or equal to',
            'headerFilterOperator<': 'Is less than',
            'headerFilterOperator<=': 'Is less than or equal to',

            // Filter values text
            filterValueAny: 'any',
            filterValueTrue: 'true',
            filterValueFalse: 'false',

            // Column menu text
            columnMenuLabel: 'Menu',
            columnMenuShowColumns: 'Show columns',
            columnMenuManageColumns: 'Manage columns',
            columnMenuFilter: 'Filter', // Used in
            columnMenuHideColumn: 'Hide column',
            columnMenuUnsort: 'Unsort',
            columnMenuSortAsc: 'Sort by ASC',
            columnMenuSortDesc: 'Sort by DESC',

            // Column header text
            columnHeaderFiltersTooltipActive: (count) =>
                count !== 1 ? `${count} active filters` : `${count} active filter`,
            columnHeaderFiltersLabel: 'Show filters',
            columnHeaderSortIconLabel: 'Sort',

            // Rows selected footer text
            footerRowSelected: (count) =>
                count !== 1
                    ? `${count.toLocaleString()} rows selected`
                    : `${count.toLocaleString()} row selected`,

            // Total row amount footer text
            footerTotalRows: 'Total Rows:',

            // Total visible row amount footer text
            footerTotalVisibleRows: (visibleCount, totalCount) =>
                `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,

            // Checkbox selection text
            checkboxSelectionHeaderName: 'Checkbox selection',
            checkboxSelectionSelectAllRows: 'Select all rows',
            checkboxSelectionUnselectAllRows: 'Unselect all rows',
            checkboxSelectionSelectRow: 'Select row',
            checkboxSelectionUnselectRow: 'Unselect row',

            // Boolean cell text
            booleanCellTrueLabel: 'yes',
            booleanCellFalseLabel: 'no',

            // Actions cell more text
            actionsCellMore: 'more',

            // Column pinning text
            pinToLeft: 'Pin to left',
            pinToRight: 'Pin to right',
            unpin: 'Unpin',

            // Tree Data
            treeDataGroupingHeaderName: 'Group',
            treeDataExpand: 'see children',
            treeDataCollapse: 'hide children',

            // Grouping columns
            groupingColumnHeaderName: 'Group',
            groupColumn: (name) => `Group by ${name}`,
            unGroupColumn: (name) => `Stop grouping by ${name}`,

            // Master/detail
            detailPanelToggle: 'Detail panel toggle',
            expandDetailPanel: 'Expand',
            collapseDetailPanel: 'Collapse',

            // Used core components translation keys
            MuiTablePagination: {},

            // Row reordering text
            rowReorderingHeaderName: 'Row reordering',

            // Aggregation
            aggregationMenuItemHeader: 'Aggregation',
            aggregationFunctionLabelSum: 'sum',
            aggregationFunctionLabelAvg: 'avg',
            aggregationFunctionLabelMin: 'min',
            aggregationFunctionLabelMax: 'max',
            aggregationFunctionLabelSize: 'size',
        };
    }

}


