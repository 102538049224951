import React, { useState, useEffect, useRef } from 'react';
import apiClient from './ApiClient'

export function useData(url) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, [])

    useEffect(() => {
        setLoading(true);
        apiClient.getData(url, function (fetchedData) {
            if (!isMounted.current) return;
            setData(fetchedData);
            setLoading(false);
        });
    }, [url]);
    return [data, loading];
}

export function assertItemIncluded(itemId, controller, items, setItems)
{
    if (itemId === "" || itemId === null || typeof itemId === "undefined") {
        return;
    }
    // Asserts that the specified item is included in the list, if not is is fetched and appended to the item list
    if (items.findIndex(o => o.id === itemId) == -1) {
        apiClient.getData(controller + "/" + encodeURIComponent(itemId), function (item) {
            setItems((prev) => {
                let newItems = [...prev];
                newItems.push(item);
                return newItems;
            })
        });
    }
}


