import authService from '../api-authorization/AuthorizeService'
import ErrorDialog from '../ErrorDialog';
import ProgressBackdrop from '../ProgressBackdrop';
import ConfirmDialog from '../ConfirmDialog';
import FileSaver from 'file-saver'
import axios from 'axios';
import SessionProvider from '../SessionProvider';

export class ApiClient {
    anonymous = false;

    async setAnonymous(val) {
        this.anonymous = val;
    }

    async getData(action, callback, authErrorCallback, confirmedCodes, onError) {
        if (typeof (confirmedCodes) === "undefined") {
            confirmedCodes = [];
        }
        var token = this.anonymous ? "" : await authService.getAccessToken();
        // Assert we have a token before proceeding
        if (!this.anonymous && !token) {
            if (authErrorCallback) {
                authErrorCallback();
            }
            else {
                SessionProvider.SetAuthorized(false);
            }
            return;
        }
        fetch(action, {
            headers: !token ? {} : { '__ConfirmedCodes': confirmedCodes.join(','), 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else if (response.status === 401) {
                    if (authErrorCallback) {
                        authErrorCallback();
                    }
                    else {
                        SessionProvider.SetAuthorized(false);
                    }
                }
                else {
                    if (ErrorDialog.show) {
                        ErrorDialog.show(); // Just show default error
                    }
                }
            })
            .then(apiResponse => {
                if (typeof apiResponse !== "undefined") {
                    if (apiResponse.ok) {
                        callback(apiResponse.data);
                    }
                    else if (apiResponse.confirmRequested) {
                        ConfirmDialog.show(apiResponse.dialogConfiguration, function () { apiClient.getData(action, callback, authErrorCallback, apiResponse.confirmedCodes); });
                    }
                    else {
                        if (onError) {
                            onError(apiResponse.dialogConfiguration);
                        }
                        else if (ErrorDialog.show) {
                            ErrorDialog.show(apiResponse.dialogConfiguration);
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                if (ErrorDialog.show) {
                    ErrorDialog.show(); // Just show default error
                }
            });
    }

    async uploadFile(action, file, updateProgress, cancelToken) {
        //const token = await authService.getAccessToken();
        var token = this.anonymous ? "" : await authService.getAccessToken();
        // Assert we have a token before proceeding
        if (!this.anonymous && !token) return;

        const formData = new FormData();
        formData.append("file", file);
        return axios.post(action, formData, {
            headers: { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${token}` },
            cancelToken: cancelToken ? cancelToken.token : null,
            onUploadProgress: function (e) {
                let percentComplete = e.loaded / e.total;
                percentComplete = parseInt(percentComplete * 100);
                if (updateProgress) {
                    updateProgress(percentComplete,file.name);
                }
            }
        }).then(response => response.data)
            .catch((error) => {
                console.log(error);
                if (ErrorDialog.showMessageKey) {
                    ErrorDialog.showMessageKey("NotAllowedFileType");
                }
        });

        //try {
        //    const response = await axios({
        //        method: "post",
        //        url: action,
        //        data: formData,
        //        headers: { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${token}` },
        //        onUploadProgress: function (e) {
        //            let percentComplete = e.loaded / e.total;
        //            percentComplete = parseInt(percentComplete * 100);
        //            if (updateProgress) {
        //                updateProgress(percentComplete);
        //            }
        //        }
        //    });
        //} catch (error) {
        //    console.log(error)
        //}


    //    axios.post('/upload', file, {
    //        headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`}
    //    });
    }

    //async downloadFileNew(action, fileName, contentType)
    //{
    //    if (typeof contentType == "undefined") {
    //        contentType = "application/octet-stream"; // The default, to force download
    //    }
    //    var token = this.anonymous ? "" : await authService.getAccessToken();

    //    const xmlHttp = new XMLHttpRequest();
    //    xmlHttp.onreadystatechange = () => {
    //        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
    //            const blobUrl = window.URL.createObjectURL(xmlHttp.response);
    //            const e = document.createElement('a');
    //            e.href = blobUrl;
    //            e.download = blobUrl.substr(blobUrl.lastIndexOf('/') + 1);
    //            document.body.appendChild(e);
    //            e.click();
    //            document.body.removeChild(e);
    //        }
    //    };
    //    xmlHttp.responseType = 'blob';
    //    xmlHttp.open('GET', action, true);
    //    xmlHttp.setRequestHeader("Accept", contentType);
    //    xmlHttp.setRequestHeader("Authorization", `Bearer ${token}`);
    //    xmlHttp.send(null);
    //}

    async downloadFile(action, fileName, contentType, httpMethod, body, callback) {
        if (typeof contentType == "undefined" || contentType === null) {
            contentType = "application/octet-stream"; // The default, to force download
        }
        if (typeof httpMethod === "undefined" || httpMethod === null) {
            httpMethod = "GET";
        }

        function handleResponse(response) {
            const blob = new Blob([response.data], {
                type: contentType,
            });
            FileSaver.saveAs(blob, fileName);
            if (callback) callback();
        }

        var token = this.anonymous ? "" : await authService.getAccessToken();
        // Assert we have a token before proceeding
        if (!this.anonymous && !token) return;

        if (httpMethod.toLowerCase() === "get") {
            axios.get(action, {
                responseType: "arraybuffer",
                headers: { "Accept": contentType, 'Authorization': `Bearer ${token}` }
            }).then(handleResponse)
        }
        else if (httpMethod.toLowerCase() === "post") {
            axios.post(action, JSON.stringify(body), {
                responseType: "arraybuffer",
                headers: { "Content-Type": "application/json", "Accept": contentType, 'Authorization': `Bearer ${token}` }
            }).then(handleResponse)
        }
        else {
            throw Error("Invalid http method");
        }
    }

    async deleteMultiple(action, ids, callback, setProgress, confirmedCodes) {
        this.updateEntity(action, ids, callback, setProgress, confirmedCodes, "DELETE");
    }

    async postData(action, data, callback, setProgress, confirmedCodes) {
        this.updateEntity(action, data, callback, setProgress, confirmedCodes, "POST");
    }
    async deleteEntity(action, id, callback, setProgress, confirmedCodes) {
        this.updateEntity(action + "/" + id, null, callback, setProgress, confirmedCodes, "DELETE");
    }
    async updateEntity(action, entity, callback, setProgress, confirmedCodes, httpMethod) {
        if (typeof (confirmedCodes) === "undefined") {
            confirmedCodes = [];
        }
        if (typeof (httpMethod) === "undefined") {
            httpMethod = "PUT";
        }
        if (setProgress) {
            setProgress(true);
        }
        //const token = await authService.getAccessToken();
        var token = this.anonymous ? "" : await authService.getAccessToken();
        // Assert we have a token before proceeding
        if (!this.anonymous && !token) {
            SessionProvider.SetAuthorized(false);
            return;
        }

        fetch(action, {
            method: httpMethod,
            headers: !token ?
                { '__ConfirmedCodes': confirmedCodes.join(','), 'Content-Type': 'application/json' } :
                { '__ConfirmedCodes': confirmedCodes.join(','), 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify(entity)
        }).then(response => {
                if (setProgress) {
                    setProgress(false);
                }
                if (response.ok) {
                    return response.json();
                }
                else if (response.status === 401) {
                    SessionProvider.SetAuthorized(false);
                }
                else {
                    ErrorDialog.show(); // Just show default error
                }
            })
            .then(apiResponse => {
                if (typeof apiResponse !== "undefined") {
                    if (apiResponse.ok) {
                        callback(apiResponse.data);
                    }
                    else if (apiResponse.confirmRequested) {
                        ConfirmDialog.show(apiResponse.dialogConfiguration, function () { apiClient.updateEntity(action, entity, callback, setProgress, apiResponse.confirmedCodes, httpMethod); });
                    }
                    else {
                        ErrorDialog.show(apiResponse.dialogConfiguration);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                ErrorDialog.show(); // Just show default error
            });
    }
}

const apiClient = new ApiClient();

export default apiClient;

