import React, { Component, useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import apiClient from './api-communication/ApiClient'
import {useData} from './api-communication/Data'
import PopupMessage from './PopupMessage';
import SessionContext from './SessionContext';
import { FormUtil } from './util/FormUtil';
import { ViewIcon } from './ViewContent';
import EmptyMessage from './EmptyMessage';
import ButtonHeader from './ButtonHeader';
import {useDateFormatter} from './hooks/DateFormatter';
import TabContent from './TabContent'
import StandardLink from './StandardLink'
import ItemCard from './ItemCard'
import { Constants } from './AppConstants'
import Tabs from '@mui/material/Tabs';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import ErrorDialog from './ErrorDialog';
import ConfirmDialog from './ConfirmDialog';
import StandardDialog from './StandardDialog';
import LoginIcon from '@mui/icons-material/Login';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import SendIcon from '@mui/icons-material/Send';
import cloneDeep from 'lodash/cloneDeep';
import { ViewContent, TabbedViewContent } from './ViewContent'
import { useTranslation } from 'react-i18next';
import { Util } from './util/Util';
import { v4 as uuidv4 } from 'uuid';

import FormTextField from './FormTextField'
import FormCheckbox from './FormCheckbox'
import FormSelect from './FormSelect'
import FormAutoComplete from './FormAutoComplete'
import ToolbarButton from './ToolbarButton'
import BottomToolbar from './BottomToolbar'
import SaveButton from './SaveButton'
import TopToolbar from './TopToolbar';
import AddButton from './AddButton';
import ProgressBackdrop from './ProgressBackdrop';
import DeleteButton from './DeleteButton';
import SimpleGrid from './SimpleGrid';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FormRadioButtonList from './FormRadioButtonList';

function AddUserDialog({ open, setOpen, onSave }) {
    const { t } = useTranslation();
    const [model, setModel] = useState({ firstName: "", lastName: "", email: "" });
    const [formErrors, setFormErrors] = useState({});
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        if (open) {
            setShowErrors(false);
        }
    }, [open]);

    function handleSave() {
        if (!FormUtil.hasErrors(formErrors)) {
            onSave(model);
            setShowErrors(false);
        }
        else {
            setShowErrors(true);
        }
    }

    return <StandardDialog
        open={open}
        onClose={() => setOpen(false)}
        onSave={handleSave}
        title={t("AddAdministrator")}
        saveButtonText={t("Add")}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormTextField
                    label={t("FirstName")}
                    entity={model}
                    setEntity={setModel}
                    errors={formErrors}
                    disableErrors={!showErrors}
                    setErrors={setFormErrors}
                    attribute="firstName"
                    required={true}
                    updateOnChange={false}
                />
            </Grid>
            <Grid item xs={12}>
                <FormTextField
                    label={t("LastName")}
                    entity={model}
                    setEntity={setModel}
                    errors={formErrors}
                    disableErrors={!showErrors}
                    setErrors={setFormErrors}
                    attribute="lastName"
                    required={true}
                    updateOnChange={false}
                />
            </Grid>
            <Grid item xs={12}>
                <FormTextField
                    label={t("Email")}
                    entity={model}
                    setEntity={setModel}
                    errors={formErrors}
                    disableErrors={!showErrors}
                    setErrors={setFormErrors}
                    attribute="email"
                    required={true}
                    updateOnChange={false}
                />
            </Grid>
        </Grid>
    </StandardDialog>;

}

function AddCertificateDialog({ open, onClose, onSave }) {
    const { t } = useTranslation();
    const [showErrors, setShowErrors] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const emptyModel = { certificate: "" };
    const [model, setModel] = useState(emptyModel);

    useEffect(() => {
        if (open) {
            setModel(emptyModel);
        }
    },[open])

    function handleSave() {
        if (!FormUtil.hasErrors(formErrors)) {
            apiClient.postData("accounts/createcertificate", model.certificate, function (certificate) {
                if (onSave) {
                    onSave(certificate);
                } 
                onClose(true);
            })
            setShowErrors(false);

        }
        else {
            setShowErrors(true);
        }
    }

    return <StandardDialog
        open={open}
        onClose={onClose}
        title={t("AddCertificate")}
        onSave={handleSave}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormTextField
                    label={t("Certificate")}
                    placeholder={t("AddCertificateIntro")}
                    entity={model}
                    setEntity={setModel}
                    errors={formErrors}
                    disableErrors={!showErrors}
                    setErrors={setFormErrors}
                    attribute="certificate"
                    multiline={true}
                    minRows={5}
                    required={true}
                    updateOnChange={false}
                />
            </Grid>
        </Grid>
    </StandardDialog>
}

function ProviderDialog({ open, onClose, onSave, provider, setProvider }) {
    const { t } = useTranslation();
    const [showErrors, setShowErrors] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [addOpen, setAddOpen] = useState(false);
    const { session, setSession } = useContext(SessionContext);
    const dateFormatter = useDateFormatter();

    useEffect(() => {
        if (open) {
            setFormErrors({})
            setShowErrors(false);
        }
    },[open])

    function handleSave() {
        if (!FormUtil.hasErrors(formErrors)) {
            setShowErrors(false);
            onSave();
            onClose();
        }
        else {
            setShowErrors(true);
        }
    }

    function addCertificate(certificate) {
        setProvider((prev) => {
            let newProvider = { ...prev };
            let index = newProvider.certificates.findIndex(o => o.thumbprint === certificate.thumbprint);
            if (index >= 0) {
                newProvider.certificates[index] = certificate;
            }
            else {
                newProvider.certificates.push(certificate);
            }
            return newProvider;
        })
    }

    function handleDeleteCertificate(certificate) {
        ConfirmDialog.showMessage(t("ConfirmDeleteCertificate"), function () {
            setProvider((prev) => {
                let newProvider = { ...prev };
                let index = newProvider.certificates.findIndex(o => o.thumbprint === certificate.thumbprint);
                if (index >= 0) {
                    newProvider.certificates.splice(index, 1);
                }
                return newProvider;
            })
        })
    }

    return open &&
    <>
        <AddCertificateDialog
            open={addOpen}
            onClose={() => setAddOpen(false)}
            onSave={addCertificate}
        />
        <StandardDialog
            open={open}
            onClose={onClose}
            title={provider.id === "" ? t("NewIdentityProvider") : t("EditIdentityProvider")}
            onSave={handleSave}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormTextField
                        label={t("Name")}
                        entity={provider}
                        setEntity={setProvider}
                        errors={formErrors}
                        disableErrors={!showErrors}
                        setErrors={setFormErrors}
                        attribute="name"
                        required={true}
                        updateOnChange={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        label={t("EntityID")}
                        entity={provider}
                        setEntity={setProvider}
                        errors={formErrors}
                        disableErrors={!showErrors}
                        setErrors={setFormErrors}
                        attribute="entityId"
                        required={true}
                        updateOnChange={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormSelect
                        label={t("Type")}
                        entity={provider}
                        setEntity={setProvider}
                        errors={formErrors}
                        disableErrors={!showErrors}
                        setErrors={setFormErrors}
                        attribute="type"
                        valueAttribute="id"
                        textAttribute="name"
                        items={[
                            { id: Constants.IdentityProviderType.Generic, name: t("IdentityProviderTypeGeneric") },
                            { id: Constants.IdentityProviderType.EntraId, name: t("IdentityProviderTypeEntraId") },
                            { id: Constants.IdentityProviderType.Okta, name: t("IdentityProviderTypeOkta") },
                            { id: Constants.IdentityProviderType.Google, name: t("IdentityProviderTypeGoogle") },
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        label={t("MetadataUrl")}
                        entity={provider}
                        setEntity={setProvider}
                        errors={formErrors}
                        disableErrors={!showErrors}
                        setErrors={setFormErrors}
                        attribute="metadataUrl"
                        required={false}
                        updateOnChange={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        label={t("SingleSignOnServiceUrl")}
                        entity={provider}
                        setEntity={setProvider}
                        errors={formErrors}
                        disableErrors={!showErrors}
                        setErrors={setFormErrors}
                        attribute="singleSignOnServiceUrl"
                        required={true}
                        updateOnChange={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        label={t("SingleLogoutServiceUrl")}
                        entity={provider}
                        setEntity={setProvider}
                        errors={formErrors}
                        disableErrors={!showErrors}
                        setErrors={setFormErrors}
                        attribute="singleLogoutServiceUrl"
                        required={true}
                        updateOnChange={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        label={t("SsoEmailDomain")}
                        entity={provider}
                        setEntity={setProvider}
                        errors={formErrors}
                        disableErrors={!showErrors}
                        setErrors={setFormErrors}
                        attribute="emailDomain"
                        required={false}
                        updateOnChange={false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ButtonHeader headerText={t("Certificates")} onButtonClick={() => setAddOpen(true)} buttonText={t("Add")} />
                    {provider.certificates.length === 0 ?
                        <EmptyMessage text={t("NoCertificates")} />
                        :
                        <>
                            {provider.certificates.map((certificate, index) => (<ItemCard key={index} onDelete={() => handleDeleteCertificate(certificate)} >{certificate.thumbprint + " (" + t("CertficateValidTo") + ": " + dateFormatter(certificate.expires) + ")"}</ItemCard>))}
                        </>
                    }
                </Grid>
                <Grid item xs={12}>
                    <FormCheckbox
                        label={t("Enabled")}
                        entity={provider}
                        setEntity={setProvider}
                        attribute="enabled"
                    />
                </Grid>
            </Grid>
        </StandardDialog>
</>
}

export function AccountView() {
    const { session, setSession } = useContext(SessionContext);
    const [tabValue, setTabValue] = useState(0);
    const { t } = useTranslation();
    const [selected, setSelected] = useState([]);
    const [edited, setEdited] = useState(false);
    const [loading, setLoading] = useState(true);
    const [account, setAccount] = useState(null);
    const [signInModel, setSignInModel] = useState({url:"",key:""})
    const [partners, partnersLoading] = useData("partners");
    const [showErrors, setShowErrors] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [users, setUsers] = useState([]);
    const [addUserOpen, setAddUserOpen] = useState(false);
    const [selectedIdentities, setSelectedIdentities] = useState([]);
    const [provider, setProvider] = useState(null);
    const { id } = useParams();
    const isMounted = useRef(false);
    const signInForm = useRef();
    const [confirmMessage, setConfirmMessage] = useState({ open: false });

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, [])

    useEffect(() => {
        if (account) {
            apiClient.getData('accounts/users/' + account.id, function (loadedUsers) {
                if (!isMounted.current) {
                    return;
                }
                setUsers(loadedUsers);
            });
        }
    }, [account && account.id])

    useEffect(() => {
        if (account) {
            Util.setPageTitle(!Util.isNullOrWhiteSpace(account.name) ? account.name : t("NewAccount"));
        }
    }, [account])

    useEffect(() => {
        getAccount(id);
        setShowErrors(false);
    }, [id])

    function getAccount(accountId) {
        apiClient.getData('accounts/' + accountId, function (data) {
            if (!isMounted.current) {
                return;
            }
            setAccount(data);
            setLoading(false);
        });
    }
    function updateAccount(updatedAccount) {
        setAccount(updatedAccount);
        setEdited(true);
    }
    function getRoleDescription(role) {
        switch (role) {
            case Constants.User.Role.Administrator:
                return t("RoleAdministrator");
            case Constants.User.Role.WorkflowAdministrator:
                return t("RoleWorkflowAdministrator");
            case Constants.User.Role.User:
                return t("RoleUser");
        }
        return "";
    }
    function handleSignIn(userId) {
        apiClient.postData("accounts/key/" + account.id, userId, function (keyModel) {
            setSignInModel(keyModel);
        }, ProgressBackdrop.handleProgress);
    }

    useEffect(() => {
        if (signInModel.url && signInModel.key) {
            signInForm.current.submit();
        }
    }, [signInModel])

    function userNameRenderer(params) {
        return <div style={{ width: "100%", display: "flex", alignItems:"center" }}>
            <div style={{flex:1}}>{params.formattedValue}</div>
            <div><IconButton onClick={(e) => { e.stopPropagation(); handleSignIn(params.id) }} title={t("SignInToAccount")} ><LoginIcon sx={{ fontSize: "18px" }}/></IconButton></div>
        </div>
    }

    function getUserGridRows() {
        let rows = [];
        for (let i = 0; i < users.length; i++) {
            let user = users[i];
            rows.push({
                id: user.id,
                name: user.name,
                email: user.email,
                role: getRoleDescription(user.role),
                active: user.status === Constants.User.Status.Active
            });
        }
        return rows;
    }
    function getUserGridColumns() {
        let columns = [];
        columns.push({
            field: "name",
            headerName: t("Name"),
            sortable: true,
            flex: 1,
            renderCell: userNameRenderer
        });
        columns.push({
            field: "email",
            headerName: t("Email"),
            sortable: true,
            width:300
        });
        columns.push({
            field: "role",
            headerName: t("Role"),
            sortable: true,
            width: 200
        });
        columns.push({
            field: "active",
            headerName: t("Active"),
            sortable: true,
            width: 200,
            renderCell:activeRenderer
        });
        return columns;
    }

    function handleSave(e) {
        e.preventDefault();
        if (!FormUtil.hasErrors(formErrors)) {
            apiClient.updateEntity('accounts', account, function (updatedAccount) {
                if (!isMounted.current) {
                    return;
                }
                setAccount(updatedAccount)
                setEdited(false);
                setShowErrors(false);
            }, ProgressBackdrop.handleProgress);
        }
        else {
            setShowErrors(true);
            ErrorDialog.showMessage(t("ErrorPleaseEnterMissingData"));
        }
    }

    function handleAddUser(userModel) {
        apiClient.postData("accounts/addUser/" + account.id, userModel, function (createdUser) {
            setUsers((prev) => {
                let newUsers = [...prev];
                newUsers.push(createdUser);
                return newUsers;
            })
            setAddUserOpen(false);
        }, ProgressBackdrop.handleProgress);
    }

    function handleDeleteIdentityProvider() {
        if (selectedIdentities.length === 0) {
            ErrorDialog.showMessage(t("PleaseSelectIdentities"));
            return;
        }
        ConfirmDialog.showMessage(t("ConfirmDeleteIdentities"), function () {
            setAccount((prev) => {
                let newAccount = cloneDeep(prev);
                for (var i = 0; i < selectedIdentities.length; i++) {
                    let selected = selectedIdentities[i];
                    let index = newAccount.identityProviders.findIndex(o => o.id === selected);
                    if (index >= 0) {
                        newAccount.identityProviders.splice(index, 1);
                    }
                }
                return newAccount;
            })
            setEdited(true);
        });
    }
    function handleSaveProvider() {
        setAccount((prev) => {
            var newAccount = cloneDeep(prev);
            let index = newAccount.identityProviders.findIndex(o => o.id === provider.id);
            if (index >= 0) {
                newAccount.identityProviders[index] = provider;
            }
            else {
                provider.id = uuidv4()
                newAccount.identityProviders.push(provider);
            }
            return newAccount;
        })
        setEdited(true);
    }
    function showIdentityProvider(id) {
        let displayedProvider = account.identityProviders.find(o => o.id === id);
        if (displayedProvider) {
            // Create clone
            displayedProvider = cloneDeep(displayedProvider);
        }
        else {
            // Create new
            displayedProvider = {
                id: "",
                name: "",
                entityId: "",
                singleSignOnServiceUrl: "",
                singleLogoutServiceUrl: "",
                emailDomain:"",
                metadataUrl: "",
                certificates: [],
                enabled: true,
                type:Constants.IdentityProviderType.Generic
            };
        }
        setProvider(displayedProvider)
    }
    function getIdentityGridRows() {
        return account.identityProviders;
    }
    function nameRenderer(params) {
        return <StandardLink onClick={(e) => { e.stopPropagation(); showIdentityProvider(params.id); }}>{params.formattedValue}</StandardLink>;
    }
    function enabledRenderer(params) {
        return params.row.enabled ? t("Yes") : t("No");
    }
    function activeRenderer(params) {
        return params.row.active ? t("Yes") : t("No");
    }
    function getIdentityGridColumns() {
        let columns = [];
        columns.push({
            field: "name",
            width: 200,
            headerName: t("Name"),
            renderCell: nameRenderer
        });
        columns.push({
            field: "entityId",
            flex:1,
            headerName: t("EntityID"),
        });
        columns.push({
            field: "enabled",
            headerName: t("Enabled"),
            renderCell: enabledRenderer
        });
        return columns;
    }
    function sendLoginDetails(e) {
        // This will be a later feature
    //    apiClient.postData('accounts/sendlogin', selected[0], function (data) {
    //        setConfirmMessage({ text: t("LoginSent"), open: true });
    //    });
    }
    const providerStates = [
        { id: Constants.ESign.ProviderState.Disabled, name: t("ProviderStateDisabled") },
        { id: Constants.ESign.ProviderState.Production, name: t("ProviderStateProduction") },
        { id: Constants.ESign.ProviderState.Developer, name: t("ProviderStateDeveloper") },
    ];
    let accountName = account && account.name ? account.name : t("NewAccount");
    return !loading && !partnersLoading && session.ready &&
        <>
        {Boolean(provider) && <ProviderDialog
            open={Boolean(provider)}
            onClose={() => setProvider(null)}
            provider={provider}
            setProvider={setProvider}
            onSave={handleSaveProvider}
        />}
        <form method="POST" style={{ display: "none" }} action={signInModel.url} ref={signInForm}>
            <input type="hidden" name="key" value={signInModel.key} />
        </form>
        <AddUserDialog
            open={addUserOpen}
            setOpen={() => setAddUserOpen(false)}
            onSave={handleAddUser}
        />
        <PopupMessage
            open={Boolean(confirmMessage.open)}
            setOpen={(open) => {
                if (!open) {
                    setConfirmMessage((prev) => { return { ...prev, open: false } })
                }
            }}
            text={confirmMessage.text}
            severity="success"
        />
        <TabbedViewContent
            icon={<ViewIcon icon={AccountBoxIcon} />}
            header={accountName}
            fixedHeader={true}
            edited={edited}
            addBottomBarSpacing={false}
            breadcrumbItems={[
                { text: t("Accounts"), url: "/", icon: <ViewIcon icon={AccountBoxIcon} size="xsmall" /> },
                { text: accountName }
            ]}
        >
            <Tabs value={tabValue} onChange={(e, newTabValue) => setTabValue(newTabValue)}>
                <Tab label={t("AccountInfo")} />
                <Tab label={t("SingleSignOn")} />
                <Tab label={t("Users")} disabled={account.id === "0"} />
            </Tabs>
            <TabContent hidden={tabValue !== 0}>
                <div className="mt-view-content">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormTextField
                                label={t("Name")}
                                entity={account}
                                setEntity={updateAccount}
                                errors={formErrors}
                                disableErrors={!showErrors}
                                setErrors={setFormErrors}
                                attribute="name"
                                required={true}
                                updateOnChange={false}
                                setEdited={setEdited}
                            />
                        </Grid>
                        {session.administrator &&
                            <Grid item xs={12}>
                                <FormAutoComplete
                                    label={t("Parter")}
                                    entity={account}
                                    setEntity={updateAccount}
                                    errors={formErrors}
                                    disableErrors={!showErrors}
                                    setErrors={setFormErrors}
                                    attribute="partnerId"
                                    required={false}
                                    updateOnChange={false}
                                    setEdited={setEdited}
                                    items={partners}
                                    valueAttribute="id"
                                    textAttribute="name"
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <h2 className="formheader">{t("ExtendedBranding")}</h2>
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                label={t("SenderName")}
                                placeholder={t("SenderNamePlaceholder")}
                                entity={account}
                                setEntity={updateAccount}
                                errors={formErrors}
                                disableErrors={!showErrors}
                                setErrors={setFormErrors}
                                attribute="senderName"
                                updateOnChange={false}
                                setEdited={setEdited}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                label={t("EmailDomain")}
                                placeholder={t("EmailDomainPlaceholder")}
                                entity={account}
                                setEntity={updateAccount}
                                errors={formErrors}
                                disableErrors={!showErrors}
                                setErrors={setFormErrors}
                                attribute="emailDomain"
                                updateOnChange={false}
                                setEdited={setEdited}
                            />
                        </Grid>
                        {account.emailDomain && 
                            <Grid item xs={12}>
                                <Alert severity="info">{t("EmailDomainInfo")}</Alert>
                            </Grid>}
                        <Grid item xs={12}>
                            <h2 className="formheader">{t("ESign")}</h2>
                        </Grid>
                        <Grid item xs={12}>
                            <FormSelect
                                label={t("Scrive")}
                                entity={account}
                                setEntity={updateAccount}
                                errors={formErrors}
                                disableErrors={!showErrors}
                                setErrors={setFormErrors}
                                attribute="scriveState"
                                required={true}
                                items={providerStates}
                                valueAttribute="id"
                                textAttribute="name"
                                updateOnChange={false}
                                setEdited={setEdited}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSelect
                                label={t("DocuSign")}
                                entity={account}
                                setEntity={updateAccount}
                                errors={formErrors}
                                disableErrors={!showErrors}
                                setErrors={setFormErrors}
                                attribute="docuSignState"
                                required={true}
                                items={providerStates}
                                valueAttribute="id"
                                textAttribute="name"
                                updateOnChange={false}
                                setEdited={setEdited}
                            />
                        </Grid>
                    </Grid>
                </div>
            </TabContent>
            <TabContent hidden={tabValue !== 1} noScroll={true} addBottomBarSpacing={false}>
                {tabValue === 1 && <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                    <TopToolbar>
                        <AddButton onClick={(e) => showIdentityProvider()} />
                        <DeleteButton onClick={handleDeleteIdentityProvider} disabled={selectedIdentities.length === 0} />
                    </TopToolbar>
                    <div style={{ flex: 1 }}>
                        <SimpleGrid
                            rows={getIdentityGridRows()}
                            columns={getIdentityGridColumns()}
                            selection={selectedIdentities}
                            setSelection={setSelectedIdentities}
                        />
                    </div>
                    <div style={{marginTop:"20px", marginBottom:"20px"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormCheckbox
                                    label={t("DisablePasswordLogin")}
                                    entity={account}
                                    setEntity={updateAccount}
                                    attribute="disablePasswordLogin"
                                    setEdited={setEdited}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormCheckbox
                                    label={t("EnableRemoteGroupAssignment")}
                                    entity={account}
                                    setEntity={updateAccount}
                                    attribute="enableRemoteGroupAssignment"
                                    setEdited={setEdited}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormCheckbox
                                    label={t("EnableAutoProvisioning")}
                                    entity={account}
                                    setEntity={updateAccount}
                                    attribute="enableAutoProvisioning"
                                    setEdited={setEdited}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
                }
            </TabContent>
            <TabContent hidden={tabValue !== 2} noScroll={true} addBottomBarSpacing={false} sx={{paddingBottom:"20px"} }>
                {tabValue === 2 && <>
                    {users.length > 0 ?
                        <SimpleGrid
                            rows={getUserGridRows()}
                            columns={getUserGridColumns()}
                            selection={selected}
                            setSelection={setSelected}
                            disableSelection={true}
                        />
                        :
                        <>
                            <TopToolbar>
                                <AddButton text={t("AddAdministrator")} onClick={() => setAddUserOpen(true)} disabled={users.length > 0} />
                            </TopToolbar>
                        </>
                        }
                </>
                }
            </TabContent>
            <BottomToolbar>
                <SaveButton disabled={!edited} onClick={handleSave}></SaveButton>
                {tabValue === 2 && false && <ToolbarButton text={t("SendLoginDetails")} disabled={selected.length !== 1} onClick={sendLoginDetails} icon={<SendIcon />}></ToolbarButton>}
            </BottomToolbar>
        </TabbedViewContent>
    </>
}

