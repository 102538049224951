import React, { useState, useEffect, useContext, useRef, useMemo} from 'react';
import { useParams, Link   } from "react-router-dom";
import apiClient from './api-communication/ApiClient'
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import BottomToolbar, {BottomDrawerToolbar} from './BottomToolbar'
import ErrorDialog from './ErrorDialog';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import cloneDeep from 'lodash/cloneDeep';
import { FormUtil } from './util/FormUtil';
import FormTextField from './FormTextField'
import FormAutoComplete from './FormAutoComplete'
import { Util } from './util/Util';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveButton from './SaveButton'
import StandardDrawer from './StandardDrawer'
import SessionContext from './SessionContext'
import StandardDialog from './StandardDialog'
import FormSelect from './FormSelect';
import UserAvatar from './UserAvatar';
import ToolbarButton from './ToolbarButton';
import { QRCodeSVG } from 'qrcode.react';
import ConfirmDialog from './ConfirmDialog';
import SessionProvider from './SessionProvider';

function MfaDialog({ open, setOpen, user, setUser }) {
    const theme = useTheme();
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState(false);
    const linkColor = theme.palette.text.primary;
    const [model, setModel] = useState({
        qrCodeUri: "",
        code: "",
        confirmCode: "",
        recoveryCodes:[]
    });

    const padding = "10px";

    useEffect(() => {
        if (open) {
            setShowErrors(false);
            apiClient.getData("users/GetQrCodeUri/" + user.id, function (loadedModel) {
                setModel(loadedModel);
            })
        }
    },[user && user.id, open])

    function handleSave() {
        if (!FormUtil.hasErrors(errors)) {
            apiClient.postData("users/setAuthCode/" + user.id, model, function (updatedModel) {
                setModel(updatedModel);
                setUser((prev) => {
                    let newUser = { ...prev };
                    newUser.twoFactorAuthenticationEnabled = true;
                    return newUser;
                })
            });
            setShowErrors(false);
            setOpen(false);
        }
        else {
            setShowErrors(true);
        }
    }

    return open && <StandardDialog
        open={open}
        onClose={() => setOpen(false)}
        onSave={() => handleSave()}
        title={t("ConfigureAuthenticator")}
    >
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="p" component="p">{t("ConfigureAuthIntro")}</Typography>
            </Grid>
            <Grid item xs={12}>
                <ol>
                    <li style={{padding:padding}}><Typography variant="p" component="span">{t("ConfigureAuthStep1_1") + " "}
                        <a target="_blank" style={{ color: linkColor }} href="https://go.microsoft.com/fwlink/?Linkid=825072">{t("ConfigureAuthStep1_Android")}</a>
                        {" " + t("ConfigureAuthStep1_and") + " "}
                        <a target="_blank" style={{ color: linkColor }} href="https://go.microsoft.com/fwlink/?Linkid=825073">{t("ConfigureAuthStep1_iOS")}</a>
                        {" " + t("ConfigureAuthStep1_2") + " "}
                        <a target="_blank" style={{ color: linkColor }} href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en">{t("ConfigureAuthStep1_Android")}</a>
                        {" " + t("ConfigureAuthStep1_and") + " "}
                        <a target="_blank" style={{ color: linkColor }} href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8">{t("ConfigureAuthStep1_iOS")}</a>
                        .
                    </Typography>
                        <div style={{marginTop:"20px",marginBottom:"10px"}}>
                            <QRCodeSVG value={model.qrCodeUri} />
                    </div>
                    </li>
                    <li style={{ padding: padding }}><Typography variant="p" component="span">{t("ConfigureAuthStep2").format(model.code)}</Typography></li>
                    <li style={{ padding: padding }}>
                        <Typography variant="p" component="span">{t("ConfigureAuthStep3").format(model.code)}</Typography>
                        <div style={{ width: "50%", marginTop: "20px", marginBottom: "10px" }}>
                            <FormTextField
                                label={t("ConfigureConfirmCode")}
                                placeholder={t("ConfigureEnterConfirmCode")}
                                entity={model}
                                autoFocus={true}
                                setEntity={setModel}
                                errors={errors}
                                disableErrors={!showErrors}
                                setErrors={setErrors}
                                attribute="confirmCode"
                                required={true}
                                updateOnChange={false}
                            />
                        </div>
                        {model.recoveryCodes.length > 0 && false &&
                            <div style={{ width: "100%", marginTop: "20px", marginBottom: "10px" }}>
                                <Typography variant="b" component="b">{t("ConfigureAutRecoveryCodes")}</Typography><br />
                                <Typography variant="p" component="span">{t("ConfigureAutRecoveryCodesIntro")}</Typography>
                                <div style={{ marginTop: "10px" }}>
                                    {model.recoveryCodes.map((code, index) => (<div key={index}>{code}</div>))}
                                </div>
                            </div>
                        }
                    </li>
                </ol>
            </Grid>
        </Grid>
    </StandardDialog>
}
export default function MfaSettings({ user, setUser, disableConfigure }) {
    const { t } = useTranslation();
    const [mfaOpen, setMfaOpen] = useState(false);

    function removeAuth() {
        apiClient.getData("users/removeauth/" + user.id, function (updatedUser) {
            setUser(updatedUser);
        })
    }

    return <>
        <MfaDialog
            open={mfaOpen}
            setOpen={setMfaOpen}
            user={user}
            setUser={setUser}
        />
        {user.twoFactorAuthenticationEnabled ?
            <>
                <div style={{ marginTop: "10px", marginBottom: "10px", display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <div style={{marginRight:"5px", marginTop:"5px"} }>
                    <CheckCircleIcon color="success"/>
                    </div>
                    <div>
                        {t("TwoFactorAuthenticationEnabled")}
                    </div>
                </div>
                <ToolbarButton text={t("TwoFactorAuthenticationRemove")} primary={false} onClick={() => removeAuth()} filled={true} />
            </>
            :
            <>
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    {t("TwoFactorAuthenticationDisabled")}
                </div>
                {!disableConfigure && <ToolbarButton text={t("TwoFactorAuthenticationConfigure")} primary={false} onClick={() => setMfaOpen(true)} />}
            </>
        }
    </>;
}


