import React, { Component, useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import apiClient from './api-communication/ApiClient'
import {useData} from './api-communication/Data'
import SessionContext from './SessionContext';
import { FormUtil } from './util/FormUtil';
import { ViewIcon } from './ViewContent';
import PopupMessage from './PopupMessage';
import TabContent from './TabContent'
import { Constants } from './AppConstants'
import Tabs from '@mui/material/Tabs';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import ErrorDialog from './ErrorDialog';
import StandardDialog from './StandardDialog';
import MfaSettings from './MfaSettings';
import LoginIcon from '@mui/icons-material/Login';
import Grid from '@mui/material/Grid';
import { ViewContent, TabbedViewContent } from './ViewContent'
import PersonIcon from '@mui/icons-material/Person';
import { useTranslation } from 'react-i18next';
import { Util } from './util/Util';
import FormTextField from './FormTextField'
import FormAutoComplete from './FormAutoComplete'
import ToolbarButton from './ToolbarButton'
import BottomToolbar from './BottomToolbar'
import SaveButton from './SaveButton'
import TopToolbar from './TopToolbar';
import AddButton from './AddButton';
import ProgressBackdrop from './ProgressBackdrop';
import SendIcon from '@mui/icons-material/Send';
import DeleteButton from './DeleteButton';
import SimpleGrid from './SimpleGrid';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MuiLink from '@mui/material/Link';
import FormCheckbox from './FormCheckbox';
import { useHasErrors } from './ErrorContextProvider';

export function UserView() {
    const { session, setSession } = useContext(SessionContext);
    const [tabValue, setTabValue] = useState(0);
    const { t } = useTranslation();
    const [selected, setSelected] = useState([]);
    const [edited, setEdited] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [partners, partnersLoading] = useData("partners");
    const [showErrors, setShowErrors] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const { id } = useParams();
    const isMounted = useRef(false);
    const navigate = useNavigate();
    const [confirmMessage, setConfirmMessage] = useState({ open: false });
    const hasErrors = useHasErrors();

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, [])

    useEffect(() => {
        if (user) {
            Util.setPageTitle(!Util.isNullOrWhiteSpace(user.name) ? user.name : t("NewUser"));
        }
    }, [user])

    useEffect(() => {
        getUser(id);
        setShowErrors(false);
    }, [id])

    function getUser(userId) {
        apiClient.getData('users/' + userId, function (data) {
            if (!isMounted.current) {
                return;
            }
            setUser(data);
            setLoading(false);
        });
    }
    function updateUser(updatedUser) {
        setUser(updatedUser);
        setEdited(true);
    }
    function handleDelete() {
        apiClient.deleteMultiple('users/deletemultiple', [user.id], function (response) {
            // Go back to list
            navigate("/user/list");
            setEdited(false);
        }, ProgressBackdrop.handleProgress);
    }
    function sendLoginDetails(e) {
        apiClient.postData('users/sendlogin', user.id, function (data) {
            setConfirmMessage({ text: t("LoginSent"), open: true });
        });
    }
    function handleSave(e) {
        e.preventDefault();
        if (!hasErrors(formErrors)) {
            apiClient.updateEntity('users', user, function (updatedUser) {
                if (!isMounted.current) {
                    return;
                }
                setUser(updatedUser)
                setEdited(false);
                setShowErrors(false);
            }, ProgressBackdrop.handleProgress);
        }
        else {
            setShowErrors(true);
            ErrorDialog.showMessage(t("ErrorPleaseEnterMissingData"));
        }
    }

    let userName = user && (user.firstName || user.lastName) ? user.firstName + " " + user.lastName : t("NewUser");
    return !loading && !partnersLoading && session.ready &&
        <>
        <PopupMessage
            open={Boolean(confirmMessage.open)}
            setOpen={(open) => {
                if (!open) {
                    setConfirmMessage((prev) => { return { ...prev, open: false } })
                }
            }}
            text={confirmMessage.text}
            severity="success"
        />
        <ViewContent
            icon={<ViewIcon icon={PersonIcon} />}
            header={userName}
            fixedHeader={true}
            edited={edited}
            addBottomBarSpacing={false}
            breadcrumbItems={[
                { text: t("Users"), url: "/user/list", icon: <ViewIcon icon={PersonIcon} size="xsmall" /> },
                { text: userName }
            ]}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormTextField
                        label={t("FirstName")}
                        entity={user}
                        setEntity={updateUser}
                        errors={formErrors}
                        disableErrors={!showErrors}
                        setErrors={setFormErrors}
                        attribute="firstName"
                        required={true}
                        updateOnChange={false}
                        setEdited={setEdited}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        label={t("LastName")}
                        entity={user}
                        setEntity={updateUser}
                        errors={formErrors}
                        disableErrors={!showErrors}
                        setErrors={setFormErrors}
                        attribute="lastName"
                        required={true}
                        updateOnChange={false}
                        setEdited={setEdited}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        label={t("Email")}
                        entity={user}
                        setEntity={updateUser}
                        errors={formErrors}
                        disableErrors={!showErrors}
                        setErrors={setFormErrors}
                        attribute="email"
                        required={true}
                        updateOnChange={false}
                        setEdited={setEdited}
                    />
                </Grid>
                {session.administrator &&
                    <>
                        <Grid item xs={12}>
                            <FormAutoComplete
                                label={t("Parter")}
                                entity={user}
                                setEntity={updateUser}
                                errors={formErrors}
                                disableErrors={!showErrors}
                                setErrors={setFormErrors}
                                attribute="partnerId"
                                required={false}
                                setEdited={setEdited}
                                items={partners}
                                valueAttribute="id"
                                textAttribute="name"
                            />
                        </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            label={t("Administrator")}
                            description={t("AdministratorDescription")}
                            entity={user}
                            setEntity={updateUser}
                            errors={formErrors}
                            disableErrors={!showErrors}
                            setErrors={setFormErrors}
                            attribute="administrator"
                            setEdited={setEdited}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            label={t("Active")}
                            entity={user}
                            setEntity={updateUser}
                            errors={formErrors}
                            disableErrors={!showErrors}
                            setErrors={setFormErrors}
                            attribute="active"
                            setEdited={setEdited}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <h2 className="subheader" style={{ marginTop: "20px" }}>{t("TwoFactorAuthentication")}</h2>
                        <MfaSettings user={user} setUser={updateUser} disableConfigure={session.user.id !== user.id} />
                    </Grid>
                    </>
                }
            </Grid>
            <BottomToolbar>
                <SaveButton disabled={!edited} onClick={handleSave}></SaveButton>
                <DeleteButton disabled={user.id === "0"} onClick={handleDelete}></DeleteButton>
                <ToolbarButton text={t("SendLoginDetails")} disabled={user.id === "0"} onClick={sendLoginDetails} icon={<SendIcon />}></ToolbarButton>
            </BottomToolbar>
        </ViewContent>
    </>
}

