import React, { useState, useEffect, useContext, useRef, useMemo} from 'react';
import { useParams, Link   } from "react-router-dom";
import apiClient from './api-communication/ApiClient'
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import BottomToolbar, {BottomDrawerToolbar} from './BottomToolbar'
import ErrorDialog from './ErrorDialog';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import cloneDeep from 'lodash/cloneDeep';
import { FormUtil } from './util/FormUtil';
import FormTextField from './FormTextField'
import FormAutoComplete from './FormAutoComplete'
import { Util } from './util/Util';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveButton from './SaveButton'
import StandardDrawer from './StandardDrawer'
import SessionContext from './SessionContext'
import StandardDialog from './StandardDialog'
import FormSelect from './FormSelect';
import UserAvatar from './UserAvatar';
import ToolbarButton from './ToolbarButton';
import { QRCodeSVG } from 'qrcode.react';
import ConfirmDialog from './ConfirmDialog';
import MfaSettings from './MfaSettings';
import SessionProvider from './SessionProvider';

export function UserProfileDrawer({ open, setOpen, userId }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [user, setUser] = useState(null);
    const { session, setSession } = useContext(SessionContext);
    const [loading, setLoading] = useState(true);
    const isMounted = useRef(false);
    const [edited, setEdited] = useState(false);
    function updateUser(newUser) {
        setUser(newUser);
        setEdited(true);
    }

    function saveUser(e) {
        apiClient.updateEntity("users/profile/" + userId, user, function (response) {
            if (isMounted.current) {
                SessionProvider.refresh();
                setEdited(false);
            }
        });
    }

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, []);


    useEffect(() => {
        if (userId) {
            apiClient.getData("users/" + userId, function (loadedUser) {
                if (isMounted.current) {
                    setUser(loadedUser);
                    setLoading(false);
                }
            }, undefined, undefined, function () {
                ErrorDialog.showMessage(t("ErrorUserDeleted"));
                setOpen(false);
            });
        }
    }, [userId])

    let fullName = user ? (user.firstName + " " + user.lastName).trim() : "";

    let bottomBarHeight = 63;
    let bottomBarWidth = 400;

    let bottomBarStyle = { height: bottomBarHeight + "px", right: 0,  paddingTop: "16px", paddingLeft: "40px", boxSizing: "border-box"}
    if (!loading) {
        return <StandardDrawer
            open={open}
            setOpen={setOpen}
            onClose={() => setOpen(false
            )}
            width="400px"
            header={fullName}
            headerLayoutRenderer={(hTag) => headerRenderer(hTag, fullName, user ? user.id : "")}
            addBottomBarSpacing={true}
        >
            <ProfileForm
                user={user}
                setUser={updateUser}
                readOnly={session.userId !== userId}
            />
            <h2 className="subheader" style={{ marginTop: "20px" }}>{t("TwoFactorAuthentication")}</h2>
            <MfaSettings user={user} setUser={updateUser} />
            {userId === session.user.id ?
                <div style={{ width: (bottomBarWidth - 40) + "px", bottomBarStyle} }>
                </div>
                : null
            }
            {userId === session.user.id && (
                <BottomDrawerToolbar width={bottomBarWidth}>
                    <SaveButton disabled={!edited} onClick={saveUser}></SaveButton>
                </BottomDrawerToolbar>
                )
            }
        </StandardDrawer>
    }
    else {
        return null;
    }
}

function headerRenderer(header,userName,userId) {
    return <div style={{ width: "100%", textAlign: "center", marginTop: "10px" }}><UserAvatar userName={userName} id={userId} large={true} centered={true} />{header}</div>
}

export function ProfileForm({ user, setUser, readOnly }) {
    const { session, setSession } = useContext(SessionContext);
    const { t } = useTranslation();

    function getUserFullName() {
        return user ? (user.firstName + " " + user.lastName).trim() : "";
    }

    useEffect(() => {
        if (user) {
            Util.setPageTitle(getUserFullName());
        }
    }, [user])

    function getApplicationCultures() {
        let items = [];
        items.push({ culture: "sv-SE", name: t("sv-SE") });
        items.push({ culture: "en-US", name: t("en-US") });
        items.push({ culture: "nb-NO", name: t("nb-NO") });
        return items;
    }
    function getDateCultures() {
        let items = [];
        items.push({ culture: "sv-SE", name: t("sv-SE") });
        items.push({ culture: "en-US", name: t("en-US") });
        items.push({ culture: "nb-NO", name: t("nb-NO") });
        return items;
    }

    if (user !== null) {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormTextField
                            label={t("FirstName")}
                            entity={user}
                            setEntity={setUser}
                            attribute="firstName"
                            required={true}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField
                            label={t("LastName")}
                            entity={user}
                            setEntity={setUser}
                            attribute="lastName"
                            required={true}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField
                            label={t("Email")}
                            entity={user}
                            setEntity={setUser}
                            attribute="email"
                            required={true}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSelect
                            label={t("Language")}
                            entity={user}
                            setEntity={setUser}
                            attribute="culture"
                            valueAttribute="culture"
                            textAttribute="name"
                            items={getApplicationCultures()}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSelect
                            label={t("DateFormat")}
                            entity={user}
                            setEntity={setUser}
                            attribute="dateCulture"
                            valueAttribute="culture"
                            textAttribute="name"
                            items={getDateCultures()}
                            readOnly={readOnly}
                        />
                    </Grid>
                </Grid>
            </>
        );
    }
    else {
        return (<></>);
    }
}

