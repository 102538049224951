import React from 'react';
import { createRoot } from 'react-dom/client';
import './i18n/config';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import AppRoutes from './AppRoutes';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// Create router configuration
const router = createBrowserRouter(AppRoutes);

root.render(
    <RouterProvider router={router} />
);

//root.render(
//    <BrowserRouter basename={baseUrl}>
//        <App />
//    </BrowserRouter>
//);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
