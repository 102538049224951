import { useContext } from 'react';
import { Util } from '../util/Util';
import SessionContext from '../SessionContext'

export function useDateFormatter() {
    const { session } = useContext(SessionContext);
    return (dateValue, format) => {
        //let format = dateOnly ? "P" : null;
        return Util.formatDate(dateValue, session.dateLocale, format);
    }
}
